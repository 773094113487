import { FormProvider, useForm } from 'react-hook-form'
import Input from '@Components/Input'
import Button from '@Components/Button'
import { useLogin } from '@shared/hooks/useLogin'
import * as S from './styled'
import Icon from '@Components/Icon'
import Text from '@Components/Text'
import Radio from '@Components/Radio'
import { useState } from 'react'
import services from '@Services/index'
import { useModal } from '@shared/hooks/useModal'
import schemas from '@Schemas/index'

const HelpStep = ({ recaptchaRef }) => {
  const methods = useForm()
  const { loginCurrentStep, onSetCurrentLoginStepNumberAndPage } = useLogin()

  const { handleSubmit, setError, getValues, reset } = methods
  const { onSetIsOpenModal } = useModal()

  const [selectedOptionRadio, setSelectedOptionRadio] = useState<number>(1)

  const handleRadioClick = (id) => {
    setSelectedOptionRadio(id)
  }
  const onSubmit = async () => {
    const reCAPTCHAToken = await recaptchaRef.current.executeAsync()

    if (reCAPTCHAToken) {
      const payload = {
        email: getValues('cpfOrEmail').toLowerCase(),
        reCAPTCHAToken,
        clientOrigin: 'Site'
      }
      const [data, error] = await services.users.resetPassword(
        payload,
        'reset_password'
      )
      if (data === 200 || data === 204) {
        reset({ cpfOrEmail: '' })
        return onSetIsOpenModal({
          ...schemas.modal.LOGIN_RESET_PASSWORD,
          onClose: () => {
            onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
          }
        })
      } else if (typeof error?.data === 'string') {
        setError('cpfOrEmail', {
          message: error?.data
        })
      } else if (error?.status === 400 || error?.status === 404) {
        return onSetIsOpenModal({
          ...schemas.modal.LOGIN_RESET_PASSWORD_ERROR,
          onClose: () => {
            onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
          },
          onConfirm: () => {
            onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
          }
        })
      }
    }
  }
  return (
    <FormProvider {...methods}>
      {loginCurrentStep.SubTitle && (
        <S.LoginSubTitle>
          <Text
            type="HEADLINE"
            text={loginCurrentStep.SubTitle}
            className="help-subtitle"
          />
        </S.LoginSubTitle>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset>
          {loginCurrentStep?.inputs?.map((inputItem) => (
            <S.ContainerInput key={inputItem.id}>
              <Input
                handleClick={() => onSetCurrentLoginStepNumberAndPage(1, 1)}
                key={inputItem.id}
                disabled={false}
                value={getValues(`${inputItem.inputName}`)}
                placeholder={inputItem.placeholder}
                rule={inputItem.valid}
                label={inputItem.label}
                pass={inputItem?.pass}
                change={inputItem?.change}
                inputName={inputItem.inputName}
                validation={inputItem?.validation}
                maxLength={256}
              />
            </S.ContainerInput>
          ))}
        </fieldset>
        <S.radioContainer>
          {loginCurrentStep.radio &&
            loginCurrentStep.radio?.length > 0 &&
            loginCurrentStep.radio?.map((rd) => (
              <S.radioItem key={rd.id}>
                <Radio
                  colorLabel="#cccc"
                  id={rd.id}
                  label={rd?.label}
                  Name={rd?.name}
                  isChecked={rd.id === selectedOptionRadio}
                  onClick={() => handleRadioClick(rd.id)}
                />
              </S.radioItem>
            ))}
        </S.radioContainer>
        <S.ContainerHelp>
          <p>
            Em caso de dúvidas, acesse o
            <S.Link href={process.env.ingHelpHost} target="_blank">
              atendimento.
            </S.Link>
          </p>
        </S.ContainerHelp>
        <S.ContainerButton>
          <Button
            isInitialPositionIcon={
              loginCurrentStep?.button?.isInitialPositionIcon
            }
            height={
              loginCurrentStep?.button?.height
                ? loginCurrentStep?.button?.height
                : '48px'
            }
            className="main-button"
            onClick={() => onSubmit}
            iconLeft={
              loginCurrentStep?.button?.icon ? (
                <Icon
                  color="#460505"
                  src={
                    loginCurrentStep?.button.icon
                      ? loginCurrentStep?.button?.icon
                      : ''
                  }
                  width={24}
                  height={24}
                />
              ) : null
            }
          >
            {loginCurrentStep?.button?.label}
          </Button>
        </S.ContainerButton>
      </form>
    </FormProvider>
  )
}

export default HelpStep
