import { css } from 'styled-components'

import {
  // fade
  fadeIn,
  fadeOut,
  // scale
  scaleInCenter,
  scaleOutCenter,
  scale3D,
  // slide
  slideInTop,
  slideInBottom,
  slideInLeft,
  slideInRight,
  slideOutTop,
  slideOutBottom,
  slideOutLeft,
  slideOutRight
} from './keyframes'

const animations = {
  scale3D: css`
    animation-name: ${scale3D};
  `,
  // fade
  fadeIn: css`
    animation-name: ${fadeIn};
    opacity: 1;
  `,
  fadeOut: css`
    animation-name: ${fadeOut};
    opacity: 0;
  `,
  // scale
  scaleInCenter: css`
    animation-name: ${scaleInCenter};
    opacity: 1;
    transform: scale(1);
  `,
  scaleOutCenter: css`
    animation-name: ${scaleOutCenter};
    opacity: 0;
    transform: scale(0);
  `,
  // slide
  slideInTop: css`
    animation-name: ${slideInTop};
    transform: translateY(0);
    opacity: 1;
  `,
  slideInBottom: css`
    animation-name: ${slideInBottom};
    transform: translateY(0);
    opacity: 1;
  `,
  slideInLeft: css`
    animation-name: ${slideInLeft};
    transform: translateX(0);
    opacity: 1;
  `,
  slideInRight: css`
    animation-name: ${slideInRight};
    transform: translateX(0);
    opacity: 1;
  `,
  slideOutTop: css`
    animation-name: ${slideOutTop};
    transform: translateY(-1000px);
    opacity: 0;
  `,
  slideOutBottom: css`
    animation-name: ${slideOutBottom};
    transform: translateY(1000px);
    opacity: 0;
  `,
  slideOutLeft: css`
    animation-name: ${slideOutLeft};
    transform: translateX(-1000px);
    opacity: 0;
  `,
  slideOutRight: css`
    animation-name: ${slideOutRight};
    transform: translateX(1000px);
    opacity: 0;
  `
}

export default animations
