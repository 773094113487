import React from 'react'

import LoadingSpin from '@Components/LoadingSpin'
//import { usePage } from '@shared/hooks/usePage'

import { SLoading } from './styled'
import { ILoading } from './interface'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const loading = (props: ILoading) => {
  return (
    <SLoading data-testid="loading">
      <LoadingSpin variant="global" />
    </SLoading>
  )
}

export default loading
