import CONSTANTS from '@Constants/index'
import { TTrackingInfo } from '@shared/interfaces/Tracking'
import { useCookies } from './useCookies'
import TrackingService from '@Tracking/index'
import { useAlert } from './useAlert'
import { useSessionData } from '@shared/hooks/useSessionData'
import { useAuthToken } from '@shared/hooks/useAuthToken'
import { TSeatsPageData } from '@shared/context/interface'
import { getFormatedDateUTC } from '@Helpers/others'

export function UseTracking() {
  const { setCookie, getCookie } = useCookies()
  const { onSetIsOpenAlert } = useAlert()
  const { sessionData } = useSessionData()
  const { authToken } = useAuthToken()
  const theatre = sessionData?.session?.theatre
  const event = sessionData?.session?.event
  const session = sessionData?.session
  const partnership = sessionData?.urlQueryParams?.partnership
    ? sessionData.urlQueryParams?.partnership
    : ''
  const sessionType = session?.typeDescription
    .map((item) => {
      return item
    })
    .join(',')

  const trackOnAlertDiplayed = (data: {
    messageType: string
    message: string
  }) => {
    TrackingService.trackClickEvent('gaEventPush', data)
  }
  const openAlertTracked = (
    data: { type: string; title: string; content: string; isVisible: boolean },
    trackingInfo: TTrackingInfo
  ) => {
    const trackingPageInfo = getTrackingPageInfo()
    if (trackingPageInfo && !trackingPageInfo?.alertMessageWasDisplayed) {
      setTrackingPageInfo({
        ...trackingPageInfo,
        alertMessageWasDisplayed: true
      })
    }

    trackOnAlertDiplayed({
      messageType: trackingInfo.type,
      message: data.content
    })

    return onSetIsOpenAlert(data)
  }

  const getTrackingPageInfo = () => {
    const { TRACKING_PAGE_INFO } = CONSTANTS.COOKIE
    return getCookie(TRACKING_PAGE_INFO)
  }

  const setTrackingPageInfo = (data: TTrackingInfo) => {
    const { TRACKING_PAGE_INFO } = CONSTANTS.COOKIE
    setCookie(TRACKING_PAGE_INFO, data)
  }
  const trackAnyEvent = (description: string, properties: object) => {
    TrackingService.trackClickEvent('gaEventPush', properties)
  }

  const trackPageView = (properties: object) => {
    TrackingService.trackPageView('pageView', {
      page: properties,
      spectacle: {
        id: event?.id,
        name: event?.name,
        contentRating: event?.rating,
        distributor: event?.distributor,
        genres: event?.genre,
        directedBy: event?.directors,
        countries: '', //Tentar colocar depois
        runtime: event?.duration,
        openingDate: event?.releaseDate
          ? getFormatedDateUTC(event?.releaseDate)
          : '',
        year: '', //Tentar colocar depois
        trailerUrl: event?.trailers[0]?.url?.trim(),
        subtitle: session?.subtitles?.name,
        tag: '' // Eventg Tag... Tentar colocar depois
      },
      place: {
        id: theatre?.id,
        name: theatre?.name,
        exhibitorId: theatre?.corporationId,
        exhibitor: theatre?.corporation,
        neighborhood: theatre?.address.neighborhood,
        room: sessionData?.movieSession
      },
      session: {
        date: session?.dateFormatted?.slice(0, 10),
        time: session?.dateFormatted?.slice(11, 16),
        type: sessionType
      },
      user: {
        name: authToken?.user_first_name || 'visitante',
        id: authToken?.user_id || '',
        email: authToken?.email || '',
        cpf: authToken?.cpf || '',
        type: authToken?.user_first_name ? 'registered' : 'guest',
        language: 'pt-br'
      }
    })
  }
  // pagina de assentos

  const trackSeatMapSelectSeat = (properties: object) => {
    TrackingService.trackClickEvent('select_seats', properties)
  }

  const trackSeatMapShowSeatNumber = (properties: object) => {
    TrackingService.trackClickEvent('click_see_seat_number_button', properties)
  }

  const trackSeatMapZoom = (properties: object) => {
    TrackingService.trackClickEvent('click_zoom', properties)
  }

  const trackSeatMapNextBtn = (properties: TSeatsPageData) => {
    TrackingService.trackClickEvent('click_next', {
      event: 'gaEvent',
      e_event: 'click_next',
      e_action: properties.selected.length,
      e_label:
        properties.selected.findIndex((seat) => seat.specialSeat == true) > 0
          ? 'true'
          : 'false',
      e_description: ''
    })
  }
  // página de ingressos
  const trackTicketTypeQuantity = (properties: object) => {
    TrackingService.trackClickEvent('click_ticket_quantity', properties)
  }

  const trackTicketTypeNextBtn = (properties: object) => {
    TrackingService.trackClickEvent('click_next', properties)
  }
  const trackTicketKnowMoreClubUol = (properties: object) => {
    TrackingService.trackClickEvent('click_clube_uol_info', properties)
  }
  const trackTicketCloseButtonClubUol = (properties: object) => {
    TrackingService.trackClickEvent('click_clube_uol_box_close', properties)
  }
  const trackTicketCloseUnderstoodButtonClubUolModal = (properties: object) => {
    TrackingService.trackClickEvent('click_clube_uol_info_entendi', properties)
  }
  const trackTicketCloseButtonClubUolModal = (properties: object) => {
    TrackingService.trackClickEvent('click_clube_uol_info_close', properties)
  }
  const trackTicketModalLinkClickHereClubUol = (properties: object) => {
    TrackingService.trackClickEvent('click_clube_uol_info_lp', properties)
  }

  // página de bomboniere

  const trackBombSnackQuantity = (properties: object) => {
    TrackingService.trackClickEvent('click_snack_quantity', properties)
  }

  const trackBombNextBtn = (properties: object) => {
    TrackingService.trackClickEvent('click_next', properties)
  }

  // página de pagamentos

  const trackPaymentType = (properties: object) => {
    TrackingService.trackClickEvent('payment_type', properties)
  }

  const trackPaymentRemoveCard = (properties: object) => {
    TrackingService.trackClickEvent('remove_card', properties)
  }

  const trackPaymentNextBtn = (properties: object) => {
    TrackingService.trackClickEvent('click_next', properties)
  }
  const trackPaymentKnowMoreClubUol = (properties: object) => {
    TrackingService.trackClickEvent('click_clube_uol_info', properties)
  }
  const trackPaymentCloseUnderstoodButtonClubUolModal = (
    properties: object
  ) => {
    TrackingService.trackClickEvent('click_clube_uol_info_entendi', properties)
  }
  const trackPaymentCloseButtonInfoClubUolModal = (properties: object) => {
    TrackingService.trackClickEvent('click_clube_uol_info_close', properties)
  }
  const trackPaymentModalLinkClickHereClubUol = (properties: object) => {
    TrackingService.trackClickEvent('click_clube_uol_info_lp', properties)
  }
  const trackPaymentClubUolModalInvalidCpf = (properties: object) => {
    TrackingService.trackClickEvent('clube_uol_modal_invalid_cpf', properties)
  }
  const trackPaymentModalLinkClickHereClubUolInvalidCpf = (
    properties: object
  ) => {
    TrackingService.trackClickEvent(
      'click_ clube_uol_modal_invalid_cpf_lp',
      properties
    )
  }
  const trackPaymentModalLimitCpfClubUol = (properties: object) => {
    TrackingService.trackClickEvent(
      'clube_uol_modal_cpf_exceeded_limit',
      properties
    )
  }
  const trackPaymentRemoveDiscountClubUol = (properties: object) => {
    TrackingService.trackClickEvent(
      'click_remove_clube_uol_discount',
      properties
    )
  }
  const trackPaymentDiscountApplySuccessfull = (properties: object) => {
    TrackingService.trackClickEvent(
      'click_clube_uol_apply_discount_successfull',
      properties
    )
  }
  const trackPaymentDiscountApplyError = (properties: object) => {
    TrackingService.trackClickEvent(
      'click_clube_uol_apply_discount_error',
      properties
    )
  }
  const trackPaymentClubUolTooManyAttempts = (properties: object) => {
    TrackingService.trackClickEvent(
      'click_clube_uol_too_many_attempts',
      properties
    )
  }
  const trackPaymentClubUolInvalidRecaptcha = (properties: object) => {
    TrackingService.trackClickEvent(
      'click_clube_uol_invalid_recaptcha',
      properties
    )
  }
  const trackModalPixEvents = (properties: object) => {
    TrackingService.trackClickEvent('pix_event', properties)
  }

  // página de pedido concluido

  const trackFinishOpenTicketApp = (properties: object) => {
    TrackingService.trackClickEvent('click_open_ticket_app', properties)
  }
  const trackNewsLetterPoweredBy = (properties: object) => {
    TrackingService.trackClickEvent('quero_receber', properties)
  }

  const trackFinishShareBtn = (properties: object) => {
    TrackingService.trackClickEvent('click_share_order', properties)
  }

  const trackFinishCopySearchCode = (properties: object) => {
    TrackingService.trackClickEvent('click_copy_search_code', properties)
  }

  const trackFinishDownloadTickets = (properties: object) => {
    TrackingService.trackClickEvent('click_download_tickets', properties)
  }

  const trackFinishOpenDetails = (properties: object) => {
    TrackingService.trackClickEvent('click_order_detail_area', properties)
  }

  const trackFinishBuyAgain = (properties: object) => {
    TrackingService.trackClickEvent('click_buy_again', properties)
  }
  // Fluxo de login
  const trackLoginVerifyAccount = (properties: object) => {
    TrackingService.trackClickEvent('click_next', properties)
  }

  const trackLoginAsClient = (properties: object) => {
    TrackingService.trackClickEvent('click_login', properties)
  }

  const trackNewsLetterIngresso = (properties: object) => {
    TrackingService.trackClickEvent('', properties)
  }

  const trackLoginAsGuest = (properties: object) => {
    TrackingService.trackClickEvent('click_login_guest', properties)
  }

  const trackLoginResendCode = (properties: object) => {
    TrackingService.trackClickEvent('mfa_click_resend_code', properties)
  }

  const trackloginMfaNextBtn = (properties: object) => {
    TrackingService.trackClickEvent('click_mfa_next', properties)
  }

  const trackAddToCart = () => {
    TrackingService.trackAddToCartEvent({
      event: 'add_to_cart',
      page: {
        partnership
      },
      ecommerce: {
        currency: 'BRL',
        items: [
          {
            item_id: event?.id ? event?.id : '',
            item_name: event?.name ? event?.name : '',
            item_brand: event?.distributor ? event?.distributor : '',
            item_category: 'Ticket',
            affiliation: partnership
          }
        ]
      },
      spectacle: {
        id: event?.id,
        name: event?.name,
        contentRating: event?.rating,
        distributor: event?.distributor,
        genres: event?.genre,
        directedBy: event?.directors,
        countries: '',
        runtime: event?.duration,
        openingDate: event?.releaseDate
          ? getFormatedDateUTC(event?.releaseDate)
          : '',
        year: '',
        trailerUrl: event?.trailers[0]?.url?.trim(),
        subtitle: session?.subtitles?.name,
        tag: ''
      },
      place: {
        id: theatre?.id,
        name: theatre?.name,
        exhibitorId: theatre?.corporationId,
        exhibitor: theatre?.corporation,
        neighborhood: theatre?.address.neighborhood,
        room: sessionData?.movieSession
      },
      session: {
        date: session?.dateFormatted?.slice(0, 10),
        time: session?.dateFormatted?.slice(11, 16),
        type: sessionType
      },
      user: {
        name: authToken?.user_first_name || 'visitante',
        id: authToken?.user_id || '',
        email: authToken?.email || '',
        cpf: authToken?.cpf || '',
        type: authToken?.user_first_name ? 'registered' : 'guest',
        language: 'pt-br'
      }
    })
  }

  const trackBeginCheckout = () => {
    let flagIndex = 0
    let items = sessionData.selectedTickets.map((item) => {
      return {
        item_id: event?.id,
        item_name: event?.name || '',
        price: item.service || 0,
        quantity: item.quantity || 0,
        item_brand: event?.distributor ? event?.distributor : '',
        item_variant: item.name,
        item_category: 'Ticket',
        affiliation: partnership,
        index: flagIndex++
      }
    })
    if (
      sessionData?.selectedBomboniere &&
      sessionData?.selectedBomboniere.length
    ) {
      items = items.concat(
        sessionData?.selectedBomboniere.flatMap((item) => {
          return item.products.map((product) => {
            let itemId = ''
            if (product.concentrator) {
              itemId = `e${theatre?.id}c${product.id}`
            } else {
              itemId = `e${theatre?.id}p${product.id}`
            }
            return {
              item_id: itemId,
              item_name: product.name,
              price: product.service || 0,
              quantity: product.quantity || 0,
              item_brand: null,
              item_variant: null,
              item_category: 'Snack',
              affiliation: partnership,
              index: flagIndex++
            }
          })
        })
      )
    }
    const totalPrice = items.reduce((acc, item) => {
      return acc + Number(item.price * item.quantity)
    }, 0)
    TrackingService.trackBeginCheckoutEvent({
      event: 'begin_checkout',
      ecommerce: {
        value: Number(totalPrice.toFixed(2)),
        currency: 'BRL',
        items
      }
    })
  }

  const trackTransactionCheckout = (
    transactionId: string,
    payments?: Array<{ giftcardType: string; type: string }>
  ) => {
    let coupon = ''

    const discountsInPayments = payments?.filter(
      (item) => item.type === 'PaymentGiftCard'
    )

    if (discountsInPayments?.length) {
      coupon = discountsInPayments[0].giftcardType
    }
    // Items com apenas as taxas de serviço (igual o begin_checkout)
    let flagIndex = 0
    let items = sessionData?.selectedTickets?.map((item) => {
      return {
        item_id: event?.id,
        item_name: event?.name || '',
        price: item.service || 0,
        quantity: item.quantity || 0,
        item_brand: event?.distributor ? event?.distributor : '',
        item_variant: item.name,
        item_category: 'Ticket',
        affiliation: partnership,
        index: flagIndex++
      }
    })
    if (
      sessionData?.selectedBomboniere &&
      sessionData?.selectedBomboniere?.length
    ) {
      items = items?.concat(
        sessionData?.selectedBomboniere?.flatMap((item) => {
          return item.products.map((product) => {
            let itemId = ''
            if (product.concentrator) {
              itemId = `e${theatre?.id}c${product.id}`
            } else {
              itemId = `e${theatre?.id}p${product.id}`
            }
            return {
              item_id: itemId,
              item_name: product.name,
              price: product.service || 0,
              quantity: product.quantity || 0,
              item_brand: null,
              item_variant: null,
              item_category: 'Snack',
              affiliation: partnership,
              index: flagIndex++
            }
          })
        })
      )
    }
    // Items com apenas o valor puro dos tickets
    let flagTicketProduct = 0
    const itemsTicket = sessionData?.selectedTickets?.map((item) => {
      let price: number
      if (item.tax) {
        price = item.unitPrice - item.service - item.tax
      } else {
        price = item.unitPrice - item.service
      }
      return {
        item_id: event?.id,
        item_name: event?.name || '',
        price,
        quantity: item.quantity || 0,
        item_brand: event?.distributor ? event?.distributor : '',
        item_variant: item.name,
        item_category: 'Ticket',
        affiliation: partnership,
        index: flagTicketProduct++
      }
    })
    // Items (tickets e produtos) com valor puro
    let itemsTicketProduct = []
    itemsTicketProduct = itemsTicketProduct?.concat(itemsTicket)
    if (
      sessionData?.selectedBomboniere &&
      sessionData?.selectedBomboniere.length
    ) {
      itemsTicketProduct = itemsTicketProduct?.concat(
        sessionData?.selectedBomboniere?.flatMap((item) => {
          return item.products.map((product) => {
            let itemId = ''
            if (product.concentrator) {
              itemId = `e${theatre?.id}c${product.id}`
            } else {
              itemId = `e${theatre?.id}p${product.id}`
            }
            return {
              item_id: itemId,
              item_name: product.name,
              price: product.price || 0,
              quantity: product.quantity || 0,
              item_brand: null,
              item_variant: null,
              item_category: 'Snack',
              affiliation: partnership,
              index: flagTicketProduct++
            }
          })
        })
      )
    }

    const ticketQuantity = itemsTicket?.reduce((acc, item) => {
      return acc + Number(item.quantity)
    }, 0)

    let productQuantity = 0
    if (
      sessionData?.selectedBomboniere &&
      sessionData?.selectedBomboniere.length
    ) {
      productQuantity = sessionData?.selectedBomboniere?.reduce((acc, item) => {
        const qtd = item.products.reduce((acc2, product) => {
          return acc2 + Number(product.quantity)
        }, 0)
        return acc + qtd
      }, 0)
    }

    TrackingService.trackTransactionEvent({
      event: 'purchase',
      order: {
        ticket_quantity: ticketQuantity,
        product_quantity: productQuantity
      },
      ecommerce: {
        transaction_id: transactionId,
        value: Number(
          items
            .reduce((acc, item) => {
              return acc + Number(item.price * item.quantity)
            }, 0)
            .toFixed(2)
        ),
        currency: 'BRL',
        coupon,
        items
      },
      ecommerceTicket: {
        transaction_id: transactionId,
        value: Number(
          itemsTicket
            .reduce((acc, item) => {
              return acc + Number(item.price * item.quantity)
            }, 0)
            .toFixed(2)
        ),
        currency: 'BRL',
        coupon,
        items: itemsTicket
      },
      ecommerceTicketProduct: {
        transaction_id: transactionId,
        value: Number(
          itemsTicketProduct
            .reduce((acc, item) => {
              return acc + Number(item.price * item.quantity)
            }, 0)
            .toFixed(2)
        ),
        currency: 'BRL',
        coupon,
        items: itemsTicketProduct
      }
    })
  }

  return {
    trackOnAlertDiplayed,
    getTrackingPageInfo,
    setTrackingPageInfo,
    openAlertTracked,
    trackAnyEvent,
    trackPageView,
    trackSeatMapSelectSeat,
    trackSeatMapShowSeatNumber,
    trackSeatMapZoom,
    trackSeatMapNextBtn,
    trackTicketTypeQuantity,
    trackTicketTypeNextBtn,
    trackPaymentType,
    trackPaymentRemoveCard,
    trackPaymentNextBtn,
    trackFinishOpenTicketApp,
    trackFinishShareBtn,
    trackFinishCopySearchCode,
    trackFinishDownloadTickets,
    trackFinishOpenDetails,
    trackFinishBuyAgain,
    trackLoginVerifyAccount,
    trackLoginAsClient,
    trackLoginAsGuest,
    trackLoginResendCode,
    trackloginMfaNextBtn,
    trackAddToCart,
    trackTicketKnowMoreClubUol,
    trackTicketCloseButtonClubUol,
    trackTicketCloseUnderstoodButtonClubUolModal,
    trackTicketCloseButtonClubUolModal,
    trackTicketModalLinkClickHereClubUol,
    trackPaymentKnowMoreClubUol,
    trackPaymentModalLinkClickHereClubUol,
    trackPaymentCloseUnderstoodButtonClubUolModal,
    trackPaymentCloseButtonInfoClubUolModal,
    trackPaymentDiscountApplySuccessfull,
    trackPaymentDiscountApplyError,
    trackPaymentClubUolTooManyAttempts,
    trackPaymentClubUolInvalidRecaptcha,
    trackPaymentClubUolModalInvalidCpf,
    trackPaymentModalLimitCpfClubUol,
    trackPaymentModalLinkClickHereClubUolInvalidCpf,
    trackPaymentRemoveDiscountClubUol,
    trackBeginCheckout,
    trackTransactionCheckout,
    trackBombSnackQuantity,
    trackBombNextBtn,
    trackModalPixEvents,
    trackNewsLetterPoweredBy,
    trackNewsLetterIngresso
  }
}
