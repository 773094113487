import React, { useEffect, useState, forwardRef } from 'react'

import {
  SContainer,
  SLabel,
  SChange,
  SWrapper,
  SIcon,
  SInputMask,
  SInput
} from './styled'
import IProps from './interface'
import * as S from './styled'
import Icon from '@Components/Icon'
import { useFormContext } from 'react-hook-form'

const Input = forwardRef<HTMLInputElement, IProps>((props, ref) => {
  const {
    register,
    formState: { errors },
    watch,
    setValue
  } = useFormContext()
  const currentValue = watch(props?.inputName)
  const {
    lowerCase = false,
    initialValue,
    label,
    placeholder,
    rule,
    disabled,
    type,
    change,
    handleClick,
    pass,
    typeStopInteractionSuccess = 'success',
    hasActiveError,
    typeStopInteraction = 'interacting',
    applyMaskOnlyThisConditionIsTrue,
    mask = '',
    autoComplete = 'on',
    onPaste = () => null,
    errorMessage,
    validation,
    externalOnChange,
    ...rest
  } = props

  const [fieldIsValid, setValidity] = useState<boolean>(false)
  const [onPassword, setOnPassword] = useState<boolean>(false)
  const [errorText, setErrorText] = useState<string>(errorMessage)
  const [activeError, setActiveError] = useState<boolean>(hasActiveError)
  const [activeMask, setIsActiveMask] = useState<string>('')
  const [inputValue, setInputValue] = useState<string>(
    initialValue ? initialValue : currentValue
  )

  const [interactionStatus, setInteractionStatus] =
    useState<string>('neverInteracted')

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const value = lowerCase
      ? evt.target.value.toLowerCase().replaceAll(' ', '')
      : evt.target.value
    setInputValue(value)
    setValue(props?.inputName, value)
    setValidity(rule && rule(value))
    if (fieldIsValid) {
      setActiveError(false)
      setInteractionStatus('success')
    } else {
      setInteractionStatus('interacting')
    }

    if (externalOnChange) {
      externalOnChange(value)
    }

    handleFocus()
  }
  const onlyMaskDigit = /^[^a-zA-Z0-9]*$/
  const hasMaskDigit =
    onlyMaskDigit.test(inputValue) && inputValue.length && !pass
  const applyMask = applyMaskOnlyThisConditionIsTrue?.(inputValue) ? mask : ''

  const verifyApplyMask = () => {
    setIsActiveMask(applyMask && inputValue ? mask : hasMaskDigit && '')
    // setValue(hasMaskDigit ? '' : value)
  }

  useEffect(() => {
    if (errors && errors[props.inputName]?.message) {
      setInteractionStatus('error')
      setActiveError(true)
      setErrorText(errors[props.inputName].message as string)
    } else if (inputValue?.length > 0 && fieldIsValid) {
      setInteractionStatus('success')
      setActiveError(false)
    }
  }, [errors[props?.inputName]])

  useEffect(() => {
    verifyApplyMask()
  }, [inputValue])

  const verifyAutoComplete = (enable: string) => {
    // chrome ignores autoComplete for input password, only if pass str 'new-password' else not
    if (props.inputName === 'password') {
      return 'new-password'
    }
    return enable === 'on' ? 'on' : 'none'
  }
  const handleFocus = () =>
    setInteractionStatus(pass ? 'neverInteracted' : typeStopInteraction)
  const handleBlur = () => {
    setInteractionStatus(pass ? 'neverInteracted' : 'neverInteracted')
  }

  const onClickPassword = () => {
    setOnPassword(!onPassword)
  }
  return (
    <SWrapper>
      {!!change && <SChange onClick={handleClick}>{change}</SChange>}
      {pass && (
        <SIcon onClick={onClickPassword}>
          <Icon
            src={onPassword ? 'icons/password-show' : 'icons/password-hide'}
            color="#fff"
            size={24}
          />
        </SIcon>
      )}
      <SContainer
        rule={{ has: !!rule, isValid: fieldIsValid }}
        disabled={disabled}
        change={!!change}
        interactionStatus={interactionStatus}
        typeStopInteractionSuccess={typeStopInteractionSuccess}
        hasActiveError={activeError}
      >
        {applyMaskOnlyThisConditionIsTrue?.(inputValue) ? (
          <SInputMask
            value={inputValue}
            mask={activeMask}
            maskPlaceholder={null}
            autoComplete={verifyAutoComplete(autoComplete)}
            hasLabel={!!label}
            ref={ref}
            onPaste={onPaste}
            placeholder={placeholder}
            type={pass && !onPassword ? 'password' : type}
            {...register(props?.inputName, {
              ...validation,
              onChange: handleChange,
              onBlur: handleBlur
            })}
            onFocus={handleFocus}
            {...rest}
          />
        ) : (
          <SInput
            value={inputValue}
            autoComplete={verifyAutoComplete(autoComplete)}
            hasLabel={!!label}
            placeholder={placeholder}
            type={pass && !onPassword ? 'password' : type}
            ref={ref}
            {...register(props?.inputName, {
              ...validation,
              onChange: handleChange,
              onBlur: handleBlur
            })}
            onFocus={handleFocus}
            onPaste={onPaste}
            {...rest}
          />
        )}
        {label && <SLabel>{label}</SLabel>}
      </SContainer>
      {activeError && (
        <S.WarningContainer>
          <Icon color="#FF5A50" src="icons/warning" width={16} height={16} />
          <S.errorText>
            {(errors[props.inputName]?.message as string) || errorText}
          </S.errorText>
        </S.WarningContainer>
      )}
    </SWrapper>
  )
})

export const defaultProps = {
  disabled: false,
  initialValue: ''
}

Input.defaultProps = defaultProps

export default Input
