import React from 'react'

import { IProps } from './interface'
import { SCaptionRegular } from './styled'

const CaptionRegular = ({ children, className }: IProps) => {
  return <SCaptionRegular className={className}>{children}</SCaptionRegular>
}

export default CaptionRegular
