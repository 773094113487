export const PAYMENT_HOST = process.env.baseUrlPayment
export const TICKETING_HOST = process.env.baseUrlApiTicketing
export const API_AUTH = process.env.baseUrlApiAuth
export const PAYMENT_TOKENS = {
  get: () => {
    return `${PAYMENT_HOST}/payments/tokens`
  }
}

export const PAYMENT_METHODS = {
  get: (cartId: string) => {
    return `${TICKETING_HOST}/carts/${cartId}/payment-methods`
  }
}

export const PAYMENTS = {
  get: (cartId: string) => {
    return `${API_AUTH}/users/${cartId}/payments`
  }
}

export const SAVEDCARD_DELETE = {
  get: (userId: string, paymentCartId: string) => {
    return `${API_AUTH}/users/${userId}/payments/${paymentCartId}`
  }
}
export const APPLEPAY_VALIDATION = {
  post: (cartId: string) => {
    return `${TICKETING_HOST}/apple-pay/${cartId}/validate/`
  }
}
export const PAYMENT_STATUS = {
  get: (cardId: string) => {
    return `${TICKETING_HOST}/carts/${cardId}/get-payment-status-async`
  }
}
