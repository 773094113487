import React, { useEffect, useState } from 'react'
import Icon from '@Components/Icon'
import Modal from '@Components/Modal'
import Image from '@Components/Image'
import { useRouter } from 'next/router'
import {
  SESSION_REMOVE_SESSION,
  SESSION_REMOVE_SESSION_LOGO
} from '@Schemas/modal'
import { CollapseUser } from '@Components/CollapseUser'
import { useCookies } from '@shared/hooks/useCookies'
import { useAuthToken } from '@shared/hooks/useAuthToken'
import { useSessionData } from '@shared/hooks/useSessionData'
import { usePage } from '@shared/hooks/usePage'
import services from '@shared/services'
import { useCart } from '@shared/hooks/useCart'
import useIsClient from '@shared/hooks/useIsClient'
import useMediaQuery from '@shared/hooks/useMediaQuery'
import { useLoading } from '@shared/hooks/useLoading'
import CONSTANTS from '@Constants/index'
import * as S from './styled'
import { getThemeLogo } from '@shared/utils/theme'
import { useTheme } from 'styled-components'
import { useAdyenCheckoutContext } from '@shared/context/Adyen/AdyenContext'
import { ECARDTYPES } from '@shared/context/Adyen/reducer'
import { useWebView } from '@shared/hooks/useWebView'
import CheckoutUtils from '@shared/utils/cart'
/* const logoIcon =
  'https://ingresso-a.akamaihd.net/catalog/img/ingresso-logo-v1-h-small.png' */

interface ILogoData {
  logo: string
  logoMobile: string
  logoPb: string
  logoWidth: string
  logoMobileWidth: string
  widthPB: string
  widthPBMobile: string
  isPoweredBy: boolean
}
const Header = () => {
  const router = useRouter()
  const [modalLogo, isModalLogoOpen] = useState(false)
  const [modalCloseIcon, setIsClose] = useState(false)
  const { removeCookie, getCookie } = useCookies()
  const {
    authToken,
    isOpenCollapseUser,
    onSetIsOpenCollapseUser,
    onSetAuthToken
  } = useAuthToken()
  const { sessionData } = useSessionData()
  const { onSetPage, page } = usePage()
  const { cart } = useCart()
  const { isClient } = useIsClient()
  const { onSetIsLoading } = useLoading()
  const isMobile = useMediaQuery('(max-width: 991px)')
  const [logoData, setLogoData] = useState<ILogoData>()
  const { dispatch } = useAdyenCheckoutContext()
  const { getUrlParams } = CheckoutUtils()
  const queryParams = getUrlParams()
  const isUserLogged = Boolean(isClient && authToken?.user_id)
  const userFirstName = authToken?.user_first_name?.split(' ')[0] ?? ''
  const theme = useTheme()
  const { closeCheckout, isMobileApp } = useWebView()
  const handleOnConfirm = async () => {
    setIsClose(false)
    const [,] = await services.carts.deleteSession(cart?.id, cart?.sessionId)
    if (isMobileApp()) closeCheckout()
    else
      router.push(
        theme.data?.isPoweredBy ? theme.data?.url : process.env.baseUrlCatalog
      )
  }

  const onClearToken = () => {
    onSetIsOpenCollapseUser(false)
    const isPaymentPage = getCookie('page') === 'pagamentos'
    removeCookie(process.env.COOKIE_TOKEN_NAME)
    removeCookie(process.env.COOKIE_TOKEN_NAME_APP)
    onSetAuthToken(null)
    dispatch({
      type: ECARDTYPES.CARD_EXPRESS,
      payload: {
        data: {}
      }
    })
    if (sessionData?.hasSeatSelection && isPaymentPage) {
      onSetIsLoading(true)
      router.push({
        pathname: `/${CONSTANTS.PAGE.PAGE_NAME_SEATS}`,
        query: { ...queryParams }
      })
      onSetPage(CONSTANTS.PAGE.PAGE_NAME_SEATS)
    } else if (!sessionData?.hasSeatSelection && isPaymentPage) {
      onSetIsLoading(true)
      router.push({
        pathname: `/${CONSTANTS.PAGE.PAGE_NAME_TICKETS}`,
        query: { ...queryParams }
      })
      onSetPage(CONSTANTS.PAGE.PAGE_NAME_TICKETS)
    }
  }
  const handleThemeLogo = async () => {
    const logoDataLocal = await getThemeLogo(isMobile, theme)
    setLogoData(logoDataLocal)
  }
  useEffect(() => {
    handleThemeLogo()
  }, [theme.data, isMobile])

  const onClickLogo = () => {
    if (page === CONSTANTS.PAGE.PAGE_NAME_WAIT_PAYMENT) {
      if (isMobileApp()) closeCheckout()
      else
        window.open(
          'https://www.ingresso.com/',
          '_blank',
          'noopener,noreferrer'
        )
    } else {
      isModalLogoOpen(true)
    }
  }

  return (
    <S.Header>
      <S.HeaderContent>
        {logoData?.logoMobile ? (
          <>
            <S.LogoContainer onClick={onClickLogo}>
              <Image
                width={isMobile ? logoData.logoMobileWidth : logoData.logoWidth}
                src={isMobile ? logoData.logoMobile : logoData.logo}
                alt={'Logo da Ingresso.com'}
              />
            </S.LogoContainer>
            {logoData.isPoweredBy && (
              <S.LogoContainer>
                <span>Powered By</span>
                <Image
                  width={isMobile ? logoData.widthPBMobile : logoData.widthPB}
                  src={logoData.logoPb}
                  alt={'Logo da Ingresso.com'}
                />
              </S.LogoContainer>
            )}
            <S.BtnContainer isOpen={isOpenCollapseUser}>
              <ul>
                {page !== CONSTANTS.PAGE.PAGE_NAME_WAIT_PAYMENT && (
                  <li>
                    {isClient && !isMobile && (
                      <p>Olá, {isUserLogged ? userFirstName : 'visitante'}</p>
                    )}
                    <a rel="noreferrer">
                      <Icon
                        className="login iconHeader"
                        aria-label="Login"
                        src="icons/user-label"
                        height={24}
                        width={24}
                        onClick={() => {
                          onSetIsOpenCollapseUser(!isOpenCollapseUser)
                        }}
                      />
                    </a>
                  </li>
                )}

                {page !== CONSTANTS.PAGE.PAGE_NAME_WAIT_PAYMENT && (
                  <li className="close" onClick={() => setIsClose(true)}>
                    <div>
                      <Icon
                        aria-label="fechar"
                        className="iconHeader"
                        src="icons/close"
                        height={24}
                        width={24}
                      />
                    </div>
                  </li>
                )}
                <li className="support">
                  <p>Atendimento</p>
                  <a
                    rel="noreferrer"
                    href={process.env.ingHelpHost}
                    target="_blank"
                  >
                    <Icon
                      aria-label="atendimento"
                      className="iconHeader"
                      src="icons/faq"
                      height={19}
                      width={19}
                    />
                  </a>
                </li>
              </ul>
            </S.BtnContainer>
          </>
        ) : (
          <></>
        )}

        <Modal
          isOpen={modalLogo}
          title={SESSION_REMOVE_SESSION_LOGO?.title}
          description={SESSION_REMOVE_SESSION_LOGO?.description}
          iconModal={SESSION_REMOVE_SESSION_LOGO?.iconModal}
          confirmButton={SESSION_REMOVE_SESSION_LOGO?.confirmButton}
          closeButton={SESSION_REMOVE_SESSION_LOGO?.closeButton}
          onClose={() => isModalLogoOpen(false)}
          onConfirm={handleOnConfirm}
        />
        <Modal
          isOpen={modalCloseIcon}
          title={SESSION_REMOVE_SESSION?.title}
          description={SESSION_REMOVE_SESSION?.description}
          iconModal={SESSION_REMOVE_SESSION?.iconModal}
          confirmButton={SESSION_REMOVE_SESSION?.confirmButton}
          closeButton={SESSION_REMOVE_SESSION?.closeButton}
          onClose={() => setIsClose(false)}
          onConfirm={() => handleOnConfirm()}
        />
      </S.HeaderContent>
      <S.CollapseContainer>
        <CollapseUser
          isOpen={isOpenCollapseUser}
          clearToken={onClearToken}
          onClose={() => onSetIsOpenCollapseUser(false)}
        />
      </S.CollapseContainer>
    </S.Header>
  )
}
export default Header
