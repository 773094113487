import { IError, IIngressError } from './interface'

export const handleError = (error: IIngressError) => {
  let errorMessage = ''
  const errorFromModelState = error?.response?.data?.modelState

  if (errorFromModelState) {
    const errorKeys = ['ticketError', 'paymentError', 'cartError']

    for (const key of errorKeys) {
      if (errorFromModelState[key] && errorFromModelState[key].length > 0) {
        errorMessage = errorFromModelState[key][0]
        break
      }
    }
  }
  const errorData: IError = {
    message: error?.response?.data?.message,
    status: error?.response?.status,
    statusText: error?.response?.statusText,
    modelState: error?.response?.data?.modelState,
    errorMessage
  }
  return errorData as IError
}
