import theme from '@Styles/theme'
import styled, { css } from 'styled-components'

export const ContainerInput = styled.div`
  margin-bottom: 8px;
`
export const ContainerButton = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #ccc;
`
export const OtherButtonContent = styled.div`
  text-align: center;
  position: relative;
  display: inline-block;
  font-weight: bold;
  margin: 25px 0;

  h3 {
    text-align: center;
    color: #999;
  }

  &:after,
  :before {
    content: '';
    position: absolute;
    top: 50%;
    width: 40%;
    height: 0;
    border-bottom: 1px solid #ccc;
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }
`

export const HelpTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  flex-direction: row;
  color: ${theme.global.link.color};
  margin: 8px 0px 16px 0px;
  cursor: pointer;
`

export const VerifyCodeContainer = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 1rem;
    align-items: center;
  `}
`
export const ContentVerifyCode = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 1rem;
  `}
`
export const WarningContainerMain = styled.div`
  display: flex;
  justify-content: left;
  font-size: 14px;
  align-items: center;
  flex-direction: row;
  margin: 5px 0px;
  color: #ff5a50;
  svg {
    display: flex;
  }
`
export const WarningContainerLoginError = styled.div`
  display: flex;
  justify-content: center;
  font-size: 12px;
  align-items: center;
  flex-direction: row;
  margin: 5px 0px;
  color: #ff5a50;
  svg {
    display: flex;
  }
`
export const ErrorMessage = styled.span`
  text-align: left;
  margin-left: 8px;
  color: #ff5a50;
`
export const SendCode = styled.span`
  ${({ theme }) => css`
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${theme.button.primary.bgColor};
    cursor: pointer;
    margin-top: 24px;
  `}
`

export const Go = styled.span`
  ${({ theme }) => css`
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${theme.button.primary.bgColor};
    cursor: pointer;
    margin-top: 24px;
    width: 100%;
    text-align: right;
  `}
`
export const keepConnectedContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  input {
    margin: 0;
    width: 22px;
    height: 22px;

    :checked {
      accent-color: #fff;
    }
  }

  span {
    text-align: start;
    color: ${(props) => props.theme?.global.four.color};
  }
  margin-bottom: 8px;
`
export const NewsLetterPoweredBy = styled.div`
  display: flex;
  flex-direction: row;

  padding-top: 12px;
  gap: 6px;
  input {
    margin: 0;
    width: 22px;
    height: 22px;

    :checked {
      accent-color: #fff;
    }
  }

  p {
    text-align: start;
    width: 89%;
    color: ${(props) => props.theme?.global.four.color};
  }

  @media (max-width: 992px) {
    p {
      text-align: start;
      width: 90%;
    }
  }
  margin-bottom: 8px;
`
