export enum AvailableRating {
  FREE = 'L',
  TEN_YEARS = '10',
  TWELVE_YEARS = '12',
  FOURTEEN_YEARS = '14',
  SIXTEEN_YEARS = '16',
  EIGHTEEN_YEARS = '18',
  DEFAULT = '?',
  AFREE = 'AL',
  ATEN_YEARS = 'A10',
  ATWELVE_YEARS = 'A12',
  AFOURTEEN_YEARS = 'A14',
  ASIXTEEN_YEARS = 'A16',
  AEIGHTEEN_YEARS = 'A18',
  LIVRE = 'Livre',
  L = 'L'
}

type TOptions = {
  [key in AvailableRating]: string
}

interface IAgeRating {
  Green: string
  BlueCola: string
  DeepLemon: string
  PrincetonOrange: string
  Red: string
  Black: string
  Default: string
}

export const getRatingClass = (
  ageParental: IAgeRating,
  rating?: string
): string => {
  const colorsRating: TOptions = {
    [AvailableRating.FREE]: ageParental?.Green,
    [AvailableRating.LIVRE]: ageParental?.Green,
    [AvailableRating.TEN_YEARS]: ageParental?.BlueCola,
    [AvailableRating.TWELVE_YEARS]: ageParental?.DeepLemon,
    [AvailableRating.FOURTEEN_YEARS]: ageParental?.PrincetonOrange,
    [AvailableRating.SIXTEEN_YEARS]: ageParental?.Red,
    [AvailableRating.EIGHTEEN_YEARS]: ageParental?.Black,
    [AvailableRating.DEFAULT]: ageParental?.Default,
    [AvailableRating.AFREE]: ageParental?.Green,
    [AvailableRating.ATEN_YEARS]: ageParental?.BlueCola,
    [AvailableRating.ATWELVE_YEARS]: ageParental?.DeepLemon,
    [AvailableRating.AFOURTEEN_YEARS]: ageParental?.PrincetonOrange,
    [AvailableRating.ASIXTEEN_YEARS]: ageParental?.Red,
    [AvailableRating.AEIGHTEEN_YEARS]: ageParental?.Black
  }
  return getType(colorsRating, rating)
}

export const getTitle = (rating?: string): string => {
  const descRating: TOptions = {
    [AvailableRating.FREE]: 'livre',
    [AvailableRating.TEN_YEARS]: '10 anos',
    [AvailableRating.TWELVE_YEARS]: '12 anos',
    [AvailableRating.FOURTEEN_YEARS]: '14 anos',
    [AvailableRating.SIXTEEN_YEARS]: '16 anos',
    [AvailableRating.EIGHTEEN_YEARS]: '18 anos',
    [AvailableRating.DEFAULT]: '?',
    [AvailableRating.AFREE]: 'livre',
    [AvailableRating.ATEN_YEARS]: '10 anos',
    [AvailableRating.ATWELVE_YEARS]: '12 anos',
    [AvailableRating.AFOURTEEN_YEARS]: '14 anos',
    [AvailableRating.ASIXTEEN_YEARS]: '16 anos',
    [AvailableRating.AEIGHTEEN_YEARS]: '18 anos',
    [AvailableRating.LIVRE]: 'livre'
  }
  return `Classificação etária - ${getType(descRating, rating)}`
}

// export const getDescRating = (rating?: string): string => {
//   const descRating: TOptions = {
//     [AvailableRating.FREE]: 'Não há inadequações.',
//     [AvailableRating.TEN_YEARS]: 'Temas Sensíveis, Violência.',
//     [AvailableRating.TWELVE_YEARS]:
//       'Linguagem Imprópria,Temas Sensíveis, Violência.',
//     [AvailableRating.FOURTEEN_YEARS]:
//       'Atos Criminosos, Conteúdo Sexual, Linguagem Imprópria, Temas Sensíveis, Violência.',
//     [AvailableRating.SIXTEEN_YEARS]:
//       'Atos Criminosos, Conteúdo Sexual, Linguagem Imprópria, Nudez, Temas Sensíveis, Violência.',
//     [AvailableRating.EIGHTEEN_YEARS]:
//       'Atos Criminosos, Conteúdo Sexual, Linguagem Imprópria, Nudez, Temas Sensíveis, Violência.',
//     [AvailableRating.DEFAULT]: 'Consulte a classificação'
//   }
//   return getType(descRating, rating)
// }

export const getParental = (rating?: string): string => {
  const descRating: TOptions = {
    [AvailableRating.FREE]: AvailableRating.FREE,
    [AvailableRating.TEN_YEARS]: AvailableRating.TEN_YEARS,
    [AvailableRating.TWELVE_YEARS]: AvailableRating.TWELVE_YEARS,
    [AvailableRating.FOURTEEN_YEARS]: AvailableRating.FOURTEEN_YEARS,
    [AvailableRating.SIXTEEN_YEARS]: AvailableRating.SIXTEEN_YEARS,
    [AvailableRating.EIGHTEEN_YEARS]: AvailableRating.EIGHTEEN_YEARS,
    [AvailableRating.DEFAULT]: AvailableRating.DEFAULT,
    [AvailableRating.AFREE]: AvailableRating.AFREE,
    [AvailableRating.ATEN_YEARS]: AvailableRating.ATEN_YEARS,
    [AvailableRating.ATWELVE_YEARS]: AvailableRating.ATWELVE_YEARS,
    [AvailableRating.AFOURTEEN_YEARS]: AvailableRating.AFOURTEEN_YEARS,
    [AvailableRating.ASIXTEEN_YEARS]: AvailableRating.ASIXTEEN_YEARS,
    [AvailableRating.AEIGHTEEN_YEARS]: AvailableRating.AEIGHTEEN_YEARS,
    [AvailableRating.LIVRE]: AvailableRating.LIVRE
  }
  return getType(descRating, rating)
}

const getType = (options: TOptions, rating?: string): string => {
  const key = rating
    ? rating.replace(/livre/i, 'L').replace(/\sanos/i, '')
    : AvailableRating.DEFAULT
  return options?.[key] ? options?.[key] : options[AvailableRating.DEFAULT]
}

export const getRatingMessage = (rating?: string): string => {
  if (
    rating === AvailableRating.LIVRE ||
    rating === AvailableRating.FREE ||
    rating === AvailableRating.L ||
    rating === AvailableRating.AFREE
  ) {
    return 'Livre para todos os públicos.'
  }
  if (rating) {
    const age = rating.replace(/\D/g, '')
    return `Não recomendado para menores de ${age} anos.`
  }
  return 'Classificação não disponível.'
}
