import useIsClient from '@shared/hooks/useIsClient'
import { useState } from 'react'

import { IProps } from './interface'

export const useImage = (url: string, callBackImage = '') => {
  const { isClient } = useIsClient()
  const [hasErr, setErr] = useState(false)

  if (!isClient) return url

  const image = new Image()

  image.onerror = function () {
    setErr(true)
  }
  image.src = !hasErr ? url : callBackImage
  return image.src
}

const Component = ({
  callbackImg = '',
  src = '',
  width = '',
  height = '',
  className = '',
  alt
}: IProps) => {
  const checkedImg = useImage(src, callbackImg)

  return (
    <img
      width={width}
      height={height}
      alt={alt}
      src={checkedImg}
      className={className}
    />
  )
}

export default Component
