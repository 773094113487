// TICKETING API
export const TICKETING_HOST = process.env.baseUrlApiTicketing

export const CARTS = `${TICKETING_HOST}/carts`

export const CARTS_SESSIONS = {
  get: (cartId: string, sessionId: string) =>
    `${TICKETING_HOST}/carts/${cartId}/sessions/${sessionId}`,
  delete: (cartId: string, sessionId: string) =>
    `${TICKETING_HOST}/carts/${cartId}/sessions/${sessionId}`
}
export const CARTS_CARTID = {
  get: (cartId: string) => `${CARTS}/${cartId}`
}

export const CARTS_PAYMENTS = {
  get: (cartId: string) => `/carts/${cartId}/payments`
}

export const GUEST_ASSOCIATE = {
  get: (cartId: string) => `/carts/${cartId}/associate`
}

export const ADD_TICKETS = {
  get: (cartId: string, sessionId: string, sectionId: string) =>
    `/carts/${cartId}/sessions/${sessionId}/sections/${sectionId}/tickets`
}

export const CARTS_PROMOCODE = {
  get: (passwordPromocode: string, cartId: string) =>
    `/carts/${passwordPromocode}/promotion/${cartId}`
}
