import * as cartEndpoint from './carts'
import * as sessionsEndpoint from './sessions'
import * as ticketsEndpoint from './tickets'
import * as bomboniereEndpoint from './bomboniere'
import * as usersEndpoint from './users'
import * as giftEndpoints from './giftcard'
import * as orderEndpoints from './orders'
import * as paymentEndpoints from './payments'
import * as tokenEndpoints from './token'
import * as clubUol from './clubUol'

const endpoints = {
  cartEndpoint,
  sessionsEndpoint,
  ticketsEndpoint,
  usersEndpoint,
  giftEndpoints,
  orderEndpoints,
  paymentEndpoints,
  tokenEndpoints,
  bomboniereEndpoint,
  clubUol
}
export default endpoints
