import React, { useEffect } from 'react'

import Icon from '@Components/Icon'
import Button from '@Components/Button'

import {
  SOverlay,
  SModal,
  SModalHeader,
  SGrandientWithCircle,
  SIconWrapper,
  STitle,
  SDescription,
  SModalBody,
  SModalFooter,
  SCloseButton
  // SButton
} from './styled'

import { IProps } from './interface'
import { useWebView } from '@shared/hooks/useWebView'

const Modal = ({
  isOpen = false,
  title,
  description,
  iconModal,
  closeButton,
  confirmButton,
  onClose,
  onConfirm,
  customContent,
  sizeTitle,
  iconColor,
  heightIcon,
  widthIcon,
  unit = 'px',
  hasCloseButton = true,
  originalSizeIcon = false,
  customHeightContainerIcon,
  typeCloseButton = 'outlined',
  typeConfirmButton = 'default',
  customWidth,
  closeButtonClass = 'close-button',
  invertButtons = false
}: IProps) => {
  const haveTwoButtons = confirmButton && closeButton ? true : false
  const { isMobileApp } = useWebView()
  useEffect(() => {
    if (isMobileApp() && hasCloseButton) {
      window.history.pushState(null, document.title, window.location.href)
      window.addEventListener('hardwareBackPress', () => {
        onClose()
      })
      return () => {
        window.removeEventListener('hardwareBackPress', () => {
          window.history.pushState(null, document.title, window.location.href)
        })
      }
    }
    // eslint-disable-next-line
  }, [])
  return (
    <SOverlay isOpen={isOpen} data-testid="modal-test">
      <SModal isOpen={isOpen} customWidth={customWidth} className="link-colors">
        {hasCloseButton && (
          <SCloseButton>
            <Icon
              size={24}
              color="#0099FF"
              src="icons/close"
              onClick={onClose}
            />
          </SCloseButton>
        )}

        {iconModal && (
          <SModalHeader>
            <SIconWrapper>
              {originalSizeIcon ? (
                <Icon
                  customHeightContainerIcon={customHeightContainerIcon}
                  height={heightIcon}
                  width={widthIcon}
                  unit={'%'}
                  src={iconModal}
                  color={iconColor ? iconColor : 'none'}
                />
              ) : (
                <Icon
                  customHeightContainerIcon={customHeightContainerIcon}
                  height={heightIcon ? heightIcon : 84}
                  width={widthIcon ? widthIcon : 84}
                  unit={unit}
                  src={iconModal}
                  color={iconColor ? iconColor : 'none'}
                />
              )}
            </SIconWrapper>
            <SGrandientWithCircle />
            {title && <STitle size={sizeTitle}> {title} </STitle>}
          </SModalHeader>
        )}
        <SModalBody>
          <SDescription
            dangerouslySetInnerHTML={{ __html: description as string }}
          />
          {customContent}
        </SModalBody>
        <SModalFooter
          haveTwoButtons={haveTwoButtons}
          invertButtons={invertButtons}
        >
          {closeButton && (
            <Button
              onClick={onClose}
              className={closeButtonClass}
              variant={typeCloseButton}
              noUppercase={true}
            >
              {closeButton}
            </Button>
          )}
          {confirmButton && (
            <Button
              onClick={onConfirm}
              className={closeButtonClass ? closeButtonClass : 'confirm-button'}
              noUppercase={true}
              variant={typeConfirmButton}
            >
              {confirmButton}
            </Button>
          )}
        </SModalFooter>
      </SModal>
    </SOverlay>
  )
}

export default Modal
