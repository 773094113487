import styled, { css } from 'styled-components'
import { TButtonVariant } from './interface'

export const Button = styled.button<{
  variant?: TButtonVariant
  height?: string
  noUppercase?: boolean
}>`
  cursor: pointer;
  position: relative;
  border: ${(props) =>
    props.variant === 'default' ? 'none' : props.theme.button.primary.bgColor};
  border-size: ${(props) => (props.variant === 'default' ? 'none' : '2px')};
  border-style: ${(props) => (props.variant === 'default' ? 'none' : 'solid')};
  align-items: center;
  border-radius: 12px;
  display: flex;
  font-weight: 700;
  font-size: 14px;
  justify-content: center;
  min-height: ${(props) => (props.height ? props.height : '40px')};
  background-image: ${(props) => props.theme.button.gradient.bgColor};
  background-color: ${(props) =>
    props.variant === 'default'
      ? props.theme.button.primary.bgColor
      : 'transparent'};
  color: ${(props) =>
    props.variant === 'default'
      ? props.theme.button.primary.color
      : props.theme.button.primary.bgColor};
  text-transform: normal;
  width: ${(props) => (props.variant === 'default' ? 'auto' : '100%')};
  min-width: 140px;
  text-transform: ${({ noUppercase }) => (noUppercase ? 'none' : 'uppercase')};
  letter-spacing: 1px;
  gap: 14px;

  &.guest-button,
  &.main-button,
  &.order-button {
    background-image: ${(props) => props.theme.button.gradient.loginBgColor};
    color: ${(props) => props.theme.button.gradient.loginColor};
  }
  &.guest-button {
    ${(props) =>
      props.theme.data?.isPoweredBy &&
      css`
        color: ${(props) => props.theme.button.gradient.loginBgColor};
      `};
  }
  &.order-button {
    gap: 5px;
    padding: 0.75rem 2rem;
  }

  ${(props) =>
    props.variant === 'outlined' &&
    css`
      background-image: none !important;
    `}

  :hover {
    opacity: 0.8;
  }

  &:disabled {
    background-color: ${(props) => props.theme.button?.disabled?.bgColor};
    color: ${(props) => props.theme.button.primary.color};
    background-image: none;
    cursor: not-allowed;

    ${(props) =>
      props.variant === 'outlined' &&
      css`
        background-color: transparent;
        color: ${(props) => props.theme.button?.disabled?.bgColor};
        border-color: ${(props) => props.theme.button?.disabled?.bgColor};
      `}

    :hover {
      opacity: 1;
    }
  }
`

export const IconLeft = styled.span<{ isInitialPositionIcon: boolean }>`
  position: absolute;
  left: ${(props) => (!props?.isInitialPositionIcon ? '10px' : '75px')};
`
