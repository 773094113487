import styled from 'styled-components'

export const ContainerSession = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  text-align: left;

  @media (min-width: 992px) {
    background: ${(props) => props.theme?.sessionContainerMovie.bgColor};
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
  }
`

export const MovieWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const MovieBanner = styled.div`
  display: none;

  @media (min-width: 992px) {
    display: block;
  }
`

export const MovieInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 12px;

  @media (min-width: 992px) {
    font-size: 14px;
  }
`

export const MovieSession = styled.span`
  font-weight: 600;
  text-transform: uppercase;

  :before {
    content: ' - ';
  }
`

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ theme }) => theme?.ticketBox?.nameColor};

  @media (min-width: 992px) {
    strong {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 15ch;
    }
  }
`

export const Container = styled.div`
  height: auto;
  border-radius: 4px;

  @media (min-width: 992px) {
    margin-right: 16px;
  }
`
export const SessionTags = styled.p`
  background-color: #3255e2;
  display: inline-block;
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 12px;
  padding: 2px 4px 0px 4px;
  height: auto;
  color: #f0f0f0 !important;
  letter-spacing: 0.4000000059604645px;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
`

export const MovieSessionContainer = styled.div`
  display: flex;
  gap: 4px;
  @media (min-width: 992px) {
    flex-wrap: wrap;
    margin-top: 4px;
  }
`
export const EventLocation = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 4px 0;
  color: ${({ theme }) => theme?.global?.text.color};
  font-size: 12px;

  @media (min-width: 992px) {
    display: block;
    margin: 4px 0;
  }
`
export const EventDate = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  text-transform: uppercase;
  font-size: 16px;
`

export const SessionDateTime = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-top: 1px solid #666666 !important;
  padding-top: 8px !important;
  color: ${({ theme }) => theme?.ticketBox?.nameColor};
`

export const Time = styled.span`
  font-weight: bold;
`

export const City = styled.p`
  @media (min-width: 992px) {
    font-size: 12px;
  }
`
export const Theater = styled.p`
  @media (min-width: 992px) {
    font-size: 14px;
  }
`

export const Details = styled.a`
  display: flex;
  align-items: baseline;
  gap: 4px;
  @media (min-width: 992px) {
    font-size: 12px;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 12px;
  gap: 4px;
`

export const SeatContainer = styled.div``
