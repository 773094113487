import { FormProvider, useForm } from 'react-hook-form'
import Input from '@Components/Input'
import Button from '@Components/Button'
import { cpfMask } from '@Helpers/CPF'
import { useLogin } from '@shared/hooks/useLogin'
import { handleCpfEmail } from '@Helpers/others'
import * as S from './styled'
import Icon from '@Components/Icon'
import Text from '@Components/Text'
import services from '@Services/index'
import { useAuthToken } from '@shared/hooks/useAuthToken'
import Benefits from '../Benefits'
import { useCookies } from '@shared/hooks/useCookies'

import { useEffect, useState } from 'react'
import { useModal } from '@shared/hooks/useModal'
import { VerifyCode } from '@Components/VerifyCode'
import { useSessionData } from '@shared/hooks/useSessionData'
import { UseTracking } from '@shared/hooks/useTracking'
import { useLoading } from '@shared/hooks/useLoading'
import CheckoutUtils from '@shared/utils/cart'
import { useWebView } from '@shared/hooks/useWebView'

const LoginAccountStep = ({ recaptchaRefV3, canGoPayment }) => {
  const methods = useForm()
  const { register } = methods
  const {
    getPartnerShipName,
    isPoweredByAndShareData,
    verifyShowCheckBoxPoweredBy
  } = CheckoutUtils()
  const { getCookie, removeCookie, setCookie } = useCookies()
  const [currrentValueCode, setCurrentValueCode] = useState('')
  const { onSetIsLoadingButton, loadingButton } = useLoading()

  const {
    loginCurrentStep,
    emailOrCpfValueContext,
    onSetCurrentLoginStepNumberAndPage,
    onSetIsFromLoginAccount,
    setOpenVerifyCode,
    openVerifyCode,
    onSetIsOpenCollapseUser,
    onSetIsOpenLogin,
    verifyCodeValue,
    errorDescriptionToken,
    setVerifyCodeValue,
    setErrorDescriptionToken,
    openLogin
  } = useLogin()
  const {
    trackLoginAsClient,
    trackLoginAsGuest,
    trackLoginResendCode,
    trackloginMfaNextBtn,
    trackNewsLetterIngresso
  } = UseTracking()

  const { onSetAuthToken } = useAuthToken()
  const { sessionData } = useSessionData()
  const isGuestEnable = sessionData?.functionalities?.guestCheckout
  const [errorMessageApi, setErrorMessageApi] = useState('')
  const { onSetIsOpenModal } = useModal()
  const { getClientOrigin } = useWebView()
  const fingerprint = getCookie(process.env.COOKIE_FINGERPRINT)

  const { handleSubmit, getValues } = methods
  const emailRegex = /\S+@\S+\.\S+/
  const isEmail = emailRegex.test(emailOrCpfValueContext)

  const getEmailOrCpfValue = () => {
    if (isEmail) {
      return emailOrCpfValueContext
    } else {
      return cpfMask(emailOrCpfValueContext, '')
    }
  }
  const onSubmit = async () => {
    onSetIsLoadingButton(true)
    trackLoginAsClient({
      event: 'gaEvent',
      e_event: 'click_login',
      e_action:
        emailOrCpfValueContext.toString().indexOf('@') == -1 ? 'cpf' : 'email',
      e_label: 'Login Account modal',
      e_description: ''
    })
    const reCAPTCHAToken = await recaptchaRefV3.current.execute()
    const userName = !isEmail
      ? getValues('emailOrCpf')?.replace(/-/g, '').split('.').join('')
      : getValues('emailOrCpf')
    const password = getValues('password')
    if (reCAPTCHAToken) {
      const payload = {
        username: userName?.toLowerCase(),
        password,
        reCAPTCHAToken,
        grant_type: 'password',
        client_origin: getClientOrigin(),
        verificationCode: undefined,
        fingerprint: getCookie(process.env.COOKIE_FINGERPRINT) ?? '',
        keep_me_connected: getValues('keepmeconnected')
      }
      const { errorType, errorMessage, status, data } =
        await services.token.getToken(payload)

      if (status === 200) {
        if (data?.password_expired_token === undefined) {
          const expiration = new Date()
          expiration.setFullYear(expiration.getFullYear() + 1)
          const cookieRefreshToken = getCookie(
            process.env.COOKIE_REFRESH_TOKEN_NAME
          )
          if (cookieRefreshToken) {
            const refreshTokenData = data.refresh_token
            removeCookie(process.env.COOKIE_REFRESH_TOKEN_NAME)
            setCookie(process.env.COOKIE_REFRESH_TOKEN_NAME, refreshTokenData)
          } else {
            setCookie(
              process.env.COOKIE_REFRESH_TOKEN_NAME,
              data.refresh_token,
              expiration
            )
          }
          if (payload.keep_me_connected !== undefined) {
            setCookie(
              process.env.COOKIE_KEEP_ME_CONNECTED,
              payload.keep_me_connected,
              expiration
            )
          }
        }
        if (data) {
          const payload = {
            access_token: data.access_token,
            user_first_name: data.user_first_name,
            user_id: data.user_id,
            cpf: data.user_cpf,
            email: data.user_email,
            id: data.user_id,
            loginType: data.login_type,
            type: data.token_type,
            refresh_token: data.refresh_token,
            client_origin: data.client_origin,
            expires_in: data.expires_in,
            issued: data['.issued'],
            expires: data['.expires'],
            fingerprint: fingerprint
          }
          setOpenVerifyCode(false)
          onSetAuthToken(payload, true)

          const [dataUser] = await services.users.getUserLogged(data.user_id)
          onSetAuthToken(
            {
              ...payload,
              cpf: dataUser.account.cpf,
              email: dataUser.email
            },
            true
          )
          if (verifyShowCheckBoxPoweredBy()) {
            const user = {
              user: {
                optinPartnership: getValues('newsletterpoweredby') ? 'Y' : '',
                cpf: dataUser.account.cpf
              }
            }
            trackNewsLetterIngresso(user)
            setCookie(
              process.env.cookieNewsLetterPoweredBy,
              getValues('newsletterpoweredby')
            )
          }
          onSetIsOpenCollapseUser(true)
          //verificar se os ingressos estão validos
          if (openLogin.onNextPage) canGoPayment()
        }
        onSetIsOpenLogin({ show: false })
      }
      if (status === 400 && errorType === 'invalid_grant') {
        setErrorMessageApi(errorMessage)
        setErrorDescriptionToken(errorMessage)
        setTimeout(() => {
          setErrorMessageApi('')
        }, 10000)
      }
      if (errorType === 'need_to_verify' && status === 400) {
        setOpenVerifyCode(true)
        setErrorDescriptionToken('')
      }
      if (errorType === 'email_not_confirmed' && status === 400) {
        handleContinue(true, true)
        setErrorDescriptionToken('')
      }
    }
    onSetIsLoadingButton(false)
  }

  const handleContinue = (verifyCode: boolean, openVerify: boolean) => {
    if (currrentValueCode?.length < 6 && !verifyCodeValue) {
      setErrorDescriptionToken('Digite o código de verificação.')
      setTimeout(() => {
        setErrorDescriptionToken('')
      }, 3000)
    }
    trackloginMfaNextBtn({
      event: 'gaEvent',
      e_event: 'click_mfa_next',
      e_action:
        emailOrCpfValueContext.toString().indexOf('@') == -1 ? 'cpf' : 'email',
      e_label: 'Login MFA modal',
      e_description: ''
    })
    setVerifyCodeValue(verifyCode)
    setOpenVerifyCode(openVerify)
  }
  const handleSendCode = async () => {
    onSetIsLoadingButton(true)
    trackLoginResendCode({
      event: 'gaEvent',
      e_event: 'mfa_click_resend_code',
      e_action:
        emailOrCpfValueContext.toString().indexOf('@') == -1 ? 'cpf' : 'email',
      e_label: 'Login MFA modal',
      e_description: ''
    })
    const reCAPTCHAToken = await recaptchaRefV3.current.executeAsync()
    const userName = !isEmail
      ? getValues('emailOrCpf').replace(/-/g, '').split('.').join('')
      : getValues('emailOrCpf')

    if (reCAPTCHAToken) {
      const payload = {
        username: userName.toLowerCase(),
        grant_type: 'resend_verification_code_email',
        client_origin: getClientOrigin(),
        keep_me_connected: getValues('keepmeconnected')
      }

      const { errorMessage, status } = await services.token.getToken(payload)

      if (errorMessage === 'Código reenviado.' && status === 400) {
        handleContinue(true, true)
        setErrorDescriptionToken('')
      }
    }
    onSetIsLoadingButton(false)
  }
  const handleVerifyCode = async (e) => {
    onSetIsLoadingButton(true)
    const userName = !isEmail
      ? getValues('emailOrCpf').replace(/-/g, '').split('.').join('')
      : getValues('emailOrCpf')
    const reCAPTCHAToken = await recaptchaRefV3.current.executeAsync()
    if (reCAPTCHAToken && e.length === 6) {
      const payload = {
        username: userName.toLowerCase(),
        password: getValues('password'),
        reCAPTCHAToken,
        grant_type: 'verification_code',
        client_origin: getClientOrigin(),
        verificationCode: e,
        fingerprint: '',
        keep_me_connected: getValues('keepmeconnected')
      }

      const { data, status, errorMessage, errorType } =
        await services.token.getToken(payload)

      if (status === 400 && errorType === 'invalid_grant') {
        setErrorDescriptionToken(errorMessage)
      }

      if (status === 200) {
        if (data?.password_expired_token === undefined) {
          const expiration = new Date()
          expiration.setFullYear(expiration.getFullYear() + 1)
          const cookieRefreshToken = getCookie(
            process.env.COOKIE_REFRESH_TOKEN_NAME
          )
          if (cookieRefreshToken) {
            const refreshTokenData = data.refresh_token
            removeCookie(process.env.COOKIE_REFRESH_TOKEN_NAME)
            setCookie(process.env.COOKIE_REFRESH_TOKEN_NAME, refreshTokenData)
          } else {
            setCookie(
              process.env.COOKIE_REFRESH_TOKEN_NAME,
              data.refresh_token,
              expiration
            )
          }
          if (payload.keep_me_connected !== undefined) {
            setCookie(
              process.env.COOKIE_KEEP_ME_CONNECTED,
              payload.keep_me_connected,
              expiration
            )
          }
        } else {
          removeCookie(process.env.COOKIE_TOKEN_NAME)
          removeCookie(process.env.COOKIE_TOKEN_NAME_APP)
        }
        if (data) {
          const payload = {
            access_token: data.access_token,
            user_first_name: data.user_first_name,
            user_id: data.user_id,
            cpf: !isEmail
              ? getValues('emailOrCpf').replace(/-/g, '').split('.').join('')
              : '',
            email: data.user_email,
            id: data.user_id,
            loginType: data.login_type,
            type: data.token_type,
            refresh_token: data.refresh_token,
            client_origin: data.client_origin,
            expires_in: data.expires_in,
            issued: data['.issued'],
            expires: data['.expires'],
            fingerprint: fingerprint
          }
          onSetIsOpenModal({ onClose: () => setOpenVerifyCode(false) })

          onSetAuthToken(payload, true)
          onSetIsOpenCollapseUser(true)

          if (isEmail) {
            const [dataUser] = await services.users.getUserLogged(data.user_id)
            onSetAuthToken(
              {
                ...payload,
                cpf: dataUser?.account?.cpf,
                email: dataUser.email
              },
              true
            )
          }
          if (verifyShowCheckBoxPoweredBy()) {
            const user = {
              user: {
                optinPartnership: getValues('newsletterpoweredby') ? 'Y' : ''
              }
            }
            trackNewsLetterIngresso(user)
            setCookie(
              process.env.cookieNewsLetterPoweredBy,
              getValues('newsletterpoweredby')
            )
          }
          if (openLogin.onNextPage) canGoPayment()
        }
        resetStates()
        onSetIsOpenLogin({ show: false })
      }
    }
    onSetIsLoadingButton(false)
  }
  const resetStates = () => {
    setOpenVerifyCode(false)
    setCurrentValueCode('')
    setErrorDescriptionToken('')
  }
  useEffect(() => {
    onSetIsOpenModal({
      isOpen: openVerifyCode,
      onClose: () => resetStates(),
      title: !verifyCodeValue ? 'Falta pouco!' : 'Não chegou?',
      description: !verifyCodeValue
        ? 'Enviamos um código de verificação para o seu e-mail. Digite-o abaixo:'
        : 'Pronto! Reenviamos para seu e-mail cadastrado um novo código de confirmação. Verifique novamente a sua caixa de e-mail. Lembre-se também de verificar a sua caixa de SPAM.',
      iconModal: !verifyCodeValue ? 'illustrated/email' : 'illustrated/lock',
      customContent: (
        <S.VerifyCodeContainer>
          <S.ContentVerifyCode>
            {!verifyCodeValue && (
              <>
                <VerifyCode
                  length={6}
                  onCompleted={handleVerifyCode}
                  onSetParentValueCode={(value) => {
                    setCurrentValueCode(value)
                  }}
                />
                {errorDescriptionToken && (
                  <S.WarningContainerMain>
                    <Icon
                      color="#FF5A50"
                      src="icons/warning"
                      width={15}
                      height={15}
                    />
                    <S.ErrorMessage>{errorDescriptionToken}</S.ErrorMessage>
                  </S.WarningContainerMain>
                )}
                <S.SendCode onClick={handleSendCode}>
                  Reenviar código
                </S.SendCode>
              </>
            )}
          </S.ContentVerifyCode>
          <S.Go onClick={() => handleContinue(false, true)}>Continuar</S.Go>
        </S.VerifyCodeContainer>
      )
    })
  }, [
    openVerifyCode,
    verifyCodeValue,
    errorDescriptionToken,
    currrentValueCode
  ])

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset>
          {loginCurrentStep?.inputs?.map((inputItem) => (
            <S.ContainerInput key={inputItem.id}>
              <Input
                handleClick={() => onSetCurrentLoginStepNumberAndPage(1, 1)}
                initialValue={inputItem.id === 1 ? getEmailOrCpfValue() : ''}
                key={inputItem.id}
                disabled={false}
                label={
                  inputItem.id === 1
                    ? handleCpfEmail(emailOrCpfValueContext).label
                    : inputItem.label
                }
                pass={inputItem?.pass}
                rule={inputItem.valid}
                change={inputItem?.change}
                inputName={inputItem.inputName}
                validation={inputItem?.validation}
                maxLength={256}
              />
            </S.ContainerInput>
          ))}
        </fieldset>
        {errorMessageApi?.length > 0 && (
          <S.WarningContainerLoginError>
            <Icon color="#FF5A50" src="icons/warning" width={20} height={20} />
            <S.ErrorMessage>{errorMessageApi}</S.ErrorMessage>
          </S.WarningContainerLoginError>
        )}

        {loginCurrentStep.helpRedirect && (
          <S.HelpTitle
            onClick={() => {
              onSetCurrentLoginStepNumberAndPage(3, 1)
            }}
          >
            {loginCurrentStep.helpRedirect}
          </S.HelpTitle>
        )}
        <S.keepConnectedContainer>
          <input
            name="keepmeconnected"
            type="checkbox"
            {...register('keepmeconnected', {})}
          />
          <span>Mantenha-me conectado</span>
        </S.keepConnectedContainer>
        {isPoweredByAndShareData() && verifyShowCheckBoxPoweredBy() && (
          <S.NewsLetterPoweredBy>
            <input
              name="newsletterpoweredby"
              type="checkbox"
              {...register('newsletterpoweredby', {})}
            />
            <p>Quero receber novidades e mensagens do {getPartnerShipName()}</p>
          </S.NewsLetterPoweredBy>
        )}

        <S.ContainerButton>
          <Button
            isInitialPositionIcon={
              loginCurrentStep?.button?.isInitialPositionIcon
            }
            height={
              loginCurrentStep?.button?.height
                ? loginCurrentStep?.button?.height
                : '48px'
            }
            className="main-button"
            loading={loadingButton}
            onClick={() => onSubmit}
            iconLeft={
              loginCurrentStep?.button?.icon ? (
                <Icon
                  color="#460505"
                  src={
                    loginCurrentStep?.button.icon
                      ? loginCurrentStep?.button?.icon
                      : ''
                  }
                  width={24}
                  height={24}
                />
              ) : null
            }
          >
            {loginCurrentStep?.button?.label}
          </Button>
        </S.ContainerButton>
      </form>

      {isGuestEnable && (
        <S.OtherButtonContent>
          <Text
            type="HEADLINE"
            text={loginCurrentStep?.otherButton.title}
            className="other-title"
          />
        </S.OtherButtonContent>
      )}
      {loginCurrentStep?.otherButton && isGuestEnable && (
        <>
          <Button
            height={
              loginCurrentStep?.otherButton?.height
                ? loginCurrentStep?.otherButton?.height
                : '48px'
            }
            href="#"
            className="guest-button"
            variant={loginCurrentStep?.otherButton.variant}
            disabled={loadingButton}
            onClick={() => {
              onSetIsFromLoginAccount(true)
              onSetCurrentLoginStepNumberAndPage(3, 2)
              trackLoginAsGuest({
                event: 'gaEvent',
                e_event: 'click_login_guest',
                e_action: '',
                e_label: 'Login Account modal',
                e_description: ''
              })
            }}
          >
            {loginCurrentStep?.otherButton.label}
          </Button>
        </>
      )}
      <Benefits />
    </FormProvider>
  )
}

export default LoginAccountStep
