import { keyframes } from 'styled-components'

// Fade
// Fade In
export const fadeIn = keyframes`
  from {opacity: 0;}
  to {opacity: 1;}
`
// Fade Out
export const fadeOut = keyframes`
  from {opacity: 1;}
  to {opacity: 0;}
`

// Scale
// Scale In
export const scaleInCenter = keyframes`
  from { opacity: 1; transform: scale(0); }
  to { opacity: 1; transform: scale(1); }
`
// Scale Out
export const scaleOutCenter = keyframes`
  from { opacity: 1; transform: scale(1); }
  to { opacity: 1; transform: scale(0); }
`
// Scale 3D
export const scale3D = keyframes`
  0% { transform: scale3d(1, 1, 1); }
  50% { transform: scale3d(1.8, 1.8, 1.8); }
  100% { transform: scale3d(1, 1, 1); }
`

// Slide
// Slide In
export const slideInTop = keyframes`
  from { opacity: 0; transform: translateY(-1000px); }
  to { opacity: 1; transform: translateY(0); }
`
export const slideInBottom = keyframes`
  from { opacity: 0; transform: translateY(1000px); }
  to { opacity: 1; transform: translateY(0); }
`
export const slideInLeft = keyframes`
  from { opacity: 0; transform: translateX(-1000px); }
  to { opacity: 1; transform: translateX(0); }
`
export const slideInRight = keyframes`
  from { opacity: 0; transform: translateX(1000px); }
  to { opacity: 1; transform: translateX(0); }
`
// Slide Out
export const slideOutTop = keyframes`
  from { opacity: 1; transform: translateY(0); }
  to { opacity: 0; transform: translateY(-1000px); }
`
export const slideOutBottom = keyframes`
  from { opacity:1 ; transform: translateY(0); }
  to { opacity: 0; transform: translateY(1000px); }
`
export const slideOutLeft = keyframes`
  from { opacity: 1; transform: translateX(0); }
  to { opacity: 0; transform: translateX(-1000px); }
`
export const slideOutRight = keyframes`
  from { opacity: 1; transform: translateX(0); }
  to { opacity: 0; transform: translateX(1000px); }
`
