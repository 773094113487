import { ticketingAPI } from 'src/shared/request/Api'
import { handleError } from '@shared/request/error'
import endpoints from 'src/shared/endpoints'

export const READ = async (sessionId: string, sectionId: string) => {
  try {
    const { data } = await ticketingAPI.get(
      endpoints.ticketsEndpoint.SESSIONS_SECTIONS_TICKETS.get(
        sessionId,
        sectionId
      )
    )
    return [data, null]
  } catch (error) {
    return [null, handleError(error)]
  }
}
