import styled, { css } from 'styled-components'

import { IRule, ITypes } from './interface'
import InputMask from 'react-input-mask'

const getStyles = (
  { has, isValid }: IRule,
  interactionStatus: string,
  typeStopInteractionSuccess: string,
  hasActiveError: boolean
) => {
  const availableStatus: ITypes = {
    neverInteracted: `border: 1px solid #999999; border-left: 4px solid #999999;`,
    interacting: `border: 1px solid #98AAEC; border-left-width: 4px;`,
    warning: `border: 1px solid #FFA928; border-left-width: 4px;`,
    error: `border: 1px solid #ff3300; border-left-width: 4px;`,
    success: `border: 1px solid #00cc00; border-left-width: 4px;`
  }

  if (interactionStatus && has && isValid && !hasActiveError) {
    return availableStatus[typeStopInteractionSuccess]
  } else if (interactionStatus && !isValid && hasActiveError) {
    return availableStatus['error']
  } else {
    return availableStatus[interactionStatus]
  }
}

export const SWrapper = styled.div`
  position: relative;
`
export const WarningContainer = styled.span`
  display: flex;
  justify-content: flex-start;
  font-size: 14px;
  align-items: center;
  float: 20px;
  margin: 8px 0px 16px 0px;
  color: #ff5a50;
`
export const errorText = styled.p`
  text-align: left;
  margin-left: 8px;
`

export const SContainer = styled.div<{
  rule: IRule
  interactionStatus: string
  typeStopInteractionSuccess: string
  hasActiveError: boolean
  disabled: boolean
  change: boolean
}>`
  display: flex;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  background: white;
  width: 100%;
  height: 48px;
  border-radius: 4px;
  transition: 0.2s;
  ${(props) =>
    props.disabled
      ? `
          border: 1px solid #cccccc;
          border-left-width: 4px;
          background-color: #f2f2f2;
          pointer-events: none;

          ${SInputMask} {
            background-color: #f2f2f2;

            &::placeholder {
              color: #cccccc;
            }
          }
      `
      : getStyles(
          props.rule,
          props.interactionStatus,
          props.typeStopInteractionSuccess,
          props.hasActiveError
        )}
  ${({ change }) => css`
    ${change &&
    `
          border: 0px;
          border-left-width: 0px;
          background-color: transparent;
          pointer-events: none;

          ${SInputMask} {
            background-color: transparent;
            color: #999999;

            &::placeholder {
              color: #cccccc;
            }
          }
          ${SInput} {
            background-color: transparent;
            color: #999999;

            &::placeholder {
              color: #cccccc;
            }
          }
      `}
  `}
`

export const SIcon = styled.div`
  ${() => css`
    position: absolute;
    right: 16px;
    top: 12px;
    z-index: 1;
    cursor: pointer;
    svg {
      path {
        fill: #979797;
      }
    }
  `}
`

export const SChange = styled.label`
  ${({ theme }) => css`
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 1;
    display: block;
    transition: 0.2s;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: ${theme.button.primary.bgColor};
    cursor: pointer;
  `}
`
export const SLabel = styled.label`
  position: absolute;
  top: 7px;
  z-index: 0;
  display: block;
  transition: 0.2s;
  font-size: 12px;
  color: grey;
  padding-left: 5px;
`

export const SInput = styled.input<{
  hasLabel: boolean
}>`
  position: relative;
  width: 100%;
  border: 0;
  outline: 0;
  color: black;
  font-size: 14px;
  transition: 0.2s;
  box-sizing: border-box;
  height: 100%;

  &::placeholder {
    color: grey;
  }

  ${(props) =>
    props.hasLabel &&
    `
      &:focus,
      &:not(:placeholder-shown) {
        padding: 15px 0 0 5px;
      }

      &:placeholder-shown ~ ${SLabel} {
        top: 20px;
        color: transparent;
      }

      &:focus ~ ${SLabel} {
        position: absolute;
        top: 7px;
        color: grey;
      }
    `}
`

export const SInputMask = styled(InputMask)<{ hasLabel: boolean }>`
  position: relative;
  width: 99%;
  height: 99%;
  border: 0;
  outline: 0;
  color: black;
  font-size: 14px;
  transition: 0.2s;
  box-sizing: border-box;
  padding: 10px 5px;

  &::placeholder {
    color: grey;
  }

  ${(props) =>
    props.hasLabel &&
    `
      &:focus,
      &:not(:placeholder-shown) {
        margin: 20px 0 0 0;
        height: 14px;
      }

      &:placeholder-shown ~ ${SLabel} {
        top: 20px;
        display: none;
      }

      &:focus ~ ${SLabel} {
        position: absolute;
        transition: 0.2s;
        display: block;
        top: 7px;
        padding: 10px 0 0 3px;
        color: grey;
      }
    `}
`
