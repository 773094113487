import { useContextSelector } from 'use-context-selector'
import { GlobalContext } from '@shared/context/Global'
import CheckoutUtils from '@shared/utils/cart'
import services from '@Services/index'
import { useModal } from '../useModal'
import schemas from '@Schemas/index'
import { useLoading } from '../useLoading'
import { useCart } from '../useCart'
import { useWebView } from '../useWebView'
import { clarity } from 'react-microsoft-clarity'
import { IDiscount } from '@shared/context/interface'
import { useLogin } from '../useLogin'
import { useCreateOrder } from '../useCreateOrder'

export function useApplePay() {
  const cart = useContextSelector(GlobalContext, (state) => state?.cart)
  const sessionData = useContextSelector(
    GlobalContext,
    (state) => state?.sessionData
  )
  const { createOrder } = useCreateOrder()
  const {
    isIOS,
    onRequestAuthorizationToApplePay,
    checkoutApplePayWasError,
    isMobileApp
  } = useWebView()
  const { onSetIsOpenLogin } = useLogin()
  const { onSetIsOpenModal } = useModal()
  const { onSetIsLoading, onSetIsLoadingButton } = useLoading()
  const { restartSession } = useCart()
  const {
    getTotalService,
    getTotalSelectedBomboniere,
    getCurrentQuantity,
    getTotalSelectedTickets,
    getTotalPriceFromCart,
    getOnlyGiftCardOrPromocodes
  } = CheckoutUtils()

  const applePayService = {
    session: null,
    createApplePaySession: function (paymentRequest) {
      applePayService.session = new ApplePaySession(5, paymentRequest)

      applePayService.session.onvalidatemerchant =
        applePayService.onValidateMerchant

      applePayService.session.onpaymentauthorized = function (event) {
        applePayService.sendPaymentWithApplePay(
          event.payment.token.paymentData,
          event.payment.token.paymentMethod.type
        )
      }
      applePayService.session.begin()
    },
    onValidateMerchant: async function (event) {
      const { data, error } = await services.payments.validateApplePay(
        cart.id,
        event.validationURL
      )
      if (data) {
        applePayService.session.completeMerchantValidation(data)
      } else {
        checkoutApplePayWasError(error)
        applePayService.session.abort()
        if (error.status === 404) {
          return onSetIsOpenModal({
            ...schemas.modal.PURCHASE_TIME_EXPIRED,
            onConfirm: () => {
              restartSession()
              onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
            },
            onClose: () => {
              onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
            }
          })
        } else {
          return onSetIsOpenModal({
            ...schemas.modal.APPLEPAY_ERROR,
            onConfirm: () => {
              onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
            },
            onClose: () => {
              onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
            }
          })
        }
      }
    },
    sendPaymentWithApplePay: async function (
      paymentData: {
        data: string
        header: {
          publicKeyHash: string
          ephemeralPublicKey: string
          transactionId: string
        }
        signature: string
        version: string
      },
      typeCard: string
    ) {
      const cardMethod =
        typeCard !== undefined
          ? typeCard.charAt(0).toUpperCase() + typeCard.substr(1).toLowerCase()
          : 'Credit'

      try {
        clarity.setTag('paymentType', 'ApplePay')
      } catch (error) {
        console.log(error)
      }
      const errorMessage =
        'Não foi possível completar o pagamento. Por favor, tente novamente.'
      const [tokenApplePay] = await services.payments.paymentsTokens({
        token: JSON.stringify(paymentData),
        type: 'ApplePay'
      })
      if (tokenApplePay?.id) {
        const allPayments: IDiscount[] = [
          {
            type: 'ApplePay',
            value: getTotalPriceFromCart(),
            PaymentId: tokenApplePay?.id,
            cardMethod
          }
        ]
        console.info(allPayments, 'all payments enviados no add payments')
        if (getOnlyGiftCardOrPromocodes().length > 0) {
          allPayments.push(...getOnlyGiftCardOrPromocodes())
        }
        const [, error] = await services.payments.addPayments(
          cart.id,
          allPayments
        )
        if (error) {
          applePayService.session.abort()
          checkoutApplePayWasError(error)
          onSetIsLoading(false)
          onSetIsLoadingButton(false)
          if (
            error?.modelState?.cartError &&
            error?.modelState?.cartError[0].includes(
              'O tempo para finalizar a compra se excedeu'
            )
          ) {
            return onSetIsOpenModal({
              ...schemas.modal.PURCHASE_TIME_EXPIRED,
              onConfirm: () => {
                restartSession()
                onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
              },
              onClose: () => {
                onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
              }
            })
          } else if (error.status === 401) {
            return onSetIsOpenModal({
              ...schemas.modal.TOKEN_TIME_EXPIRED,
              onConfirm: () => {
                onSetIsOpenLogin({ show: true })
                onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
              },
              onClose: () => {
                onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
              }
            })
          } else {
            return onSetIsOpenModal({
              ...schemas.modal.APPLEPAY_ERROR_CONFIRM_PAYMENT,
              description: errorMessage,
              onConfirm: () => {
                onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
              },
              onClose: () => {
                onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
              }
            })
          }
        } else {
          onSetIsLoading(true)
          onSetIsLoadingButton(true)
          if (!isMobileApp()) {
            applePayService.session.completePayment(
              ApplePaySession.STATUS_SUCCESS
            )
          }
          await createOrder(true)
          return
        }
      } else {
        onSetIsLoading(false)
        onSetIsLoadingButton(false)
        applePayService.session.abort()
        return onSetIsOpenModal({
          ...schemas.modal.APPLEPAY_ERROR_CONFIRM_PAYMENT,
          description: errorMessage,
          onConfirm: () => {
            onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
          },
          onClose: () => {
            onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
          }
        })
      }
    }
  }
  // essa funcão é usada dentro do webview para interagir com o apple pay dentro checkout
  window.doPaymentOnApplePay = async (
    paymentData: {
      data: string
      header: {
        publicKeyHash: string
        ephemeralPublicKey: string
        transactionId: string
      }
      signature: string
      version: string
    },
    typeCard: string
  ) => {
    return await applePayService.sendPaymentWithApplePay(paymentData, typeCard)
  }
  const initPaymentWithApplePay = () => {
    const orderRequest = {
      merchantId: process.env.APPLE_PAY_MERCHANT_ID,
      countryCode: 'BR',
      currencyCode: 'BRL',
      supportedNetworks: [
        'amex',
        'discover',
        'masterCard',
        'visa',
        'maestro',
        'electron',
        'elo'
      ],
      requiredShippingContactFields: [],
      merchantCapabilities: ['supports3DS', 'supportsCredit', 'supportsDebit'],
      orderItems: [],
      payTo: 'Ingresso.com',
      totalOrder: getTotalPriceFromCart(),
      total: {
        label: 'Ingresso.com',
        amount: getTotalPriceFromCart()
      }
    }

    orderRequest.orderItems.push({
      description:
        'INGRESSOS ' +
        sessionData.session.event.name +
        ' (' +
        getCurrentQuantity() +
        ')',
      price: getTotalSelectedTickets()
    })

    if (getTotalSelectedBomboniere() > 0) {
      orderRequest.orderItems.push({
        description: 'BOMBONIERE',
        price: getTotalSelectedBomboniere()
      })
    }
    orderRequest.orderItems.push({
      description: 'TAXA DE SERVIÇO',
      price: getTotalService()
    })
    if (isIOS()) {
      onRequestAuthorizationToApplePay(orderRequest)
    } else {
      applePayService.createApplePaySession(orderRequest)
    }
  }
  return {
    initPaymentWithApplePay
  }
}
