import { useSessionData } from './useSessionData'
import { useContextSelector } from 'use-context-selector'
import { GlobalContext } from '@shared/context/Global'

export function useSessionTimer() {
  const timer = useContextSelector(GlobalContext, (state) => state?.timer)
  const onSetTimer = useContextSelector(
    GlobalContext,
    (state) => state?.onSetTimer
  )

  const { sessionData } = useSessionData()
  let reservationThresholdWarning = timer?.reservationThresholdWarning

  const reservationThresholdDuration =
    sessionData?.session?.reservationMinutesLeft * 60 * 1000
  const reservationMessageDuration = 1 * 60 * 1000

  const showReservationWarning = () => {
    const element = document.querySelector('#session-warning')
    element.classList.add('show-warning-footer')
  }

  const hideReservationWarning = () => {
    const element = document.querySelector('#session-warning')
    element?.classList.remove('show-warning-footer')
  }
  const initTimer = () => {
    reservationThresholdWarning = setTimeout(() => {
      showReservationWarning()
      setTimeout(() => {
        hideReservationWarning()
      }, reservationMessageDuration)
    }, reservationThresholdDuration)
    onSetTimer({
      reservationThresholdWarning
    })
  }

  const clearTimer = () => {
    clearTimeout(timer.reservationThresholdWarning)
  }

  const sessionTimer = () => {
    if (
      sessionData?.functionalities?.seatReservationLimit &&
      sessionData?.session?.reservationMinutesLeft
    ) {
      clearTimer()
      initTimer()
    }
  }

  return {
    sessionTimer,
    onSetTimer,
    clearTimer,
    hideReservationWarning
  }
}
