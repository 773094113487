import { authAPI } from 'src/shared/request/Api'
import endpoints from 'src/shared/endpoints'

export const getToken = async (value: {}) => {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded'
  }

  try {
    const { url, fields } = endpoints.tokenEndpoints.TOKEN_SESSIONS.post(value)
    const { data, status } = await authAPI.post(url, fields, {
      headers: headers
    })
    return {
      data,
      status
    }
  } catch (error) {
    return {
      errorType: error.response.data.error,
      errorMessage: error.response.data.error_description,
      status: error.response.status
    }
  }
}
