import React from 'react'
import * as S from './styled'
import Icon from '@Components/Icon'
import ParentalRating from '@Components/ParentalRating'
import { useModal } from '@shared/hooks/useModal'
import schemas from '@Schemas/index'
import { useSessionData } from '@shared/hooks/useSessionData'
import { getRatingMessage } from '@Helpers/rating'
import useMediaQuery from '@shared/hooks/useMediaQuery'

export default function SessionInfo() {
  const { onSetIsOpenModal } = useModal()
  const { sessionData } = useSessionData()
  const isMobile = useMediaQuery('(max-width: 991px)')
  const seeMore = () => {
    onSetIsOpenModal({
      ...schemas.modal.SESSION_INFO(
        sessionData?.titleMovie,
        duration,
        sessionData?.session?.event?.ratingDescriptor,
        sessionData?.session?.event?.genre,
        sessionInfo(),
        parental(),
        getRatingMessage(sessionData?.session?.event?.rating)
      ),
      onClose: () => {
        onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
      }
    })
  }
  const sessionInfo = () => {
    const tags: string[] = sessionData.typeDescription
    if (tags) {
      return (
        <S.MovieSessionContainer>
          {tags.map((type, index) => (
            <S.SessionTags key={index}>{type}</S.SessionTags>
          ))}
          {sessionData?.session?.subtitles?.name && (
            <S.SessionTags>
              {sessionData?.session?.subtitles?.name}
            </S.SessionTags>
          )}
        </S.MovieSessionContainer>
      )
    }
  }
  const parental = () => {
    return (
      <ParentalRating
        rating={sessionData?.session?.event?.rating}
        size="16px"
      />
    )
  }

  const iconSize = isMobile ? 12 : 10

  const duration = sessionData?.session?.event?.duration
    ? `${Math.floor(Number(sessionData.session.event.duration) / 60)}H${
        Number(sessionData.session.event.duration) % 60
      }`
    : ''

  return (
    <S.Details onClick={() => seeMore()}>
      <S.Icon>
        <Icon
          className="titleIcon"
          src={`icons/info`}
          size={iconSize}
          color="#98AAEC"
        />
      </S.Icon>
      {!isMobile && 'Ver detalhes'}
    </S.Details>
  )
}
