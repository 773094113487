import styled, { css } from 'styled-components'

export const ContainerButton = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #ccc;
`
export const ContainerInput = styled.div`
  margin-bottom: 8px;
`
export const ValidationInput = styled.ul`
  margin-top: 16px;
  list-style: none;

  li {
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    color: #ccc;
    text-align: left;
    display: flex;
    align-items: baseline;
    gap: 10px;
  }
`
export const passwordValidations = styled.div`
  text-align: left;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #ccc;
  margin-top: 16px;
`
export const HelpContainer = styled.p`
  span {
    color: ${(props) => props.theme?.global.four.color};
  }
  margin-bottom: 8px;
  width: 100%;
  font-size: 14px;
  text-align: start;
`

export const Newsletter = styled.div`
  display: flex;

  input {
    width: 20px;
    height: 20px;

    :checked {
      accent-color: #fff;
    }
  }

  span {
    margin-left: 5px;
    text-align: start;
    width: 89%;
    color: ${(props) => props.theme?.global.four.color};
  }
  margin-bottom: 8px;
`

export const VerifyCodeContainer = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 1rem;
    align-items: center;
  `}
`
export const ContentVerifyCode = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 1rem;
  `}
`
export const WarningContainerMain = styled.div`
  display: flex;
  justify-content: center;
  font-size: 14px;
  align-items: center;
  margin: 5px 0px;
  color: #ff5a50;
  svg {
    display: flex;
  }
`
export const ErrorMessage = styled.span`
  text-align: left;
  margin-left: 8px;
  color: #ff5a50;
`
export const SendCode = styled.span`
  ${({ theme }) => css`
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${theme.button.primary.bgColor};
    cursor: pointer;
    margin-top: 24px;
  `}
`

export const Go = styled.span`
  ${({ theme }) => css`
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${theme.button.primary.bgColor};
    cursor: pointer;
    margin-top: 24px;
    width: 100%;
    text-align: right;
  `}
`
export const NewsLetterPoweredBy = styled.div`
  display: flex;
  flex-direction: row;

  gap: 6px;
  align-items: start;
  input {
    width: 20px;
    height: 20px;
    margin-top: 2.5px;
    :checked {
      accent-color: #fff;
    }
  }

  p {
    text-align: start;
    width: 89%;
    color: ${(props) => props.theme?.global.four.color};
  }

  @media (max-width: 992px) {
    p {
      text-align: start;
      width: 90%;
    }
  }
  margin-bottom: 8px;
`
