import styled from 'styled-components'

export const SSubtitleBold = styled.h3<{ color: string }>`
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5rem;
  letter-spacing: 0.15px;
  color: ${(props) =>
    !props.color ? props.theme.ticketBox.nameColor : props.color};
  &.title-giftCard {
    color: ${(props) =>
      props.theme.data?.isPoweredBy
        ? props.theme.global.secondary.color
        : props.theme.global.link.color};
  }
`
