import { ticketingAPI } from '@shared/request/Api'
import endpoints from '@shared/endpoints'
import { handleError } from '@shared/request/error'
import { ISeatInfo } from '../../components/Seat/interface'
import { getAuthTypeHeader } from '../utils/helpers/headers'

export const get = async (sessionId: string, sectionId: string) => {
  try {
    const { data } = await ticketingAPI.get(
      endpoints.sessionsEndpoint.SESSIONS_SECTIONS_SEATS.get(
        sessionId,
        sectionId
      )
    )
    return [data, null]
  } catch (error) {
    return [null, handleError(error)]
  }
}

interface IProps {
  cartId: string
  sessionId: string
  sectionId: string
}
interface IPayload {
  seats: ISeatInfo[]
  captchaResponse?: string
}

export const create = async (
  { cartId, sessionId, sectionId }: IProps,
  seats: {
    seats: ISeatInfo[]
  },
  captchaResponse?: string
) => {
  try {
    const payload: IPayload = {
      seats: seats.seats
    }
    if (captchaResponse) {
      payload.captchaResponse = captchaResponse
    }

    const { data } = await ticketingAPI.post(
      endpoints.sessionsEndpoint.CARTS_SESSIONS_SECTIONS_SEATS.get(
        cartId,
        sessionId,
        sectionId
      ),
      payload,
      {
        headers: getAuthTypeHeader()
      }
    )
    return [data, null]
  } catch (error) {
    return [null, handleError(error)]
  }
}
