import styled from 'styled-components'

export const BenefitsWrp = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 40px;
  margin-bottom: 40px;
  text-align: center;
  .benefits-title {
    font-size: 12px;
    margin-bottom: 10px;
    font-weight: normal;
  }
  .benefits-icon {
    margin-bottom: 10px;
  }
  .benefits-text {
    font-size: 10px;
    font-weight: bold;
    line-height: 11px;
  }
`

export const BenefitsList = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

export const BenefitsItem = styled.li`
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  align-items: center;
  justify-content: end;
`
