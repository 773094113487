import Icon from '@Components/Icon'
import {
  PAGE_NAME_BOMBONIERE,
  PAGE_NAME_PAYMENTS,
  PAGE_NAME_SEATS,
  PAGE_NAME_TICKETS
} from '@Constants/page'
import { useCart } from '@shared/hooks/useCart'
import { usePage } from '@shared/hooks/usePage'
import React, { Fragment, useState } from 'react'
import * as S from './styled'
import Tab from './Tab'
import { useNavigation } from '@shared/hooks/useNavigation'
import { useSessionData } from '@shared/hooks/useSessionData'
import { useLoading } from '@shared/hooks/useLoading'
import Modal from '@Components/Modal'
import { SESSION_REMOVE_SESSION } from '@Schemas/modal'
import services from '@Services/index'
import { useRouter } from 'next/router'
import { useTheme } from 'styled-components'
import { useWebView } from '@shared/hooks/useWebView'
import CheckoutUtils from '@shared/utils/cart'

const descPage = {
  [PAGE_NAME_SEATS]: 'Selecione seus assentos',
  [PAGE_NAME_TICKETS]: 'Escolha os tipos de ingresso',
  [PAGE_NAME_BOMBONIERE]: 'Bomboniere',
  [PAGE_NAME_PAYMENTS]: 'Selecione a forma de pagamento'
}
const ProgressTab = ({ isMobileApp }: { isMobileApp: boolean }) => {
  const { page } = usePage()
  const { cart, goSeats, goTickets, goBomboniere } = useCart()
  const { isVisiblePrevButton } = CheckoutUtils()
  const { steps } = useNavigation()
  const { sessionData } = useSessionData()
  const { loadingButton } = useLoading()
  const [modalCloseIcon, setIsClose] = useState(false)
  const router = useRouter()
  const theme = useTheme()
  const { closeCheckout } = useWebView()

  const handlePrevPage = () => {
    if (page === PAGE_NAME_TICKETS) {
      return goSeats(cart.sessionId)
    } else if (
      page === PAGE_NAME_PAYMENTS &&
      !sessionData.hasBomboniereActive
    ) {
      return goTickets(page, cart?.sessionId)
    } else if (page === PAGE_NAME_PAYMENTS && sessionData.hasBomboniereActive) {
      return goBomboniere(cart?.sessionId)
    } else if (page === PAGE_NAME_BOMBONIERE) {
      return goTickets(page, cart?.sessionId)
    } else {
      return goTickets(page, cart?.sessionId)
    }
  }
  const renderTabs = () => {
    const allTabs = steps

    const totalTabs = Object.getOwnPropertyNames(allTabs).filter((name) => {
      const item = allTabs[name]
      return item.shouldShowTab
    })

    const tabs = totalTabs.map((name, index) => {
      const item = allTabs[name]
      const activeTab = allTabs[page]?.index || 0
      const isActive = activeTab === item.index
      const isActiveLine = activeTab === item.index || item.index < activeTab
      const isDisabled = !isActive && !item.enabledTab
      const handleClick = () => !isActive && item.onClick()

      return (
        item.shouldShowTab && (
          <Fragment key={name}>
            {index > 0 && (
              <S.ItemLine
                isActive={isActiveLine}
                countTabs={totalTabs.length - 1}
              />
            )}
            <S.IconContainer currentPage={descPage[page]} isActive={isActive}>
              <Tab
                isActive={isActive}
                getKey={item.index}
                key={item.index}
                icon={item.icon}
                label={item.label}
                onClick={handleClick}
                disabled={isDisabled || loadingButton}
              />
            </S.IconContainer>
          </Fragment>
        )
      )
    })
    return tabs
  }

  const handleOnConfirm = async () => {
    setIsClose(false)
    const [,] = await services.carts.deleteSession(cart?.id, cart?.sessionId)
    if (isMobileApp) closeCheckout()
    else
      router.push(
        theme.data?.isPoweredBy ? theme.data?.url : process.env.baseUrlCatalog
      )
  }

  return (
    <S.ProgressTabContainer isMobileApp>
      <S.TextContainer>
        <S.TitleArrow onClick={handlePrevPage} disabled={isVisiblePrevButton()}>
          <Icon src="icons/arrow" size={24} />
        </S.TitleArrow>
        <S.TitleTab>{descPage[page]}</S.TitleTab>
        {isMobileApp && (
          <S.Close onClick={() => setIsClose(true)}>
            <Icon
              aria-label="fechar"
              className="iconHeader"
              src="icons/close"
              height={24}
              width={24}
            />
          </S.Close>
        )}
      </S.TextContainer>
      <S.ListTab>{renderTabs().map((tab) => tab)}</S.ListTab>
      <Modal
        isOpen={modalCloseIcon}
        title={SESSION_REMOVE_SESSION?.title}
        description={SESSION_REMOVE_SESSION?.description}
        iconModal={SESSION_REMOVE_SESSION?.iconModal}
        confirmButton={SESSION_REMOVE_SESSION?.confirmButton}
        closeButton={SESSION_REMOVE_SESSION?.closeButton}
        onClose={() => setIsClose(false)}
        onConfirm={() => handleOnConfirm()}
      />
    </S.ProgressTabContainer>
  )
}
export default ProgressTab
