import { useSessionData } from '@shared/hooks/useSessionData'
import { ISeatInfo } from '@Components/Seat/interface'
import { useDiscounts } from '@shared/hooks/useDiscounts'
import { useRouter } from 'next/router'
import { getTotalQuantityTickets } from '@Helpers/others'
import { useSeats } from '@shared/hooks/useSeats'
import { useTickets } from '@shared/hooks/useTickets'
import { IDiscount } from '@shared/context/interface'
import { useFidelity } from '@shared/hooks/useFidelity'
import { useBomboniere } from '@shared/hooks/useBomboniere'
import { useCookies } from '@shared/hooks/useCookies'
import { useAuthToken } from '@shared/hooks/useAuthToken'
import { filterPropertiesUrl } from '@Helpers/others'
import { usePage } from '@shared/hooks/usePage'
import { PAGE_NAME_SEATS, PAGE_NAME_WAIT_PAYMENT } from '@Constants/page'

const CheckoutUtils = () => {
  const { sessionData } = useSessionData()
  const { discounts } = useDiscounts()
  const router = useRouter()
  const { seats } = useSeats()
  const { tickets } = useTickets()
  const { fidelityFields, fidelity } = useFidelity()
  const { bomboniere } = useBomboniere()
  const { getCookie } = useCookies()
  const { authToken } = useAuthToken()
  const getPath = (pathName) =>
    router.asPath.split('/').find((path) => path === pathName)
  const { page } = usePage()
  const getCurrentQuantity = () => {
    if (sessionData?.hasSeatSelection) {
      return seats?.selected?.length
    } else {
      if (tickets?.selected?.length > 0) {
        return getTotalQuantityTickets(tickets?.selected)
      }
      return 0
    }
  }
  const VerifyAllFieldsTicketValid = () => {
    let allfieldsValid = true
    for (let i = 0; i < tickets?.selected?.length; i++) {
      const fields = tickets?.selected[i].fields
      for (let j = 0; j < fields.length; j++) {
        if (fields[j].activeError) {
          allfieldsValid = false
        }
      }
    }
    if (fidelity) {
      for (let i = 0; i < fidelityFields?.length; i++) {
        const fields = fidelityFields[i].fields
        for (let j = 0; j < fields.length; j++) {
          if (fields[j].activeError) {
            allfieldsValid = false
          }
        }
      }
    }
    return allfieldsValid
  }
  const getDifferenceSeats = (array1, array2) => {
    if (Array.isArray(array1) && Array.isArray(array2))
      return array1?.filter((object1) => {
        return !array2?.some((object2) => {
          return object1.id === object2.id
        })
      })
  }
  const getSelectedSeats = () => {
    if (
      sessionData?.hasSeatSelection &&
      sessionData?.selectedSeats?.length > 0
    ) {
      return sessionData?.selectedSeats?.map((item: ISeatInfo) => ({
        id: item?.id,
        label: item?.label,
        typeDescription: item?.typeDescription,
        iconUrl: item?.iconUrl,
        specialSeat: item?.typeSeparation === 'Especial',
        typeSeparation: item?.typeSeparation,
        type: item?.type,
        areaNumber: item?.areaNumber,
        areaCategoryCode: item?.areaCategoryCode,
        physicalName: item?.physicalName,
        rowIndex: item?.rowIndex,
        columnIndex: item?.columnIndex,
        sectorId: item?.sectorId,
        sectorDescription: item?.sectorDescription,
        sectorTypeId: item?.sectorTypeId,
        sectorTypeDescription: item?.sectorTypeDescription,
        sectionId: item?.sectionId
      }))
    } else {
      return []
    }
  }

  const getSelectedTickets = () => {
    return sessionData?.selectedTickets
  }

  const getTotalSelectedTickets = () => {
    let total = 0
    sessionData?.selectedTickets?.forEach((item) => {
      const quantidade = item.quantity
      const precoUnitario = item.unitPrice
      const subtotal = quantidade * precoUnitario
      total += subtotal
    })

    return total
  }
  const getTotalSelectedBomboniereWithTax = () => {
    const totalProducts = bomboniere?.selected?.reduce((acc, category) => {
      const total = category?.products.reduce((acc, product) => {
        return acc + (product.price + product.service) * product.quantity
      }, 0)
      return acc + total
    }, 0)

    return totalProducts
  }
  const getTotalSelectedBomboniere = () => {
    const totalProducts = sessionData?.selectedBomboniere?.reduce(
      (acc, category) => {
        const total = category.products.reduce((acc, product) => {
          return acc + (product.price + product.service) * product.quantity
        }, 0)
        return acc + total
      },
      0
    )
    return totalProducts ? totalProducts : 0
  }

  const getTotalPriceFromCart = (): number => {
    let total = 0

    total = getTotalSelectedTickets() + getTotalSelectedBomboniere()
    discounts?.forEach(function (discount) {
      if (discount?.value) {
        total = total - discount.value
      }
    })
    if (total < 0) total = 0

    return Number(total.toFixed(2))
  }

  const getTotalService = () => {
    let total = 0
    let totalBomboniere = 0

    bomboniere?.selected?.forEach((category) => {
      category.products.forEach((item) => {
        const totalService = item.quantity * item.service
        totalBomboniere += totalService
      })
    })

    sessionData?.selectedTickets?.forEach((item) => {
      const totalService = item.quantity * item.service
      total += totalService
    })

    return total + totalBomboniere
  }
  const getShowSeatsOrTickets = () => {
    return (
      (getPath('assentos') &&
        sessionData?.hasSeatSelection &&
        seats?.selected?.length > 0) ||
      (sessionData?.hasSeatSelection &&
        getPath('ingressos') &&
        sessionData?.selectedSeats?.length > 0) ||
      (!sessionData?.hasSeatSelection &&
        getPath('ingressos') &&
        sessionData?.selectedTickets?.length > 0)
    )
  }

  const getTotalTax = () => {
    let total = 0

    sessionData?.selectedTickets?.forEach((item) => {
      const totalTax = item.quantity * item.tax
      total += totalTax
    })
    return total
  }
  const verifyShowCheckBoxPoweredBy = () => {
    const newsLetterPoweredbyCookie = getCookie(
      process.env.cookieNewsLetterPoweredBy
    )

    if (
      (sessionData?.isPoweredBy &&
        newsLetterPoweredbyCookie === 'false' &&
        getPartnerDataShare() &&
        !authToken?.EmailOptIn) ||
      (sessionData?.isPoweredBy &&
        typeof newsLetterPoweredbyCookie === 'undefined' &&
        getPartnerDataShare() &&
        !authToken?.EmailOptIn)
    ) {
      return true
    }

    return false
  }
  const getPartnerDataShare = () => {
    const isClient = typeof window !== 'undefined'
    if (isClient) {
      const partnerDataShare = sessionStorage.getItem('partnerdatashare')
      return typeof partnerDataShare === 'string' && partnerDataShare === 'true'
    }
    return false
  }

  const getPartnerShipName = () => {
    return router.query?.partnership
  }
  const isPoweredBy = () => sessionData?.isPoweredBy
  const isPoweredByAndShareData = () =>
    sessionData?.isPoweredBy && getPartnerDataShare()

  const getTotalGiftCardsAndPromocodes = () => {
    let total = 0

    discounts?.forEach(function (discount) {
      if (discount?.value) {
        total = total + discount.value
      }
    })
    if (total < 0) total = 0

    return Number(total.toFixed(2))
  }
  const getTotalPriceFromCartBeforeDiscount = () => {
    let total = 0

    total = getTotalSelectedTickets() + getTotalSelectedBomboniere()
    if (total < 0) total = 0

    return Number(total.toFixed(2))
  }
  const getOnlyGiftCardOrPromocodes = () => {
    const onlyGiftCardsOrPromocodes: IDiscount[] = discounts.filter(
      (discount) => discount.type !== 'ClubUol'
    )
    return onlyGiftCardsOrPromocodes || []
  }

  const getUrlParams = () => {
    const { query } = router
    const queryParams = filterPropertiesUrl({
      sessionId: query?.sessionId,
      partnership: query?.partnership ? query.partnership : 'home',
      ing_source: query?.ing_source,
      ing_medium: query?.ing_medium,
      ing_campaign: query?.ing_campaign,
      ing_content: query?.ing_content,
      _gl: query?._gl,
      app: query?.app,
      gtm_debug: query?.gtm_debug,
      queueittoken: query?.queueittoken
    })
    return queryParams
  }
  const isVisiblePrevButton = () => {
    if (sessionData?.hasSeatSelection && page !== PAGE_NAME_SEATS) {
      return false
    } else if (!sessionData?.hasSeatSelection && page === `ingressos`) {
      return true
    } else if (sessionData?.hasSeatSelection && page === PAGE_NAME_SEATS) {
      return true
    } else if (!sessionData?.hasSeatSelection && page !== `ingressos`) {
      return false
    } else if (page === PAGE_NAME_WAIT_PAYMENT) {
      return false
    }
  }
  return {
    getSelectedSeats,
    getSelectedTickets,
    getTotalPriceFromCart,
    getTotalSelectedTickets,
    getDifferenceSeats,
    getTotalService,
    getTotalTax,
    getTotalSelectedBomboniereWithTax,
    getTotalSelectedBomboniere,
    getPath,
    getCurrentQuantity,
    getShowSeatsOrTickets,
    getTotalGiftCardsAndPromocodes,
    getTotalPriceFromCartBeforeDiscount,
    getOnlyGiftCardOrPromocodes,
    VerifyAllFieldsTicketValid,
    getPartnerShipName,
    verifyShowCheckBoxPoweredBy,
    isPoweredBy,
    isPoweredByAndShareData,
    getPartnerDataShare,
    getUrlParams,
    isVisiblePrevButton
  }
}

export default CheckoutUtils
