import { useContextSelector } from 'use-context-selector'
import { GlobalContext } from '@shared/context/Global'
import { useContext } from 'react'
import { LoginContext } from '@shared/context/Login/LoginContext'

export function useLogin() {
  const isOpenCollapseUser = useContextSelector(
    GlobalContext,
    (state) => state?.isOpenCollapseUser
  )
  const onSetIsOpenCollapseUser = useContextSelector(
    GlobalContext,
    (state) => state?.onSetIsOpenCollapseUser
  )
  const {
    loginSteps,
    onSetLoginSteps,
    loginCurrentStep,
    loginStepPage,
    onSetCurrentLoginStepNumberAndPage,
    setEmailOrCpfValueContext,
    emailOrCpfValueContext,
    onSetIsOpenLogin,
    openLogin,
    onSetIsFromLoginAccount,
    isFromLoginAccount,
    setDataCreateAccountStepOne,
    dataFromCreateAccountStepOne,
    policiesFromContext,
    setPoliciesContext,
    openVerifyCode,
    setOpenVerifyCode,
    setVerifyCodeValue,
    verifyCodeValue,
    errorDescriptionToken,
    setErrorDescriptionToken,
    hasAccount,
    setHasAccount
  } = useContext(LoginContext)
  return {
    onSetIsOpenLogin,
    openLogin,
    isOpenCollapseUser,
    onSetIsOpenCollapseUser,
    loginSteps,
    onSetLoginSteps,
    loginCurrentStep,
    loginStepPage,
    onSetCurrentLoginStepNumberAndPage,
    setEmailOrCpfValueContext,
    emailOrCpfValueContext,
    isFromLoginAccount,
    onSetIsFromLoginAccount,
    setDataCreateAccountStepOne,
    dataFromCreateAccountStepOne,
    policiesFromContext,
    setPoliciesContext,
    openVerifyCode,
    setOpenVerifyCode,
    setVerifyCodeValue,
    verifyCodeValue,
    errorDescriptionToken,
    setErrorDescriptionToken,
    hasAccount,
    setHasAccount
  }
}
