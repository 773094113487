import { getParental, getRatingClass } from '../../shared/utils/helpers/rating'
import colors from '@Styles/theme/colors'
import * as S from './styled'

const ParentalRating = ({
  rating,
  size
}: {
  rating?: string
  size?: string
}) => {
  const fontSize = Number(size) - 2
  return (
    <S.Parental
      color={getRatingClass(colors.ageRating, rating)}
      className="parental-container"
      height={size}
      fontSize={`${fontSize}px`}
    >
      <S.Span className="parental-rating"> {getParental(rating)}</S.Span>
    </S.Parental>
  )
}

export default ParentalRating
