import { useContextSelector } from 'use-context-selector'
import { GlobalContext } from '../context/Global'

export function useModal() {
  const modal = useContextSelector(GlobalContext, (state) => state?.modal)
  const onSetIsOpenModal = useContextSelector(
    GlobalContext,
    (state) => state?.onSetModal
  )
  return {
    modal,
    onSetIsOpenModal
  }
}
