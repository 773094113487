import { useContextSelector } from 'use-context-selector'
import { GlobalContext } from '@shared/context/Global'

export function useIsChangeSeatMap() {
  const changeSeatMap = useContextSelector(
    GlobalContext,
    (state) => state?.changeSeatMap
  )
  const onSetIsChangeSeatMap = useContextSelector(
    GlobalContext,
    (state) => state?.onSetIsChangeSeatMap
  )

  return {
    changeSeatMap,
    onSetIsChangeSeatMap
  }
}
