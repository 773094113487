import React from 'react'
import { ITab } from './interface'
import Icon from '@Components/Icon'
import * as S from './styled'

const Tab = (props: ITab) => {
  const renderContent = () => {
    const { icon } = props
    return <Icon src={icon} size={16} />
  }

  const getClassName = (isActive?: boolean): string => {
    return isActive ? 'active' : ''
  }
  const {
    disabled,
    bgDefault,
    bgActive,
    colorDefault,
    colorActive,
    getKey,
    onClick,
    isActive,
    testid,
    label
  } = props

  return (
    <S.Item
      key={getKey}
      className={getClassName(isActive)}
      data-testid={testid}
      aria-label={label}
      onClick={onClick}
      disabled={disabled}
      bgDefault={bgDefault}
      bgActive={bgActive}
      colorDefault={colorDefault}
      colorActive={colorActive}
    >
      {renderContent()}
    </S.Item>
  )
}

export default Tab
