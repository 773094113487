import styled from 'styled-components'
import animations from '../../styles/animations'

import { TTypes } from './interface'

export const Header = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  width: 100%;
`

export const Title = styled.h3`
  font-size: 14px;
  line-height: 20px;
  padding: 0 8px 0;
  width: 100%;
  @media (max-width: 992px) {
    font-size: 12px;
  }
`

export const Body = styled.p`
  font-size: 14px;
  padding: 10px 16px 0 0;
  width: 100%;
  justify-content: start;
  font-weight: normal;
`
export const Content = styled.p`
  font-size: 14px;
  display: flex;
  align-items: center;
  align-content: start;
  margin-left: 7px;
  width: 100%;
  height: 100%;
  align-self: end;
  @media (max-width: 992px) {
    font-size: 12px;
  }
`

export const Container = styled.div<{
  type: TTypes
  isVisible: boolean
}>`
  position: absolute;
  bottom: 69px;
  padding: 13px 15px;
  display: flex;
  max-width: 664px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  z-index: 2;
  background-color: ${(props) => props.theme?.alert?.[props.type]?.bgColor};
  border-left: ${(props) => props.theme?.alert?.[props.type]?.borderLeft};

  ${(props) => (props.isVisible ? animations.fadeIn : animations.fadeOut)}
  ${(props) =>
    props.isVisible
      ? `animation-duration: 100ms`
      : `animation-duration: 500ms`};

  ${Title} {
    color: ${(props) => props.theme?.alert?.[props.type]?.titleColor};
  }
  ${Body} {
    color: ${(props) => props.theme?.alert?.[props.type]?.contentColor};
  }

  .titleIcon svg {
    fill: ${(props) => props.theme?.alert?.[props.type]?.iconColor} +
      '!important';
  }

  .closeIcon svg {
    fill: ${(props) => props.theme?.alert?.[props.type]?.iconCloseColor};
  }
  svg {
    fill: ${(props) => props.theme?.alert?.[props.type]?.iconColor};
  }
  @media (max-width: 992px) {
    bottom: 54px;
  }
`
