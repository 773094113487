import { useContextSelector } from 'use-context-selector'
import { GlobalContext } from '@shared/context/Global'

export function usePaymentMethods() {
  const debitCardMethodPaymentOpen = useContextSelector(
    GlobalContext,
    (state) => state?.debitCardMethodPaymentOpen
  )
  const creditCardMethodPaymentOpen = useContextSelector(
    GlobalContext,
    (state) => state?.creditCardMethodPaymentOpen
  )
  const paymentMethodCurrent = useContextSelector(
    GlobalContext,
    (state) => state?.paymentMethodCurrent
  )
  const onSetDebitCardMethodPaymentOpen = useContextSelector(
    GlobalContext,
    (state) => state?.onSetDebitCardMethodPaymentOpen
  )
  const onSetCreditCardMethodPaymentOpen = useContextSelector(
    GlobalContext,
    (state) => state?.onSetCreditCardMethodPaymentOpen
  )
  const onSetPaymentMethodCurrent = useContextSelector(
    GlobalContext,
    (state) => state?.onSetPaymentMethodCurrent
  )
  const onSetApplePayMethodPaymentOpen = useContextSelector(
    GlobalContext,
    (state) => state?.onSetApplePayMethodPaymentOpen
  )
  const applePayMethodPaymentOpen = useContextSelector(
    GlobalContext,
    (state) => state?.applePayMethodPaymentOpen
  )
  const googlePayMethodPaymentOpen = useContextSelector(
    GlobalContext,
    (state) => state?.googlePayMethodOpen
  )
  const onSetGooglePayMethodOpen = useContextSelector(
    GlobalContext,
    (state) => state?.onSetGooglePayMethodOpen
  )
  const pixPayMethodOpen = useContextSelector(
    GlobalContext,
    (state) => state?.pixPayMethodOpen
  )
  const onSetPixPayMethodOpen = useContextSelector(
    GlobalContext,
    (state) => state?.onSetPixPayMethodOpen
  )
  const nuPayMethodOpen = useContextSelector(
    GlobalContext,
    (state) => state?.nuPayMethodOpen
  )
  const onSetNuPayMethodOpen = useContextSelector(
    GlobalContext,
    (state) => state?.onSetNuPayMethodOpen
  )
  return {
    debitCardMethodPaymentOpen,
    creditCardMethodPaymentOpen,
    onSetCreditCardMethodPaymentOpen,
    onSetDebitCardMethodPaymentOpen,
    paymentMethodCurrent,
    onSetPaymentMethodCurrent,
    onSetApplePayMethodPaymentOpen,
    applePayMethodPaymentOpen,
    googlePayMethodPaymentOpen,
    onSetGooglePayMethodOpen,
    pixPayMethodOpen,
    onSetPixPayMethodOpen,
    nuPayMethodOpen,
    onSetNuPayMethodOpen
  }
}
