import axios, { AxiosError } from 'axios'
import { useCookies } from '@shared/hooks/useCookies'

const authAPI = axios.create({ baseURL: process.env.baseUrlApiAuth })
const ticketingAPI = axios.create({ baseURL: process.env.baseUrlApiTicketing })

const axiosInstances = [authAPI, ticketingAPI]

const DoRefreshToken = async () => {
  const { getCookie, removeCookie, setCookie } = useCookies()
  const refresh_token = getCookie(process.env.COOKIE_REFRESH_TOKEN_NAME)
  const token =
    getCookie(process.env.COOKIE_TOKEN_NAME) ||
    getCookie(process.env.COOKIE_TOKEN_NAME_APP)
  const keep_me_connected_token = getCookie(
    process.env.COOKIE_KEEP_ME_CONNECTED
  )
  if (token) {
    removeCookie(process.env.COOKIE_TOKEN_NAME)
    removeCookie(process.env.COOKIE_TOKEN_NAME_APP)
  }

  const params = new URLSearchParams({
    refresh_token: refresh_token.replace(/['"]+/g, ''),
    grant_type: 'refresh_token',
    keep_me_connected_token,
    client_origin: 'Site'
  }).toString()
  const tokenAuthResponse = await authAPI
    .post(`/token`, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
    .then((response) => {
      const { data } = response
      const expirationToken = new Date(data['.expires'])
      expirationToken.setFullYear(expirationToken.getFullYear() + 1)
      const userData = {
        id: data.user_id,
        access_token: data.access_token,
        refreshToken: data.refresh_token,
        user_first_name: data.user_first_name,
        user_id: data.user_id,
        email: data.user_email,
        loginType: data.login_type,
        type: 'bearer',
        expires: data['.expires']
      }

      setCookie(
        process.env.COOKIE_REFRESH_TOKEN_NAME,
        userData.refreshToken,
        expirationToken
      )
      /** ISI: Verificar funcionamento no webview  */

      setCookie(process.env.COOKIE_TOKEN_NAME, userData, expirationToken)
      setCookie(process.env.COOKIE_TOKEN_NAME_APP, userData, expirationToken)
      return userData
    })
    .catch((error) => {
      return Promise.reject(error)
    })
  return tokenAuthResponse
}
axiosInstances.forEach((instance) => {
  instance.interceptors.request.use((config) => {
    const { getCookie } = useCookies()
    const token =
      getCookie(process.env.COOKIE_TOKEN_NAME) ||
      getCookie(process.env.COOKIE_TOKEN_NAME_APP)
    if (
      config.url.includes('sessions') ||
      config.url.includes('newCheckout') ||
      config.url.includes('tokens') ||
      config.url.includes('apple-pay')
    ) {
      return config
    }
    if (token?.access_token) {
      if (token?.type === 'bearer') {
        config.headers['Authorization'] = `Bearer ${token.access_token}`
      } else if (token?.type === 'Guest') {
        config.headers['Guest-Authorization'] = `${token.access_token}`
      }
    }

    return config
  })

  instance.interceptors.response.use(
    (response) => response,
    async (error: AxiosError) => {
      const { getCookie, removeCookie } = useCookies()

      const refreshToken = getCookie(process.env.COOKIE_REFRESH_TOKEN_NAME)
      const keepMeConnectedTokenCookie = getCookie(
        process.env.COOKIE_KEEP_ME_CONNECTED
      )
      const tokenCookie =
        getCookie(process.env.COOKIE_TOKEN_NAME) ||
        getCookie(process.env.COOKIE_TOKEN_NAME_APP)

      const { response } = error
      if (
        keepMeConnectedTokenCookie === 'true' &&
        (response.status === 401 || response.status === 403) &&
        tokenCookie?.type === 'bearer'
      ) {
        if (refreshToken) {
          if (tokenCookie) {
            removeCookie(process.env.COOKIE_TOKEN_NAME)
            removeCookie(process.env.COOKIE_TOKEN_NAME_APP)
          }
        }
        try {
          const response = await DoRefreshToken()
          if (response.access_token) {
            error.config.headers[
              'Authorization'
            ] = `Bearer ${response.access_token}`

            return instance.request(error.config)
          }
        } catch (refreshError) {
          return Promise.reject(refreshError)
        }
      } else if (
        keepMeConnectedTokenCookie === 'false' &&
        (response.status === 401 ||
          (response.status === 403 && tokenCookie?.type === 'bearer'))
      ) {
        removeCookie(process.env.COOKIE_TOKEN_NAME)
        removeCookie(process.env.COOKIE_TOKEN_NAME_APP)
      }
      return Promise.reject(error)
    }
  )
})

export { authAPI, ticketingAPI, DoRefreshToken }
