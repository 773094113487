// eslint-disable-next-line
const loadDnaUOL = (onLoadIsComplete: (result: any) => void) => {
  const existingScript = document.getElementById('DnaUOL')

  if (!existingScript) {
    const script = document.createElement('script')
    script.src = process.env.DNA_UOL_URL
    script.id = 'DnaUOL'
    script.type = 'text/javascript'
    script.async = true

    document.getElementsByTagName('head')[0].appendChild(script)

    script.onload = () => {
      window.DnaUOL.run({
        callback: (dna) => onLoadIsComplete(dna)
      })
    }
  }
}

export default loadDnaUOL
