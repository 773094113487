import { GlobalContext } from '@shared/context/Global'
import { useContextSelector } from 'use-context-selector'

export function useAuthToken() {
  const authToken = useContextSelector(
    GlobalContext,
    (state) => state?.authToken
  )
  const onSetAuthToken = useContextSelector(
    GlobalContext,
    (state) => state?.onSetAuthToken
  )
  const isOpenCollapseUser = useContextSelector(
    GlobalContext,
    (state) => state?.isOpenCollapseUser
  )
  const onSetIsOpenCollapseUser = useContextSelector(
    GlobalContext,
    (state) => state?.onSetIsOpenCollapseUser
  )

  return {
    authToken,
    onSetAuthToken,
    isOpenCollapseUser,
    onSetIsOpenCollapseUser
  }
}
