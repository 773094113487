import { GlobalContext } from '@shared/context/Global'
import { useContextSelector } from 'use-context-selector'

export function useSessionId() {
  const sessionId = useContextSelector(
    GlobalContext,
    (state) => state?.sessionId
  )

  const onSetSessionId = useContextSelector(
    GlobalContext,
    (state) => state?.onSetSessionId
  )
  return {
    sessionId,
    onSetSessionId
  }
}
