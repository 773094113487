import React, { FC } from 'react'

import * as S from './styled'
import { IProps } from './interface'
import Icon from '@Components/Icon'

const FooterAlert: FC<IProps> = ({
  type = 'info',
  title,
  content,
  isVisible = false,
  onClose,
  onlyShowContentInHeader = true
}: IProps) => {
  return (
    <S.Container type={type} isVisible={isVisible}>
      {onlyShowContentInHeader ? (
        <>
          <S.Header>
            <Icon
              className="titleIcon"
              src={`icons/${type}`}
              size={18}
              color="#00CC00"
            />
            {content && <S.Content>{content}</S.Content>}
            {title && <S.Title>{title}</S.Title>}
            <Icon
              className="closeIcon"
              src="icons/close"
              onClick={onClose}
              size={18}
              color="#00CC00"
            />
          </S.Header>
        </>
      ) : (
        <>
          <S.Header>
            <Icon className="titleIcon" src={`icons/${type}`} size={18} />
            {title && <S.Title>{title}</S.Title>}
            <Icon
              className="closeIcon"
              src="icons/close"
              onClick={onClose}
              size={14}
              color="#00CC00"
            />
          </S.Header>
          {content && <S.Body>{content}</S.Body>}
        </>
      )}
    </S.Container>
  )
}

export default FooterAlert
