import endpoints from '@shared/endpoints'
import { ticketingAPI } from '@shared/request/Api'
import { handleError } from '@shared/request/error'
import { getAuthTypeHeader } from '../utils/helpers/headers'

export const tokenApiPayment = process.env.tokenApiPayment
export const urlApiPayment = process.env.baseUrlApiPayment

export const gift = async (cartId, giftbody) => {
  try {
    const { data } = await ticketingAPI.post(
      endpoints.cartEndpoint.CARTS_PAYMENTS.get(cartId),
      giftbody,
      {
        headers: getAuthTypeHeader()
      }
    )
    return [data, null]
  } catch (error) {
    return [null, handleError(error)]
  }
}

export const order = async (body) => {
  try {
    const { data } = await ticketingAPI.post(
      endpoints.orderEndpoints.ORDERS.get(),
      body,
      {
        headers: getAuthTypeHeader()
      }
    )
    return [data, null]
  } catch (error) {
    return [null, handleError(error)]
  }
}

export const cancelAsyncOrder = async (orderId, body) => {
  try {
    const { data } = await ticketingAPI.post(
      endpoints.orderEndpoints.CANCEL_ASYNC_ORDER.get(orderId),
      body,
      {
        headers: getAuthTypeHeader()
      }
    )
    return [data, null]
  } catch (error) {
    return [null, handleError(error)]
  }
}

export const asyncOrder = async (body) => {
  try {
    const { data } = await ticketingAPI.post(
      endpoints.orderEndpoints.ASYNC_ORDERS.get(),
      body,
      {
        headers: getAuthTypeHeader()
      }
    )
    return [data, null]
  } catch (error) {
    return [null, handleError(error)]
  }
}

export const orderCompleted = async (orderID) => {
  try {
    const { data } = await ticketingAPI.get(
      endpoints.orderEndpoints.ORDERS_COMPLETED.get(orderID),
      {
        headers: getAuthTypeHeader()
      }
    )
    return [data, null]
  } catch (error) {
    return [null, handleError(error)]
  }
}

export const paymentsTokens = async (body) => {
  try {
    const { data } = await ticketingAPI.post(
      `${urlApiPayment}/payments/tokens`,
      body,
      {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: tokenApiPayment
        }
      }
    )
    return [data, null]
  } catch (error) {
    return [null, handleError(error)]
  }
}

export const addPayments = async (cartId: string, paymentData) => {
  try {
    const { data } = await ticketingAPI.post(
      endpoints.cartEndpoint.CARTS_PAYMENTS.get(cartId),
      paymentData,
      {
        headers: getAuthTypeHeader()
      }
    )
    return [data, null]
  } catch (error) {
    return [null, handleError(error)]
  }
}
export const addPaymentsApplePay = async (cartId: string, paymentData) => {
  try {
    const { data } = await ticketingAPI.post(
      endpoints.cartEndpoint.CARTS_PAYMENTS.get(cartId),
      paymentData,
      {
        headers: getAuthTypeHeader()
      }
    )
    return [data, null]
  } catch (error) {
    return [null, handleError(error)]
  }
}

export const printTickets = async (printUrl: string) => {
  try {
    const { data } = await ticketingAPI.get(printUrl, {
      headers: getAuthTypeHeader(),
      responseType: 'arraybuffer'
    })

    return [data, null]
  } catch (error) {
    return [null, handleError(error)]
  }
}

export const paymentMethods = async (cardId: string) => {
  try {
    const { data } = await ticketingAPI.get(
      endpoints.paymentEndpoints.PAYMENT_METHODS.get(cardId),
      {
        headers: getAuthTypeHeader()
      }
    )
    return [data, null]
  } catch (error) {
    return [null, handleError(error)]
  }
}

export const payments = async (userId: string) => {
  try {
    const { data } = await ticketingAPI.get(
      endpoints.paymentEndpoints.PAYMENTS.get(userId)
    )
    return [data, null]
  } catch (error) {
    return [null, handleError(error)]
  }
}

export const deleteSaveCard = async (userId: string, paymentCardId: string) => {
  try {
    const { data } = await ticketingAPI.delete(
      endpoints.paymentEndpoints.SAVEDCARD_DELETE.get(userId, paymentCardId),
      {
        headers: getAuthTypeHeader()
      }
    )
    return [data, null]
  } catch (error) {
    return [null, handleError(error)]
  }
}
export const validateApplePay = async (
  cartId: string,
  validationURL: string
) => {
  try {
    const { data } = await ticketingAPI.post(
      endpoints.paymentEndpoints.APPLEPAY_VALIDATION.post(cartId),
      {
        headers: getAuthTypeHeader(),
        validationURL
      }
    )
    return { data }
  } catch (error) {
    return { error: handleError(error) }
  }
}

export const paymentStatus = async (orderId: string) => {
  try {
    const { data } = await ticketingAPI.get(
      endpoints.paymentEndpoints.PAYMENT_STATUS.get(orderId),
      {
        headers: getAuthTypeHeader()
      }
    )
    return [data, null]
  } catch (error) {
    return [null, handleError(error)]
  }
}
