import { GlobalContext } from '@shared/context/Global'
import { useContextSelector } from 'use-context-selector'

export function useBomboniere() {
  const bomboniere = useContextSelector(
    GlobalContext,
    (state) => state?.bomboniere
  )

  const onSetBomboniere = useContextSelector(
    GlobalContext,
    (state) => state?.onSetBomboniere
  )
  return {
    bomboniere,
    onSetBomboniere
  }
}
