import { useContextSelector } from 'use-context-selector'
import { GlobalContext } from '@shared/context/Global'

export function useCartIsReady() {
  const cartIsReady = useContextSelector(
    GlobalContext,
    (state) => state?.cartIsReady
  )
  const onSetCartIsReady = useContextSelector(
    GlobalContext,
    (state) => state?.onSetCartIsReady
  )

  return {
    cartIsReady,
    onSetCartIsReady
  }
}
