import { useContextSelector } from 'use-context-selector'
import { GlobalContext } from '@shared/context/Global'

export function useCheckout() {
  const globalLoading = useContextSelector(
    GlobalContext,
    (state) => state?.globalLoading
  )
  const onSetGlobalLoading = useContextSelector(
    GlobalContext,
    (state) => state?.onSetGlobalLoading
  )

  return {
    globalLoading,
    onSetGlobalLoading
  }
}
