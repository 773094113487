import { useAdyenCheckoutContext } from '@shared/context/Adyen/AdyenContext'
import { useEffect, useState } from 'react'
import CheckoutUtils from '@shared/utils/cart'
import { useCartIsReady } from '@shared/hooks/useCartIsReady'
import { useCheckout } from '@shared/hooks/useCheckout'
import { useLoading } from './useLoading'
import { useModal } from './useModal'
import schemas from '@Schemas/index'
import { addPayments, paymentsTokens } from '@Services/payments'
import { useCart } from './useCart'
import services from '@Services/index'
import { useDiscounts } from './useDiscounts'
import { clarity } from 'react-microsoft-clarity'
import { useLogin } from './useLogin'
import { GlobalContext } from '@shared/context/Global'
import { useContextSelector } from 'use-context-selector'
import { UseTracking } from '@shared/hooks/useTracking'
import { useAsyncPayment } from './payment/useAsyncPayment'
import { useWebView } from './useWebView'
import { useWebViewWithTracking } from './useWebViewWithTracking'
import { useCookies } from './useCookies'

export function usePayment() {
  const { getCookie } = useCookies()
  const {
    fingerprint,
    generateFingerprint,
    fingerprintMyToken,
    paymentToken,
    cardValue,
    adyenInstance,
    resetState,
    validFieldsCard
  } = useAdyenCheckoutContext()
  const { trackCheckoutErrorApp } = useWebViewWithTracking()

  const { cart, goConcluded, restartSession, goSeatsOnNotAvailableSeatError } =
    useCart()
  const { redirectPaymentAsyncPage } = useAsyncPayment()
  const [tokenFingerprint, setTokenFingerprint] = useState(null)
  const showModalChallenge = useContextSelector(
    GlobalContext,
    (state) => state?.showModalChallenge
  )

  const onSetModalChallenge = useContextSelector(
    GlobalContext,
    (state) => state?.onSetModalChallenge
  )

  const { discounts } = useDiscounts()
  const { onSetIsLoading, onSetIsLoadingButton } = useLoading()
  const { onSetCartIsReady } = useCartIsReady()
  const { onSetGlobalLoading } = useCheckout()
  const { closeCheckout } = useWebView()
  const {
    getTotalPriceFromCart,
    getTotalGiftCardsAndPromocodes,
    getTotalPriceFromCartBeforeDiscount,
    getOnlyGiftCardOrPromocodes
  } = CheckoutUtils()

  const { onSetIsOpenLogin } = useLogin()
  const { onSetIsOpenModal } = useModal()

  const threeDSConfiguration = {
    challengeWindowSize: '01'
  }
  const { trackModalPixEvents } = UseTracking()
  const { trackModalPixEventsApp } = useWebViewWithTracking()

  const { isMobileApp, goToConfirmed, isIOS, checkoutApplePayWasError } =
    useWebView()

  function createFingerprintModal(data) {
    onSetModalChallenge(true)
    const action = {
      authorisationToken: data.authorisationToken?.[0],
      paymentData: data.paymentData?.[0],
      paymentMethodType: data.paymentMethodType?.[0],
      subtype: data.subtype?.[0],
      token: data.token?.[0],
      type: data.type?.[0]
    }
    adyenInstance
      .createFromAction(action, threeDSConfiguration)
      .mount('#fingerprint-3ds')
  }
  const getErrorNamePayment = async (error) => {
    onSetIsLoading(false)
    if (error?.modelState) {
      if (error.modelState?.paymentError && error.modelState?.paymentError[0]) {
        onSetModalChallenge(false)
        return onSetIsOpenModal({
          ...schemas.modal.ADD_TICKETS_ERROR,
          description: error.modelState.paymentError[0],
          onConfirm: () => {
            onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
          },
          onClose: () => {
            onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
          }
        })
      }

      if (error?.modelState?.errorSeatSelected) {
        trackCheckoutErrorApp(
          error.status,
          'Error Seat Selected',
          error?.modelState?.errorSeatSelected[0]
        )

        return onSetIsOpenModal({
          ...schemas.modal.PAYMENT_ERROR,
          description: error?.modelState?.errorSeatSelected[0],
          onClose: () => {
            onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
            goSeatsOnNotAvailableSeatError()
          }
        })
      }

      if (error?.modelState?.orderError) {
        return onSetIsOpenModal({
          ...schemas.modal.PAYMENT_ERROR,
          description: error?.modelState?.orderError[0],
          onClose: () => {
            onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
          }
        })
      }

      if (error?.modelState?.errorSeatTimeout) {
        return onSetIsOpenModal({
          ...schemas.modal.PAYMENT_ERROR,
          description: error?.modelState?.errorSeatTimeout[0],
          onClose: () => {
            onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
          }
        })
      }

      if (error?.modelState?.sessionError) {
        return onSetIsOpenModal({
          ...schemas.modal.PAYMENT_ERROR,
          description: error?.modelState?.sessionError[0],
          onClose: () => {
            onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
            if (isMobileApp) closeCheckout()
            window.location.href = process.env.baseUrlCatalog
          }
        })
      }

      if (
        error.modelState?.reserveSeatsError &&
        error.modelState?.reserveSeatsError[0]
      ) {
        return onSetIsOpenModal({
          ...schemas.modal.SEATS_EXPIRED_SESSION_CUSTOM({
            isOpen: true,
            description: error.modelState?.reserveSeatsError[0]
          }),

          onConfirm: () => {
            onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
            restartSession()
          },
          onClose: () => {
            onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
          }
        })
      }

      if (
        error.modelState?.moreInformation &&
        error.modelState?.moreInformation[0] === 'ChallengeShopper'
      ) {
        onSetModalChallenge(true)
        setTokenFingerprint(error.modelState)
        createChallengeModal(error.modelState)
      }

      if (
        error.modelState?.moreInformation &&
        error.modelState?.moreInformation[0] === 'IdentifyShopper'
      ) {
        generateFingerprint(error.modelState)
        setTokenFingerprint(error.modelState)
        createFingerprintModal(error.modelState)
      }

      if (
        error.modelState?.moreInformation &&
        error.modelState?.moreInformation[0] === 'Pending'
      ) {
        redirectPaymentAsyncPage(error.modelState)
      }

      if (
        error?.modelState?.paymentError?.[0]?.includes(
          'Ocorreu um erro com o meio de pagamento'
        )
      ) {
        onSetIsOpenModal({
          ...schemas.modal.PAYMENT_ERROR,
          description: error?.modelState?.paymentError[0],
          onClose: () => {
            onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
          }
        })
      }

      if (
        error?.modelState?.cartError &&
        error?.modelState?.cartError[0].includes(
          'O tempo para finalizar a compra se excedeu'
        )
      ) {
        onSetIsOpenModal({
          ...schemas.modal.PURCHASE_TIME_EXPIRED,
          onConfirm: () => {
            restartSession()
            onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
          },
          onClose: () => {
            onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
          }
        })
      }
    }
    if (error.status === 401) {
      onSetIsOpenModal({
        ...schemas.modal.TOKEN_TIME_EXPIRED,
        onConfirm: () => {
          onSetIsOpenLogin({ show: true })
          onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
        },
        onClose: () => {
          onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
        }
      })
    }
    if (error.status === 404) {
      onSetIsOpenModal({
        ...schemas.modal.PURCHASE_TIME_EXPIRED,
        onConfirm: () => {
          restartSession()
          onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
        },
        onClose: () => {
          onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
        }
      })
    }
  }
  function createChallengeModal(data) {
    const action = {
      authorisationToken: data.authorisationToken?.[0],
      paymentData: data.paymentData?.[0],
      paymentMethodType: data.paymentMethodType?.[0],
      subtype: data.subtype?.[0],
      token: data.token?.[0],
      type: data.type?.[0]
    }
    adyenInstance
      .createFromAction(action, threeDSConfiguration)
      .mount('#challenge-3ds')
  }

  function showModalExpired() {
    trackModalPixEvents({
      event: 'gaEvent',
      e_event: 'pix_event',
      e_action: 'OpenPixExpirationModal',
      e_label: '',
      e_description: ''
    })
    trackModalPixEventsApp({
      event: 'gaEvent',
      e_event: 'pix_event',
      e_action: 'OpenPixExpirationModal',
      e_label: '',
      e_description: ''
    })
    onSetIsOpenModal({
      ...schemas.modal.PIX_ERROR_EXPIRED,
      onClose: () => {
        onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
        onSetIsLoading(false)
        restartSession()
      }
    })
  }

  const postback = async () => {
    if (tokenFingerprint) {
      const typeIndetifyShopper: {}[] =
        paymentToken?.holderName && Object.keys(cardValue.data).length === 0
          ? [
              {
                PaymentId: tokenFingerprint.paymentId[0],
                type: cardValue.cardType,
                value: getTotalPriceFromCart(),
                brand:
                  cardValue.brand === 'mc' ? 'Mastercard' : cardValue.brand,
                bin: cardValue.bin,
                summary: cardValue.summary,
                save: paymentToken.storePaymentMethod,
                browserInfo: paymentToken?.browserInfo,
                origin: window.location.origin,
                threeDsFingerprint: tokenFingerprint.threeDsFingerprintToken[0],
                transactionId: tokenFingerprint.transactionId[0],
                completePayment: {
                  fingerprint: fingerprintMyToken,
                  transactionId: tokenFingerprint.paymentApiTransactionId[0],
                  authorizationStatus: 'IdentifyShopper'
                }
              }
            ]
          : [
              {
                PaymentId: tokenFingerprint.paymentId[0],
                type: 'CreditCardExpress',
                value: getTotalPriceFromCart(),
                origin: window.location.origin,
                bin: cardValue.data.bin,
                id: cardValue.data.id,
                brand:
                  cardValue.data.brand === 'mc'
                    ? 'Mastercard'
                    : cardValue.data.brand,
                browserInfo: paymentToken?.browserInfo,
                threeDsFingerprint: tokenFingerprint.threeDsFingerprintToken[0],
                transactionId: tokenFingerprint.transactionId[0],
                completePayment: {
                  fingerprint: fingerprintMyToken,
                  transactionId: tokenFingerprint.paymentApiTransactionId[0],
                  authorizationStatus: 'IdentifyShopper'
                }
              }
            ]

      const typeChallenge: {}[] =
        Object.keys(cardValue.data).length === 0 || undefined
          ? [
              {
                id: cardValue.data.id,
                PaymentId: tokenFingerprint.paymentId[0],
                type: cardValue.cardType,
                value: getTotalPriceFromCart(),
                brand:
                  cardValue.brand === 'mc' ? 'Mastercard' : cardValue.brand,
                bin: cardValue.bin,
                summary: cardValue.summary,
                save: paymentToken?.storePaymentMethod,
                browserInfo: paymentToken?.browserInfo,
                origin: window.location.origin,
                transactionId: tokenFingerprint.transactionId[0],
                completePayment: {
                  threeDsChallengeResult: fingerprintMyToken,
                  transactionId: tokenFingerprint.paymentApiTransactionId[0],
                  authorizationStatus: 'ChallengeShopper'
                }
              }
            ]
          : [
              {
                PaymentId: tokenFingerprint.paymentId[0],
                type: 'CreditCardExpress',
                value: getTotalPriceFromCart(),
                browserInfo: paymentToken?.browserInfo,
                origin: window.location.origin,
                bin: cardValue.data.bin,
                id: cardValue.data.id,
                transactionId: tokenFingerprint.transactionId[0],
                brand:
                  cardValue.data.brand === 'mc'
                    ? 'Mastercard'
                    : cardValue?.data?.brand?.toLowerCase(),
                completePayment: {
                  threeDsChallengeResult: fingerprintMyToken,
                  transactionId: tokenFingerprint.paymentApiTransactionId[0],
                  authorizationStatus: 'ChallengeShopper'
                }
              }
            ]
      if (getOnlyGiftCardOrPromocodes().length > 0) {
        typeIndetifyShopper.push(...getOnlyGiftCardOrPromocodes())
        typeChallenge.push(...getOnlyGiftCardOrPromocodes())
      }

      try {
        const [data, error] = await addPayments(
          cart.id,
          tokenFingerprint.moreInformation[0] === 'IdentifyShopper'
            ? typeIndetifyShopper
            : typeChallenge
        )

        try {
          clarity.setTag('cart', cart.id)
        } catch (error) {
          console.log(error)
        }

        if (error?.modelState) {
          getErrorNamePayment(error)
          onSetIsLoading(false)
        } else {
          onSetModalChallenge(false)
          await createOrder()
        }

        console.log('postback', data, error)
      } catch (error) {
        console.log(error)
      } finally {
        resetState()
      }
    }
  }
  const createOrder = async (isApplePay?: boolean) => {
    try {
      try {
        clarity.setTag('cart', cart.id)
        clarity.setTag('session', cart.sessionId)
      } catch (error) {
        console.log(error)
      }

      onSetIsLoading(true)
      onSetIsLoadingButton(true)

      const [data, error] = await services.payments.order({
        cartId: cart.id,
        fingerprint: getCookie(process.env.COOKIE_FINGERPRINT) ?? ''
      })
      if (error?.modelState?.errorSeatSelected) {
        onSetIsLoading(false)
        onSetIsLoadingButton(false)
        trackCheckoutErrorApp(
          error.status,
          'Error Seat Selected',
          error?.modelState?.errorSeatSelected[0]
        )
        return onSetIsOpenModal({
          ...schemas.modal.PAYMENT_ERROR,
          description: error?.modelState?.errorSeatSelected[0],
          onClose: () => {
            onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
            goSeatsOnNotAvailableSeatError()
          }
        })
      } else if (error?.modelState?.productConsumeError) {
        onSetIsLoading(false)
        onSetIsLoadingButton(false)
        return onSetIsOpenModal({
          ...schemas.modal.PAYMENT_ERROR,
          description: error?.modelState?.productConsumeError[0],
          onClose: () => {
            onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
          }
        })
      } else if (error?.modelState?.errorSeatTimeout) {
        onSetIsLoading(false)
        onSetIsLoadingButton(false)
        return onSetIsOpenModal({
          ...schemas.modal.PAYMENT_ERROR,
          description: error?.modelState?.errorSeatTimeout[0],
          onClose: () => {
            onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
          }
        })
      } else if (error?.modelState?.orderError) {
        onSetIsLoading(false)
        onSetIsLoadingButton(false)
        return onSetIsOpenModal({
          ...schemas.modal.PAYMENT_ERROR,
          description: error?.modelState?.orderError[0],
          onClose: () => {
            onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
          }
        })
      } else if (error?.status === 404) {
        return onSetIsOpenModal({
          ...schemas.modal.PURCHASE_TIME_EXPIRED,
          onConfirm: () => {
            restartSession()
            onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
          },
          onClose: () => {
            restartSession()
            onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
          }
        })
      } else if (error) {
        onSetIsLoading(false)
        onSetIsLoadingButton(false)
        return onSetIsOpenModal({
          ...schemas.modal.PAYMENT_ERROR,
          onClose: () => {
            onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
          }
        })
      }

      try {
        clarity.setTag('order', data?.id)
      } catch (error) {
        console.log(error)
      }

      onSetCartIsReady(false)
      onSetGlobalLoading(true)
      if (isMobileApp()) {
        goToConfirmed(data)
      } else {
        goConcluded(data.id)
      }
    } catch (error) {
      if (isApplePay && isIOS()) checkoutApplePayWasError(error)
      return error
    }
  }
  const submitPayment = async () => {
    const paymentType = !paymentToken?.paymentMethod?.paymentType
      ? 'AdyenCardComponent'
      : paymentToken?.paymentMethod?.type

    // apenas promocode ou giftCard
    if (
      getOnlyGiftCardOrPromocodes().length > 0 &&
      typeof paymentToken?.paymentMethod?.encryptedCardNumber === 'undefined' &&
      typeof paymentToken?.paymentMethod?.encryptedSecurityCode ===
        'undefined' &&
      typeof paymentToken?.paymentMethod?.type === 'undefined'
    ) {
      if (
        getTotalGiftCardsAndPromocodes() >=
        getTotalPriceFromCartBeforeDiscount()
      ) {
        onSetIsOpenModal({
          ...schemas.modal.GIFT_PAYMENT,
          onClose: () => {
            onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
          },
          onConfirm: async () => {
            try {
              onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
              onSetIsLoading(true)
              onSetIsLoadingButton(true)
              const [, error] = await services.payments.gift(
                cart.id,
                getOnlyGiftCardOrPromocodes()
              )
              if (error?.modelState) {
                getErrorNamePayment(error)
                onSetIsLoading(false)
              } else {
                await createOrder()
              }
            } catch (error) {
              return error
            } finally {
              onSetIsLoading(false)
              onSetIsLoadingButton(false)
            }
          }
        })
      } else {
        onSetIsOpenModal({
          ...schemas.modal.ERROR_SEND_PAYMENT_CARD,
          onConfirm: () => {
            onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
          },
          onClose: () => {
            onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
          }
        })
      }
    } else if (
      typeof paymentToken?.paymentMethod?.encryptedCardNumber !== 'undefined' &&
      getOnlyGiftCardOrPromocodes().length === 0
    ) {
      // apenas cartão

      const paymentValue = paymentToken?.encryptedExpiryMonth
        ? {
            type: paymentType,
            token: JSON.stringify({
              ...paymentToken.paymentMethod,
              storePaymentMethod: paymentToken.storePaymentMethod
            })
          }
        : {
            type: paymentType,
            token: JSON.stringify({
              encryptedSecurityCode:
                paymentToken?.paymentMethod?.encryptedSecurityCode,
              id: cardValue.data.id
            })
          }
      if (!fingerprint) {
        try {
          onSetIsLoading(true)
          onSetIsLoadingButton(true)
          const data = await paymentsTokens(paymentValue)
          sendPayment(data[0].id)
        } catch (error) {
          console.log(error)
        }
      }
    } else {
      // cartão e promocode juntos
      const paymentValue =
        paymentToken?.encryptedExpiryMonth ||
        paymentToken?.paymentMethod?.paymentType === 'pix' ||
        paymentToken?.paymentMethod?.paymentType === 'nupay'
          ? {
              type: paymentType,
              token: JSON.stringify({
                ...paymentToken.paymentMethod,
                storePaymentMethod: paymentToken.storePaymentMethod
              })
            }
          : {
              type: paymentType,
              token: JSON.stringify({
                encryptedSecurityCode:
                  paymentToken?.paymentMethod?.encryptedSecurityCode,
                id: cardValue?.data?.id
              })
            }
      if (!fingerprint) {
        try {
          onSetIsLoading(true)
          onSetIsLoadingButton(true)
          const data = await paymentsTokens(paymentValue)
          sendPayment(data[0].id)
        } catch (error) {
          console.log(error)
        }
      }
    }
  }

  const initPayment = async () => {
    if (paymentToken?.paymentMethodData) {
      clarity.setTag('paymentType', 'GooglePay')
      if (paymentToken?.paymentMethodData?.tokenizationData?.token) {
        const bodyToken = {
          token: paymentToken?.paymentMethodData?.tokenizationData?.token,
          type: 'GooglePay'
        }

        const tokenGooglePay = await paymentsTokens(bodyToken)
        const filterDiscounts = discounts.filter(
          (item) => item.type !== 'ClubUol'
        )

        const payloadPayment = [
          {
            PaymentId: tokenGooglePay[0]?.id,
            type: 'GooglePay',
            value: getTotalPriceFromCart(),
            brand: paymentToken?.paymentMethodData?.info?.cardNetwork,
            assuranceDetails:
              paymentToken?.paymentMethodData?.info?.assuranceDetails,
            billingAddress:
              paymentToken?.paymentMethodData?.info?.billingAddress
          },
          ...filterDiscounts
        ]

        const [, error] = await services.payments.gift(cart.id, payloadPayment)
        if (error) {
          getErrorNamePayment(error)
          onSetIsLoading(false)
          onSetIsLoadingButton(false)
        } else {
          onSetModalChallenge(false)
          await createOrder()
        }

        return
      } else {
        onSetIsOpenModal({
          ...schemas.modal.ERROR_SEND_PAYMENT_GOOGLE_PAY,
          onConfirm: () => {
            onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
          },
          onClose: () => {
            onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
          }
        })
      }
    }
    if (
      getTotalPriceFromCart() <= 0 &&
      getOnlyGiftCardOrPromocodes().length <= 0
    ) {
      await createOrder()
      return
    }
    if (paymentToken || getOnlyGiftCardOrPromocodes().length > 0) {
      if (
        paymentToken?.paymentMethod?.paymentType === 'pix' ||
        paymentToken?.paymentMethod?.paymentType === 'nupay' ||
        validFieldsCard ||
        getOnlyGiftCardOrPromocodes().length > 0
      ) {
        submitPayment()
      } else {
        onSetIsOpenModal({
          ...schemas.modal.ERROR_SEND_PAYMENT_CARD,
          onConfirm: () => {
            onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
          },
          onClose: () => {
            onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
          }
        })
      }
    } else {
      onSetIsOpenModal({
        ...schemas.modal.ERROR_SEND_PAYMENT_CARD,
        onConfirm: () => {
          onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
        },
        onClose: () => {
          onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
        }
      })
    }
  }
  const sendPayment = async (id) => {
    let typePayments = []

    if (
      (Object.keys(cardValue.data).length === 0 || undefined) &&
      (paymentToken?.holderName ||
        paymentToken?.paymentMethod?.paymentType === 'pix')
    ) {
      typePayments = [
        {
          PaymentId: id,
          type: cardValue.cardType ?? 'PixPagBank',
          value: getTotalPriceFromCart(),
          save: paymentToken.storePaymentMethod,
          browserInfo: paymentToken?.browserInfo,
          origin: window.location.origin,
          bin: cardValue.bin,
          name: paymentToken?.holderName,
          brand: cardValue.brand === 'mc' ? 'Mastercard' : cardValue.brand,
          summary: cardValue.summary,
          cardMethod: paymentToken?.paymentMethod?.paymentType
            ? paymentToken?.paymentMethod?.paymentType
            : undefined
        }
      ]
    } else {
      if (
        (Object.keys(cardValue.data).length === 0 || undefined) &&
        (paymentToken?.holderName ||
          paymentToken?.paymentMethod?.paymentType === 'nupay')
      ) {
        typePayments = [
          {
            PaymentId: id,
            type: cardValue.cardType ?? 'NuPay',
            value: getTotalPriceFromCart(),
            save: paymentToken.storePaymentMethod,
            browserInfo: paymentToken?.browserInfo,
            origin: window.location.origin,
            bin: cardValue.bin,
            name: paymentToken?.holderName,
            brand: cardValue.brand === 'mc' ? 'Mastercard' : cardValue.brand,
            summary: cardValue.summary,
            cardMethod: paymentToken?.paymentMethod?.paymentType
              ? paymentToken?.paymentMethod?.paymentType
              : undefined
          }
        ]
      } else {
        if (Object.keys(cardValue.data).length > 0) {
          typePayments = [
            {
              PaymentId: id,
              type: 'CreditCardExpress',
              value: getTotalPriceFromCart(),
              browserInfo: paymentToken?.browserInfo,
              origin: window.location.origin,
              bin: cardValue.data.bin,
              id: cardValue.data.id,
              brand:
                cardValue.data.brand === 'mc'
                  ? 'Mastercard'
                  : cardValue?.data?.brand?.toLowerCase()
            }
          ]
        }
      }
    }

    try {
      clarity.setTag('cart', cart.id)
      clarity.setTag('session', cart.sessionId)
      clarity.setTag('paymentType', typePayments[0]?.type)
    } catch (error) {
      console.log(error)
    }

    if (getOnlyGiftCardOrPromocodes().length > 0) {
      typePayments.push(...getOnlyGiftCardOrPromocodes())
    }

    const [data, error] = await addPayments(cart.id, typePayments)

    if (error?.modelState || error?.status) {
      onSetIsLoadingButton(false)
      getErrorNamePayment(error)
    } else {
      if (data && (data?.subtype === 'Pix' || data?.subtype === 'NuPay')) {
        redirectPaymentAsyncPage(data)
      } else {
        await createOrder()
      }
    }
  }

  useEffect(() => {
    if (tokenFingerprint?.moreInformation[0] && fingerprintMyToken) {
      postback()
    }
  }, [tokenFingerprint, fingerprintMyToken])

  return {
    initPayment,
    showModalChallenge,
    getErrorNamePayment,
    createOrder,
    showModalExpired
  }
}
