class TrackingService {
  trackPageView = (pageView: string, data: {}) => {
    if (typeof window !== 'undefined') {
      window?.dataLayer?.push({
        event: 'pageview',
        ...data,
        e_event: null,
        e_action: null,
        e_label: null,
        e_description: null,
        ecommerce: null
      })
    }
  }
  trackClickEvent = (event: string, data: object) => {
    window?.dataLayer?.push({
      event,
      ...data
    })
  }
  trackAddToCartEvent = (data: object) => {
    window?.dataLayer?.push(data)
  }
  trackBeginCheckoutEvent = (data: object) => {
    window?.dataLayer?.push(data)
  }
  trackTransactionEvent = (data: object) => {
    window?.dataLayer?.push(data)
  }
}
const trackingService = new TrackingService()

export default trackingService
