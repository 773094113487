import styled from 'styled-components'

export const ContainerButton = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #ccc;
`
export const OtherButtonContent = styled.div`
  text-align: center;
  position: relative;
  display: inline-block;
  font-weight: bold;
  margin: 25px 0;

  h3 {
    text-align: center;
    color: #999;
  }

  &:after,
  :before {
    content: '';
    position: absolute;
    top: 50%;
    width: 40%;
    height: 0;
    border-bottom: 1px solid #ccc;
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }
`
export const ContainerInput = styled.div`
  margin-bottom: 8px;
`
export const NewsLetterPoweredBy = styled.div`
  display: flex;
  flex-direction: row;

  padding-top: 12px;
  gap: 6px;
  input {
    margin: 0;
    width: 20px;
    height: 20px;

    :checked {
      accent-color: #fff;
    }
  }

  p {
    text-align: start;
    width: 89%;
    color: ${(props) => props.theme?.global.four.color};
  }

  @media (max-width: 992px) {
    p {
      text-align: start;
      width: 90%;
    }
  }
  margin-bottom: 8px;
`
export const Newsletter = styled.div`
  display: flex;
  margin-top: 12px;
  input {
    width: 20px;
    height: 20px;

    :checked {
      accent-color: #fff;
    }
  }

  span {
    margin-left: 5px;
    text-align: start;
    width: 89%;
    color: ${(props) => props.theme?.global.four.color};
  }
  margin-bottom: 8px;
`
