import React from 'react'

import { IProps } from './interface'
import { SSubtitleBold } from './styled'

const SubtitleBold = ({ children, className, color }: IProps) => {
  return (
    <SSubtitleBold className={className} color={color}>
      {children}
    </SSubtitleBold>
  )
}

export default SubtitleBold
