import { useContextSelector } from 'use-context-selector'
import { GlobalContext } from '@shared/context/Global'

export function useAlert() {
  const alert = useContextSelector(GlobalContext, (state) => state?.alert)
  const alertFooter = useContextSelector(
    GlobalContext,
    (state) => state?.alertFooter
  )
  const onSetIsOpenAlert = useContextSelector(
    GlobalContext,
    (state) => state?.onSetAlert
  )
  const onSetIsOpenAlertFooter = useContextSelector(
    GlobalContext,
    (state) => state?.onSetAlertFooter
  )
  return {
    alert,
    onSetIsOpenAlert,
    alertFooter,
    onSetIsOpenAlertFooter
  }
}
