import React from 'react'

import { IProps } from './interface'
import { SParagraphRegular } from './styled'

const ParagraphRegular = ({ children, className }: IProps) => {
  return <SParagraphRegular className={className}>{children}</SParagraphRegular>
}

export default ParagraphRegular
