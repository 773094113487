import React from 'react'
import { ReactSVG } from 'react-svg'

import { SIcon } from './styled'
import { IProps } from './interface'

const Icon = ({
  src,
  size,
  height,
  width,
  color,
  unit,
  className,
  isIcon,
  external,
  customHeightContainerIcon,
  ...svgProps
}: IProps) => {
  const {
    afterInjection,
    beforeInjection,
    evalScripts,
    fallback,
    loading,
    renumerateIRIElements,
    wrapper,
    onClick,
    'aria-label': ariaLabel,
    role
  } = svgProps

  const path = external ? src : `/static/images/${src}.svg`
  return (
    <SIcon
      height={size || height}
      width={size || width}
      customHeightContainerIcon={customHeightContainerIcon}
      color={color ? color : null}
      unit={unit}
      className={className}
      isIcon={isIcon}
      data-testid="icon-element"
    >
      <ReactSVG
        src={path}
        data-testid="svg-element"
        afterInjection={afterInjection}
        beforeInjection={beforeInjection}
        evalScripts={evalScripts}
        fallback={fallback}
        loading={loading}
        renumerateIRIElements={renumerateIRIElements}
        wrapper={wrapper}
        onClick={onClick}
        aria-label={ariaLabel}
        role={role}
      />
    </SIcon>
  )
}

export const defaultProps = {
  src: 'icons/faq',
  height: 24,
  width: 24,
  unit: 'px',
  isIcon: true,
  external: false
}

Icon.defaultProps = defaultProps

export default Icon
