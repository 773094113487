import { useContextSelector } from 'use-context-selector'
import { GlobalContext } from '@shared/context/Global'

export function useFidelity() {
  const fidelity = useContextSelector(GlobalContext, (state) => state?.fidelity)
  const fidelityFields = useContextSelector(
    GlobalContext,
    (state) => state?.fidelityFields
  )
  const onSetIsFidelity = useContextSelector(
    GlobalContext,
    (state) => state?.onSetFidelity
  )
  const onSetFidelityFields = useContextSelector(
    GlobalContext,
    (state) => state?.onSetFidelityFields
  )
  const onSetSameCpfFidelity = useContextSelector(
    GlobalContext,
    (state) => state?.onSetSameCpfFidelity
  )
  const sameCpfFidelity = useContextSelector(
    GlobalContext,
    (state) => state?.sameCpfFidelity
  )
  return {
    fidelity,
    onSetIsFidelity,
    fidelityFields,
    onSetFidelityFields,
    sameCpfFidelity,
    onSetSameCpfFidelity
  }
}
