import Icon from '../Icon'
import CheckoutUtils from '@shared/utils/cart'
import { useSeats } from '@shared/hooks/useSeats'
import { useSessionData } from '@shared/hooks/useSessionData'
import SessionContainer from '@Components/SessionContainer'
import { formatCurrencyValue } from '@Helpers/Currency'
import { useAdyenCheckoutContext } from '@shared/context/Adyen/AdyenContext'
import useMediaQuery from '@shared/hooks/useMediaQuery'
import * as S from './styled'
import { useDiscounts } from '@shared/hooks/useDiscounts'
import { useEffect } from 'react'
import services from '@Services/index'
import { useRouter } from 'next/router'
import { useCart } from '@shared/hooks/useCart'
import { useModal } from '@shared/hooks/useModal'
import schemas from '@Schemas/index'
import { useTickets } from '@shared/hooks/useTickets'
import { usePaymentMethods } from '@shared/hooks/usePaymentMethods'
import { useBomboniere } from '@shared/hooks/useBomboniere'
import { useTheme } from 'styled-components'
import { usePage } from '@shared/hooks/usePage'
import { PAGE_NAME_WAIT_PAYMENT } from '@Constants/page'
import { useWebView } from '@shared/hooks/useWebView'

const paymentTypes = [
  { name: 'debitcard', label: 'Cartão de débito' },
  { name: 'creditcard', label: 'Cartão de crédito' },
  { name: 'googlepay', label: 'GooglePay' },
  { name: 'applepay', label: 'ApplePay' },
  { name: 'pix', label: 'Pix' },
  { name: 'nupay', label: 'NuPay' }
]

export const ResumeFooter = () => {
  const { hasDiferentsTypesTickets } = useTickets()
  const { discounts, onSetDiscounts } = useDiscounts()
  const { seats } = useSeats()
  const { sessionData } = useSessionData()
  const { bomboniere } = useBomboniere()
  const { cardValue } = useAdyenCheckoutContext()
  const { page } = usePage()
  const { closeCheckout, isMobileApp } = useWebView()

  const {
    getTotalPriceFromCart,
    getTotalSelectedTickets,
    getTotalService,
    getTotalTax,
    getTotalSelectedBomboniere
  } = CheckoutUtils()
  const { paymentMethodCurrent } = usePaymentMethods()
  const isMobile = useMediaQuery('(max-width: 991px)')
  const haveDiscountClubUol = sessionData?.discounts?.find(
    (discount) => discount.type === 'ClubUol'
  )
  useEffect(() => {
    const hasClubInState = discounts?.find(
      (discount) => discount.type === 'ClubUol'
    )
    if (haveDiscountClubUol?.id && !hasClubInState?.id) {
      onSetDiscounts(haveDiscountClubUol)
    }
  }, [])
  const totalFromTickets = getTotalSelectedTickets()
  const totalFromBomboniere = getTotalSelectedBomboniere()
  const cardType = cardValue?.cardType?.toLowerCase()
  const paymentSelected = []
  const router = useRouter()
  const { onSetIsOpenModal } = useModal()
  const { cart } = useCart()
  const theme = useTheme()
  cardType && paymentSelected.push(paymentMethodCurrent)
  discounts?.length && paymentSelected.push('giftcard')

  const payments = paymentTypes.filter((paymentType) =>
    paymentSelected.some((paymentMethod) => paymentType.name === paymentMethod)
  )
  const seatSelected = seats?.selected
    .map((seat) => {
      return seat.label
    })
    .join(', ')
  const ticketSelected =
    sessionData?.selectedTickets &&
    sessionData?.selectedTickets.map((item) => (
      <S.ResumeContainer key={item.id}>
        <S.ResumeContent>
          <span id="qtt_ticket">{item?.quantity}x</span>
          <span id="tp_ticket">
            {item?.name}
            {hasDiferentsTypesTickets() &&
              ` - ${
                item.type === 'Default' ? 'Simples' : item.typeDescription
              }`}
          </span>
        </S.ResumeContent>
        <span id="value_ticket">
          {formatCurrencyValue(item?.unitPrice * item?.quantity)}
        </span>
      </S.ResumeContainer>
    ))

  const bomboniereSelected =
    sessionData?.selectedBomboniere &&
    sessionData?.selectedBomboniere.map((category) => {
      return category.products.map((product, idx) => {
        if (product.quantity > 0) {
          return (
            <S.ResumeContainer key={product.id + idx}>
              <S.ResumeHeader>
                <S.ResumeBody>
                  <S.ResumeContent>
                    <span id="qtt_product">{product?.quantity + 'x'}</span>
                    <span id="tp_product" style={{ marginLeft: '5px' }}>
                      {product?.name[0].toLocaleUpperCase() +
                        product?.name.slice(1).toLocaleLowerCase()}
                    </span>
                  </S.ResumeContent>

                  <span id="value_product">
                    {formatCurrencyValue(
                      (product?.price + product?.service) * product?.quantity
                    )}
                  </span>
                </S.ResumeBody>
                <ol id="tp_product">
                  {product?.items?.map((item, idx) => {
                    return (
                      <S.ComboProducts key={item.id + idx}>
                        {item.quantity * product?.quantity + ' x '}
                        {item?.name[0].toLocaleUpperCase() +
                          item.name.slice(1).toLocaleLowerCase()}
                      </S.ComboProducts>
                    )
                  })}
                </ol>
                {product.tag && (
                  <S.Promotion>
                    Produto vinculado ao ingresso {product.tag}
                  </S.Promotion>
                )}
              </S.ResumeHeader>
            </S.ResumeContainer>
          )
        }
      })
    })

  const allQuantities = bomboniere?.selected?.reduce((acc, category) => {
    const total = category?.products.reduce((acc, product) => {
      return acc + product.quantity
    }, 0)
    return acc + total
  }, 0)

  const hasTax = sessionData?.selectedTickets?.some((ticket) => ticket.tax > 0)

  const discountsApplied = discounts?.map((discount) => (
    <S.ResumeTotalContent key={discount.id}>
      <span>
        {' '}
        {discount.password
          ? `Cupom ${discount.password}`
          : 'Desconto Clube Uol'}
      </span>
      <S.GiftValue>
        {discount.value > totalFromTickets + totalFromBomboniere
          ? formatCurrencyValue(totalFromTickets + totalFromBomboniere)
          : formatCurrencyValue(discount.value)}
      </S.GiftValue>
    </S.ResumeTotalContent>
  ))
  const handleOpenRemoveSession = () => {
    onSetIsOpenModal({
      ...schemas.modal.SESSION_REMOVE_SESSION,
      onConfirm: async () => {
        handleOnConfirm()
      },
      onClose: () => {
        onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
      }
    })
  }
  const handleOnConfirm = async () => {
    const [,] = await services.carts.deleteSession(cart?.id, cart?.sessionId)
    if (isMobileApp) closeCheckout()
    router.push(
      theme.data?.isPoweredBy ? theme.data?.url : process.env.baseUrlCatalog
    )
  }

  return (
    <S.ResumeWrapper data-testid="resume-element">
      <S.WrapperContent>
        <S.Header>
          {isMobile ? (
            <>
              <S.ResumeIconWrp data-testid="button-close">
                <Icon
                  role="img"
                  src="icons/arrow"
                  width={24}
                  height={24}
                  className="icon-resume"
                />
              </S.ResumeIconWrp>

              <h1>Resumo</h1>
            </>
          ) : (
            <>
              <h1>Resumo do pedido</h1>
              {page !== PAGE_NAME_WAIT_PAYMENT && (
                <Icon
                  role="img"
                  src="icons/trash"
                  className="icon-resume"
                  width={18}
                  height={20}
                  onClick={handleOpenRemoveSession}
                />
              )}
            </>
          )}
        </S.Header>
        <S.SessionWrapper>
          <SessionContainer
            movieTitle={sessionData?.titleMovie}
            moviePlace={sessionData?.moviePlace}
            movieSession={sessionData?.movieSession}
            movieBanner={sessionData?.session?.images[0].url}
            theatreCity={sessionData?.session?.theatre?.city}
            sessionTime={sessionData?.sessionTime?.substring(11, 16)}
            sessionDate={sessionData?.session?.date}
            contentRating={sessionData?.session?.event?.rating}
            hideSessionDate={sessionData?.session?.event?.hideSessionDate}
            typeDescription={sessionData?.typeDescription}
            subtitles={sessionData?.subtitles}
            isMobile={isMobile}
          />
        </S.SessionWrapper>
        {seatSelected && (
          <S.ResumeLine>
            <S.ResumeContainer>
              <h2>Assentos</h2>
              <S.ResumeContent>
                <strong>{seatSelected}</strong>
              </S.ResumeContent>
            </S.ResumeContainer>
          </S.ResumeLine>
        )}
        {ticketSelected?.length ? (
          <S.ResumeLine>
            <h2>Ingressos</h2>
            {ticketSelected}
          </S.ResumeLine>
        ) : null}
        {bomboniereSelected?.length ? (
          <S.ResumeLine>
            <h2>Produtos</h2>
            {bomboniereSelected}
          </S.ResumeLine>
        ) : null}
        {payments.length ? (
          <S.ResumeLine>
            <S.ResumeContainer>
              <h2>Pagamento</h2>
              <S.ResumePayment>
                {payments.map((payment) => (
                  <p key={payment.name}>{payment.label}</p>
                ))}
              </S.ResumePayment>
            </S.ResumeContainer>
          </S.ResumeLine>
        ) : null}
      </S.WrapperContent>
      <S.ResumeLine>
        <S.ResumeTotalContainer>
          {sessionData?.hasSeatSelection && (
            <S.ResumeTotalContent>
              <span>Itens</span>
              <span>
                {allQuantities
                  ? seats?.selected?.length + allQuantities
                  : seats?.selected?.length}
              </span>
            </S.ResumeTotalContent>
          )}
          {discounts?.length ? discountsApplied : null}
          <S.ResumeTotalContent>
            <span>Total taxa</span>
            <span>{formatCurrencyValue(getTotalService())}</span>
          </S.ResumeTotalContent>
          {hasTax && (
            <S.ResumeTotalContent>
              <span>Direito Autoral</span>
              <span>{formatCurrencyValue(getTotalTax())}</span>
            </S.ResumeTotalContent>
          )}
          <S.ResumeTotalContent>
            <strong>Total</strong>
            <S.TotalOrder>
              {discounts?.length ? (
                <span>
                  {formatCurrencyValue(totalFromTickets + totalFromBomboniere)}
                </span>
              ) : null}
              <strong>{formatCurrencyValue(getTotalPriceFromCart())}</strong>
            </S.TotalOrder>
          </S.ResumeTotalContent>
        </S.ResumeTotalContainer>
      </S.ResumeLine>
    </S.ResumeWrapper>
  )
}
