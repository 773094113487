import Image from '@Components/Image'
import ParentalRating from '@Components/ParentalRating'
import * as S from './styled'
import SessionInfo from '@Components/SessionInfo'

interface ISessionProps {
  movieTitle: string
  moviePlace: string
  movieSession: string
  movieBanner?: string
  theatreCity: string
  sessionTime: string
  contentRating: string
  hideSessionDate: boolean
  sessionDate?: string
  isMobile?: boolean
  typeDescription: []
  subtitles: string
}

export default function SessionContainer({
  movieTitle,
  moviePlace,
  movieSession,
  movieBanner,
  theatreCity,
  sessionTime,
  contentRating,
  hideSessionDate,
  sessionDate,
  typeDescription,
  subtitles,
  isMobile
}: ISessionProps) {
  const sessionInfo = () => {
    const tags: string[] = typeDescription
    if (tags) {
      return (
        <S.MovieSessionContainer>
          <ParentalRating rating={contentRating} size="18px" />
          {tags.map((type, index) => (
            <S.SessionTags key={index}>{type}</S.SessionTags>
          ))}
          {subtitles && <S.SessionTags>{subtitles}</S.SessionTags>}
        </S.MovieSessionContainer>
      )
    }

    return null
  }

  const eventDate = sessionDate
    ? new Date(sessionDate).toLocaleDateString('pt-BR', {
        weekday: 'short',
        month: 'numeric',
        day: 'numeric'
      })
    : ''

  const [eventWeekDay, eventDayMonth] = eventDate.split(',')

  return (
    <div>
      <S.ContainerSession>
        <S.MovieBanner>
          <Image
            src={movieBanner}
            alt={movieTitle}
            width="84"
            height="120"
            callbackImg="https://ingresso-a.akamaihd.net/ingresso/checkout/images/background/placeholder-movie-image.jpg"
          />
        </S.MovieBanner>
        <S.MovieWrapper data-testid="session-container">
          <S.Title>
            <strong>{movieTitle}</strong>
          </S.Title>
          {!hideSessionDate && (
            <div>
              <S.MovieInfo>
                {sessionInfo()}
                <S.EventLocation>
                  <S.Theater>{moviePlace}</S.Theater>
                  {isMobile && !hideSessionDate && <p>{' - '}</p>}
                  <S.City>{!hideSessionDate && theatreCity}</S.City>
                </S.EventLocation>
              </S.MovieInfo>
              {!isMobile && <SessionInfo />}
            </div>
          )}
        </S.MovieWrapper>
      </S.ContainerSession>
      {!hideSessionDate && (
        <S.SessionDateTime>
          <span>{movieSession?.toUpperCase()}</span>
          <S.EventDate>
            {sessionDate && (
              <div className="sessionDate">
                <span className="date">{`${eventWeekDay
                  .slice(0, -1)
                  .toUpperCase()}  ${eventDayMonth}`}</span>
              </div>
            )}
            <div className="sessionTime">
              {isMobile && '- '}
              <S.Time className="time">{sessionTime}</S.Time>
            </div>
          </S.EventDate>
        </S.SessionDateTime>
      )}
    </div>
  )
}
