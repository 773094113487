import { useLoading } from './useLoading'
import { useModal } from './useModal'
import schemas from '@Schemas/index'
import { useCart } from './useCart'
import services from '@Services/index'
import { clarity } from 'react-microsoft-clarity'

import { useWebView } from './useWebView'
import { useCookies } from './useCookies'
import { useCheckout } from './useCheckout'
import { useCartIsReady } from './useCartIsReady'

export function useCreateOrder() {
  const { goSeatsOnNotAvailableSeatError, cart, goConcluded } = useCart()
  const { isIOS, checkoutApplePayWasError, isMobileApp, goToConfirmed } =
    useWebView()
  const { onSetGlobalLoading } = useCheckout()
  const { onSetCartIsReady } = useCartIsReady()

  const { onSetIsOpenModal } = useModal()
  const { onSetIsLoading, onSetIsLoadingButton } = useLoading()
  const { restartSession } = useCart()
  const { getCookie } = useCookies()
  const createOrder = async (isApplePay?: boolean) => {
    console.log(cart, 'cartData no create order')
    console.log('chamei a create order')
    try {
      try {
        clarity.setTag('cart', cart.id)
        clarity.setTag('session', cart.sessionId)
      } catch (error) {
        console.log(error)
      }

      onSetIsLoading(true)
      onSetIsLoadingButton(true)

      const [data, error] = await services.payments.order({
        cartId: cart.id,
        fingerprint: getCookie(process.env.COOKIE_FINGERPRINT) ?? ''
      })
      console.log('chamei a create order', data, error)
      if (error?.modelState?.errorSeatSelected) {
        onSetIsLoading(false)
        onSetIsLoadingButton(false)
        return onSetIsOpenModal({
          ...schemas.modal.PAYMENT_ERROR,
          description: error?.modelState?.errorSeatSelected[0],
          onClose: () => {
            onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
            goSeatsOnNotAvailableSeatError()
          }
        })
      } else if (error?.modelState?.productConsumeError) {
        onSetIsLoading(false)
        onSetIsLoadingButton(false)
        return onSetIsOpenModal({
          ...schemas.modal.PAYMENT_ERROR,
          description: error?.modelState?.productConsumeError[0],
          onClose: () => {
            onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
          }
        })
      } else if (error?.modelState?.errorSeatTimeout) {
        onSetIsLoading(false)
        onSetIsLoadingButton(false)
        return onSetIsOpenModal({
          ...schemas.modal.PAYMENT_ERROR,
          description: error?.modelState?.errorSeatTimeout[0],
          onClose: () => {
            onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
          }
        })
      } else if (error?.modelState?.orderError) {
        onSetIsLoading(false)
        onSetIsLoadingButton(false)
        return onSetIsOpenModal({
          ...schemas.modal.PAYMENT_ERROR,
          description: error?.modelState?.orderError[0],
          onClose: () => {
            onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
          }
        })
      } else if (error?.status === 404) {
        return onSetIsOpenModal({
          ...schemas.modal.PURCHASE_TIME_EXPIRED,
          onConfirm: () => {
            restartSession()
            onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
          },
          onClose: () => {
            restartSession()
            onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
          }
        })
      } else if (error) {
        onSetIsLoading(false)
        onSetIsLoadingButton(false)
        return onSetIsOpenModal({
          ...schemas.modal.PAYMENT_ERROR,
          onClose: () => {
            onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
          }
        })
      }

      try {
        clarity.setTag('order', data?.id)
      } catch (error) {
        console.log(error)
      }

      onSetCartIsReady(false)
      onSetGlobalLoading(true)
      if (isMobileApp()) {
        console.log('cai no if do isMobileApp')
        goToConfirmed(data)
      } else {
        console.log('cai no else do goConcluded', data)
        goConcluded(data.id)
      }
    } catch (error) {
      console.log('erro na create order', error)

      if (isApplePay && isIOS()) checkoutApplePayWasError(error)
      return error
    }
  }
  return {
    createOrder
  }
}
