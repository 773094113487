import styled, { css, keyframes } from 'styled-components'

const loadingAnimation = keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
`

export const LoadingContainer = styled.div<{ variant?: string }>`
  width: 24px;
  height: 24px;
  display: inline-block;
  overflow: hidden;
  background: none;
  position: relative;
  ${({ variant }) =>
    variant === 'global' &&
    css`
      width: 60px;
      height: 60px;
    `};
`
export const LoadingSpinItem = styled.div<{ variant: string }>`
  position: absolute;
  width: 100%;
  height: 100%;
  border: 4px solid
    ${({ variant }) => (variant === 'outlined' ? '#fff' : '#000')};
  border-top-color: ${({ variant, theme }) =>
    variant === 'outlined' ? theme.button.primary.bgColor : '#fff'};
  border-radius: 50%;
  ${({ variant }) =>
    variant === 'global' &&
    css`
      border: 8px solid #8899aa;
      border-top-color: #ffce00;
    `};
  animation: ${loadingAnimation} 1.5s linear infinite;
`
