import styled from 'styled-components'

export const SLoading = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  padding: 20px;
  justify-content: center;
  margin: 0 auto;
  background-color: rgba(4, 18, 24, 0.8);
  width: 100%;
  height: 100%;
  z-index: 1000;
`
