import {
  PAGE_NAME_BOMBONIERE,
  PAGE_NAME_CONCLUDED,
  PAGE_NAME_PAYMENTS,
  PAGE_NAME_SEATS,
  PAGE_NAME_TICKETS
} from '@Constants/page'
import { useCart } from '@shared/hooks/useCart'
import { useModal } from '@shared/hooks/useModal'
import { usePage } from '@shared/hooks/usePage'
import { useSeats } from '@shared/hooks/useSeats'
import { useSessionData } from '@shared/hooks/useSessionData'
import { useTickets } from '@shared/hooks/useTickets'
import { UseTracking } from '@shared/hooks/useTracking'
import schemas from '@Schemas/index'
import { useFidelity } from '@shared/hooks/useFidelity'
import services from '@shared/services'
import { useBomboniere } from '@shared/hooks/useBomboniere'
import { useLogin } from '@shared/hooks/useLogin'
import { useAuthToken } from '@shared/hooks/useAuthToken'
import { useLoading } from '@shared/hooks/useLoading'
import { usePayment } from '@shared/hooks/usePayment'
import {
  getValidFunctionByDescriptionField,
  removeMaskCpf
} from '@Helpers/others'
import { IFidelity, IFieldFidelity } from '@shared/context/interface'
import { usePaymentMethods } from '@shared/hooks/usePaymentMethods'
import { useWebViewWithTracking } from './useWebViewWithTracking'

export function useNavigation() {
  const { sessionData } = useSessionData()
  const { page } = usePage()
  const { cart, goTickets, goSeats, goBomboniere, goPayments, restartSession } =
    useCart()
  const { seats } = useSeats()
  const { tickets, onSetTickets } = useTickets()
  const {
    trackSeatMapNextBtn,
    trackTicketTypeNextBtn,
    trackPaymentNextBtn,
    trackBombNextBtn
  } = UseTracking()
  const { trackPaymentNextBtnApp } = useWebViewWithTracking()
  const { trackSeatMapNextBtnApp } = useWebViewWithTracking()
  const { onSetIsOpenModal } = useModal()
  const { fidelity, onSetFidelityFields, fidelityFields, sameCpfFidelity } =
    useFidelity()
  const { bomboniere } = useBomboniere()
  const { onSetIsOpenLogin } = useLogin()
  const { authToken } = useAuthToken()
  const { onSetIsLoading, onSetIsLoadingButton } = useLoading()
  const { initPayment } = usePayment()
  const { paymentMethodCurrent } = usePaymentMethods()

  const hasBomboniere = sessionData?.hasBomboniereActive

  const fidelityType = () => {
    if (sessionData?.session?.promotion.kinefilos) {
      return 'Kinefilos'
    }
    if (sessionData?.session?.promotion.popcornClub) {
      return 'PopcornClub'
    }
    if (sessionData?.session?.promotion.movieClub) {
      return 'MovieClub'
    }
  }
  const validateFieldsTickets = () => {
    const updatedTickets = [...sessionData.selectedTickets]
    updatedTickets.map((ticket) => {
      if (ticket.hasValidation) {
        ticket.fields = ticket.fields.map((field) => {
          if (field.document.length === 0 || field.document === '') {
            field.activeError = true
            field.typeStopInteraction = 'error'
            field.errorMessage = field.description
              ? field.description + ' é obrigatório.'
              : 'Esse campo é obrigatório.'

            return field
          } else if (
            !getValidFunctionByDescriptionField(field)(field.document)
          ) {
            field.activeError = true
            field.typeStopInteraction = 'error'
            field.errorMessage = field.description + ' inválido.'
            return field
          }
          return field
        })
      }
      return ticket
    })
    onSetTickets({ selected: updatedTickets, ...tickets })
  }

  const validateFidelityFields = () => {
    let fidelityFieldsUpdated = null
    if (fidelity) {
      if (sameCpfFidelity) {
        const valueFirstInput = tickets.selected[0].fields[0].promotion.document
        fidelityFieldsUpdated = fidelityFields.map(
          (fidelityField: IFidelity, indexFidelity) => {
            fidelityField.fields.map((field, indexField) => {
              if (indexFidelity === 0 && indexField === 0) {
                if (valueFirstInput === '') {
                  field.activeError = true
                  field.typeStopInteraction = 'error'
                  field.errorMessage = field.description
                    ? 'O Campo ' + field.description + ' é obrigatório.'
                    : 'Campo é obrigatório.'
                }
                if (
                  !getValidFunctionByDescriptionField(field)(valueFirstInput)
                ) {
                  field.activeError = true
                  field.typeStopInteraction = 'error'
                  field.errorMessage = field.description
                    ? field.description + ' inválido.'
                    : 'Campo é obrigatório.'
                }
                if (
                  getValidFunctionByDescriptionField(field)(valueFirstInput)
                ) {
                  field.activeError = false
                  field.typeStopInteraction = 'success'
                  field.errorMessage = ''
                }
              }
              return field
            })

            return fidelityField
          }
        )
      } else {
        fidelityFieldsUpdated = fidelityFields.map(
          (fidelityField: IFidelity) => {
            fidelityField.fields.map((field: IFieldFidelity) => {
              if (field.promotion.document === '') {
                field.activeError = true
                field.typeStopInteraction = 'error'
                field.errorMessage = field.description
                  ? 'O Campo ' + field.description + ' é obrigatório.'
                  : 'Campo  obrigatório.'
              } else if (
                !getValidFunctionByDescriptionField(field)(
                  field.promotion.document
                )
              ) {
                field.activeError = true
                field.typeStopInteraction = 'error'
                field.errorMessage = field.description
                  ? field.description + ' inválido.'
                  : 'Campo inválido.'
              } else if (
                getValidFunctionByDescriptionField(field)(
                  field.promotion.document
                )
              ) {
                field.activeError = false
                field.typeStopInteraction = 'success'
                field.errorMessage = ''
              }

              return field
            })
            return fidelityField
          }
        )
      }
      onSetFidelityFields(fidelityFieldsUpdated)
    }
    return true
  }

  const VerifyAllFieldsTicketValid = () => {
    let allfieldsValid = true
    for (let i = 0; i < tickets?.selected?.length; i++) {
      const fields = tickets?.selected[i].fields
      for (let j = 0; j < fields.length; j++) {
        if (fields[j].activeError) {
          allfieldsValid = false
        }
      }
    }
    if (fidelity && sameCpfFidelity) {
      for (let i = 0; i < fidelityFields?.length; i++) {
        const fields = fidelityFields[i].fields
        if (fields[0].activeError) {
          allfieldsValid = false
        }
      }
    } else if (fidelity) {
      for (let i = 0; i < fidelityFields?.length; i++) {
        const fields = fidelityFields[i].fields
        for (let j = 0; j < fields.length; j++) {
          if (fields[j].activeError) {
            allfieldsValid = false
          }
        }
      }
    }
    return allfieldsValid
  }

  const addMyTickets = async () => {
    validateFieldsTickets()
    validateFidelityFields()
    const selectedTickets = []
    if (VerifyAllFieldsTicketValid()) {
      tickets.selected.map((selected) => {
        selectedTickets.push(
          ...Array.from({ length: selected.quantity }, (_, i) => {
            return {
              typeId: selected.id,
              seatType: selected.type || 'Default',
              document: selected.hasValidation
                ? removeMaskCpf(selected.fields[i]?.document)
                : '',
              promotion: fidelity
                ? {
                    type:
                      selected?.fields[i]?.promotion?.type ?? fidelityType(),
                    id: 0,
                    document: removeMaskCpf(
                      selected?.fields[i]?.promotion?.document
                    )
                  }
                : {
                    type: '',
                    id: 0,
                    document: ''
                  }
            }
          })
        )
      })
      const [data, error] = await services.carts.addTickets(
        cart.id,
        cart?.sessionId,
        cart.sectionId,
        selectedTickets
      )
      if (!data) {
        if (
          error?.modelState?.ticketError
            ? error?.modelState?.ticketError[0]?.includes(
                'O tempo para finalizar a compra se excedeu'
              )
            : error?.modelState?.cartError[0]?.includes(
                'O tempo para finalizar a compra se excedeu'
              )
        ) {
          onSetIsOpenModal({
            ...schemas.modal.PURCHASE_TIME_EXPIRED,
            onConfirm: () => {
              restartSession()
              onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
            },
            onClose: () => {
              onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
            }
          })
          return false
        } else if (error?.modelState?.ticketError) {
          onSetIsOpenModal({
            ...schemas.modal.TICKET_VALIDATION_ERROR(
              error?.modelState?.ticketError[0]
            ),
            onConfirm: () => {
              onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
            },
            onClose: () => {
              onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
            }
          })
          return false
        } else if (error) {
          const isData = error?.modelState
          if (isData)
            onSetIsOpenModal({
              ...schemas.modal.TICKET_VALIDATION_ERROR(
                error?.modelState?.ticketError[0]
              ),
              onConfirm: () => {
                onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
              },
              onClose: () => {
                onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
              }
            })
          else
            onSetIsOpenModal({
              ...schemas.modal.ADD_TICKET_CART_ERROR,
              onConfirm: () => {
                restartSession()
                onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
              },
              onClose: () => {
                onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
              }
            })
          return false
        }
      }
      return true
    } else {
      onSetIsOpenModal({
        ...schemas.modal.ADD_TICKETS_ERROR,
        onClose: () => {
          onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
        },
        onConfirm: () => {
          onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
        }
      })
      return false
    }
  }

  const handleAddTicketsGoNextPage = async () => {
    const noHaveErrorInAddTickets = await addMyTickets()
    if (VerifyAllFieldsTicketValid() && noHaveErrorInAddTickets) {
      await trackTicketTypeNextBtn({
        event: 'gaEvent',
        e_event: 'click_next',
        e_action: tickets.totalSelected,
        e_label: '',
        e_description: ''
      })
      return true
    }
    return false
  }

  const onCheckLogin = () => {
    if (!authToken?.access_token) {
      onSetIsOpenLogin({ show: true, onNextPage: true })
    } else {
      onSetIsLoading(true)
      goPayments()
    }
  }
  const qtdTicket = tickets?.selected?.reduce(
    (acc, ticket) => acc + ticket.quantity,
    0
  )

  const enablePaymentPage = sessionData?.hasSeatSelection
    ? tickets?.selected?.length > 0 &&
      seats.selected.length > 0 &&
      qtdTicket === seats.selected.length &&
      authToken?.id
    : tickets?.selected?.length > 0 && authToken?.id

  const limitTicketsSelected = sessionData?.hasSeatSelection
    ? tickets?.totalSelected === tickets?.limit
    : tickets.totalSelected > 0
  const steps = {
    [`${PAGE_NAME_SEATS}`]: {
      enabled: sessionData?.hasSeatSelection,
      enabledTab: sessionData?.hasSeatSelection,
      index: 0,
      icon: 'icons/seat',
      label: 'Avançar',
      shouldShowTab: sessionData?.hasSeatSelection ?? false,
      disabled: sessionData?.hasSeatSelection ?? false,
      disabledResume: sessionData?.hasSeatSelection ?? false,
      onClick: () => {
        goSeats(cart?.sessionId)
      }
    },
    [`${PAGE_NAME_TICKETS}`]: {
      index: 1,
      enabled: seats?.selected?.length || !sessionData?.hasSeatSelection,
      enabledTab: seats?.selected?.length || !sessionData?.hasSeatSelection,
      icon: 'icons/ticket',
      label: 'Escolher Ingressos',
      shouldShowTab: true,
      disabled: sessionData?.hasSeatSelection
        ? seats?.selected?.length <= 0
        : !limitTicketsSelected,
      disabledResume: sessionData?.hasSeatSelection
        ? seats?.selected?.length <= 0
        : !limitTicketsSelected,
      onClick: async () => {
        onSetIsLoadingButton(true)
        trackSeatMapNextBtn(seats)
        trackSeatMapNextBtnApp(seats)
        await goTickets(page, cart?.sessionId, cart.sectionId)
      }
    },
    [`${PAGE_NAME_BOMBONIERE}`]: {
      index: 2,
      enabled:
        (sessionData.selectedTickets?.length > 0 &&
          sessionData.hasBomboniereActive) ??
        false,
      enabledTab:
        (sessionData.selectedTickets?.length > 0 &&
          sessionData.hasBomboniereActive) ??
        false,
      shouldShowTab: sessionData.hasBomboniereActive,
      icon: 'icons/popcorn',
      label: 'Ir para Pipoca',
      disabled: false,
      disabledResume: false,
      onClick: async () => {
        onSetIsLoadingButton(true)
        if (sessionData?.hasSeatSelection) {
          const quantityTickets = sessionData?.selectedTickets.reduce(
            (acc, ticket) => acc + ticket.quantity,
            0
          )
          if (quantityTickets < seats?.selected?.length ) {
            onSetIsOpenModal({
              ...schemas.modal.QUANTITY_TICKETS_ERROR,
              onClose: () => {
                onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
              },
              onConfirm: () => {
                onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
              }
            })
            onSetIsLoadingButton(false)
            return
          }
        }

        if (
          sessionData?.selectedTickets?.length < 1 &&
          sessionData.hasSeatSelection
        ) {
          onSetIsOpenModal({
            ...schemas.modal.AT_LEAST_ONE_TICKET_ERROR,
            onClose: () => {
              onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
            },
            onConfirm: () => {
              onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
            }
          })
          onSetIsLoadingButton(false)
          return
        } else if (
          sessionData?.selectedTickets?.length < 1 &&
          !sessionData.hasSeatSelection
        ) {
          onSetIsOpenModal({
            ...schemas.modal.TICKETS_SELECT_SOME_TICKET,
            onClose: () => {
              onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
            },
            onConfirm: () => {
              onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
            }
          })
          onSetIsLoadingButton(false)
          return
        }
        if (page === PAGE_NAME_TICKETS) {
          const validNextPage = await handleAddTicketsGoNextPage()
          if (validNextPage) await goBomboniere(cart?.sessionId)
        } else {
          await goBomboniere(cart?.sessionId)
        }
        onSetIsLoadingButton(false)
      }
    },
    [`${PAGE_NAME_PAYMENTS}`]: {
      index: 3,
      icon: 'icons/card',
      label:
        page === PAGE_NAME_BOMBONIERE &&
        sessionData.selectedBomboniere?.length === 0
          ? 'Pular'
          : 'Ir para Pagamento',
      enabledTab: enablePaymentPage,
      enabled: sessionData.selectedTickets?.length > 0,
      disabled: false,
      shouldShowTab: true,
      disabledResume: false,
      onClick: async () => {
        onSetIsLoadingButton(true)
        if (sessionData?.hasSeatSelection) {
          const quantityTickets = sessionData?.selectedTickets.reduce(
            (acc, ticket) => acc + ticket.quantity,
            0
          )

          if (quantityTickets < seats?.selected?.length) {
            onSetIsOpenModal({
              ...schemas.modal.QUANTITY_TICKETS_ERROR,
              onClose: () => {
                onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
              },
              onConfirm: () => {
                onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
              }
            })
            onSetIsLoadingButton(false)
            return
          }
          if (!limitTicketsSelected) {
            onSetIsLoadingButton(false)
          }
        } else if (
          sessionData?.selectedTickets?.length < 1 &&
          !sessionData.hasSeatSelection
        ) {
          onSetIsOpenModal({
            ...schemas.modal.TICKETS_SELECT_SOME_TICKET,
            onClose: () => {
              onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
            },
            onConfirm: () => {
              onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
            }
          })
          onSetIsLoadingButton(false)
          return
        }

        trackBombNextBtn({
          event: 'gaEvent',
          e_event: 'click_next',
          e_action: '',
          e_label: '',
          e_description: ''
        })

        if (hasBomboniere && page !== PAGE_NAME_TICKETS) {
          const payload = []
          bomboniere?.selected?.map((category) => {
            category.products.filter((product) => {
              if (product.quantity > 0) {
                payload.push({
                  ...product,
                  theaterId: Number(sessionData.session.theatre.id)
                })
              }
            })
          })

          await services.bomboniere.postProducts(
            cart?.sessionId,
            cart?.id,
            payload
          )
          await onCheckLogin()
          onSetIsLoadingButton(false)
        } else {
          const validNextPage = await handleAddTicketsGoNextPage()
          if (validNextPage) await onCheckLogin()
          onSetIsLoadingButton(false)
        }
      }
    },

    [`${PAGE_NAME_CONCLUDED}`]: {
      index: 4,
      enabled: true,
      disabled: false,
      icon: 'icons/conclued',
      label: paymentMethodCurrent !== 'nupay' ? 'Pagar' : 'Pagar com Nubank',
      disabledResume: false,
      shouldShowTab: true,
      onClick: async () => {
        await trackPaymentNextBtn({
          event: 'gaEvent',
          e_event: 'click_next',
          e_action: paymentMethodCurrent,
          e_label: '',
          e_description: ''
        })
        await trackPaymentNextBtnApp({
          event: 'gaEvent',
          e_event: 'click_next',
          e_action: paymentMethodCurrent,
          e_label: '',
          e_description: ''
        })
        await initPayment()
      }
    }
  }

  return { steps }
}
