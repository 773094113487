export const TICKETING_HOST = process.env.baseUrlApiTicketing

export const SESSIONS_SECTIONS_TICKETS = {
  get: (sessionId: string | string[], sectionId: string) => {
    return `${TICKETING_HOST}/sessions/${sessionId}/sections/${sectionId}/tickets`
  }
}

export const CARTS_SESSIONS_SECTIONS_TICKETS = {
  get: (cartId: string, sessionId: string, sectionId: string) => {
    return `${TICKETING_HOST}/carts/${cartId}/sessions/${sessionId}/sections/${sectionId}/tickets`
  }
}
