import styled from 'styled-components'

export const WrapperContent = styled.div``

export const Header = styled.div`
  color: ${({ theme }) => theme.summary.header.color};
  font-size: 14px;
  letter-spacing: 1px;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 8px;

  @media (min-width: 992px) {
    font-size: 16px;
    margin-bottom: 16px;
    justify-content: space-between;

    svg {
      fill: #0099ff;
      cursor: pointer;

      :hover {
        opacity: 0.8;
      }
    }
  }
`

export const ResumeIconWrp = styled.span`
  display: flex;
  flex-direction: row;
  margin-right: 16px;

  svg {
    fill: ${({ theme }) => theme.summary.icons.secondary};
    transform: rotate(180deg);
  }
`

export const SessionWrapper = styled.div`
  padding-bottom: 8px;

  div {
    padding: 0;
    border: none;

    p {
      color: #999;
    }
  }
`

export const ResumeWrapper = styled.div`
  width: 100%;
  line-height: 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${(props) => props.theme?.footer.bgColor};

  @media (min-width: 992px) {
    background-color: ${({ theme }) => theme.seats.screenSeatMap.bgColor};
    padding: 16px 16px 8px;
    border-radius: 4px;
    max-width: 300px;
  }
`

export const ResumeLine = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-top: 1px solid ${({ theme }) => theme.summary.tab.color.disabled};
  padding: 8px 0;
  color: ${({ theme }) => theme.summary.color.primary};
  font-size: 12px;

  @media (min-width: 992px) {
    font-size: 14px;
  }
`
export const ResumeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;

  h2 {
    min-width: 100px;
  }
`

export const ResumeContent = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: right;
  #tp_ticket {
    margin-left: 4px;
    font-weight: 700;
    text-align: left;
  }

  color: ${({ theme }) => theme.global.secondary.color};
`

export const ResumeBody = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
export const ResumeHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const ResumePayment = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  gap: 8px;
`

export const Promotion = styled.span`
  display: flex;
  color: ${({ theme }) =>
    theme.data?.isPoweredBy ? theme.global.secondary.color : '#ffe270'};
`

export const ResumeTotalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const ResumeTotalContent = styled.div`
  color: #999;
  display: flex;
  justify-content: space-between;

  :last-child {
    color: ${({ theme }) => theme.summary.total?.color || '#fff'};
  }

  @media (min-width: 992px) {
    font-size: 16px;
  }
`

export const GiftValue = styled.span`
  :before {
    content: '-';
  }
`

export const TotalOrder = styled.div`
  display: flex;
  gap: 16px;

  span {
    color: ${({ theme }) => theme.summary.color.primary};
    text-decoration: line-through;
  }
  strong {
    color: ${({ theme }) => theme.global.placemark.color};
  }
`

export const ComboProducts = styled.li`
  font-size: 12px;
  color: #999999;
`
