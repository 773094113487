import styled from 'styled-components'

export const ContainerInput = styled.div`
  margin-bottom: 8px;
`
export const ContainerButton = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #ccc;
`
export const OtherButtonContent = styled.div`
  text-align: center;
  position: relative;
  display: inline-block;
  font-weight: bold;
  margin: 25px 0;

  h3 {
    text-align: center;
    color: #999;
  }

  &:after,
  :before {
    content: '';
    position: absolute;
    top: 50%;
    width: 40%;
    height: 0;
    border-bottom: 1px solid #ccc;
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }
`

export const HelpTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  flex-direction: row;
  color: #0099ff;
  margin: 8px 0px 16px 0px;
`
export const LoginSubTitle = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  text-align: center;
  padding: 20px 0;

  .help-subtitle {
    line-height: 16px;
    text-align: start;
    color: #ccc;
  }
`
export const ContainerHelp = styled.span`
  display: flex;
  align-items: baseline;
  color: #ccc;
  text-align: start;
  font-size: 14px;
  margin: 12px 0;
`
export const Link = styled.a`
  display: inline-block;
  color: #0099ff;
  @media (max-width: 992px) {
    margin-left: 4px;
  }
`
export const radioContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  max-width: 100%;
  color: #ccc;
`

export const radioItem = styled.div`
  margin: 10px 0;
  width: 100%;
`
