import styled from 'styled-components'

import { IIcon } from './interface'

export const SIcon = styled.span<IIcon>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0;
  height: ${(props) =>
    props.customHeightContainerIcon
      ? props.customHeightContainerIcon
      : `${props.height}${props.unit}`};
  width: ${(props) => `${props.width}${props.unit}`};
  &.guest-icon {
    transform: rotate(-90deg);
  }
  div,
  span {
    font-size: 0;
  }

  svg {
    fill: ${(props) =>
      props?.color
        ? props?.color
        : props.theme.ticketBox?.nameColor} !important;
    height: ${(props) => `${props.isIcon ? '100%' : 'auto'} `};
    width: ${(props) => `${props.isIcon ? '100%' : 'auto'} `};
    padding: ${(props) => `${props.bleed ? props.bleed : '0'} `};
  }
  &.icon-color-text {
    svg {
      fill: ${(props) =>
        props?.color
          ? props?.color
          : props.theme.ticketBox?.nameColor} !important;
      path {
        fill: ${(props) => (props?.color ? props?.color : 'inherit')};
      }
    }
  }
  &.iconPath {
    svg {
      fill: ${(props) =>
        props?.color
          ? props?.color
          : props.theme.global?.background?.color} !important;
      path {
        fill: ${(props) => (props?.color ? props?.color : 'inherit')};
      }
    }
  }
  &.close-icon,
  &.icon-resume,
  &.positionIcon,
  &.title-giftCard {
    svg {
      fill: ${(props) =>
        props?.theme.data?.isPoweredBy
          ? props.theme.global.secondary.color
          : props.theme.global?.link.color} !important;
      path {
        fill: ${(props) => (props?.color ? props?.color : 'inherit')};
      }
    }
  }
  &.iconHeader {
    svg {
      fill: ${(props) =>
        props?.color
          ? props?.color
          : props.theme.header?.icons.color} !important;
      path {
        fill: ${(props) => (props?.color ? props?.color : 'inherit')};
      }
    }
  }
  &.order-button-icon {
    svg path {
      fill: ${(props) => (props?.color ? props?.color : 'none')};
    }
  }
`
