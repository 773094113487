import { useEffect, useRef } from 'react'

import * as S from './styled'
import { useLogin } from '@shared/hooks/useLogin'

// components
import Icon from '@Components/Icon'
import Text from '@Components/Text'

// steps
import VerifyAccountStep from './VerifyAccountStep'
import NoHaveAccountStep from './NoHaveAccountStep'
import LoginAccountStep from './LoginAccountStep'
import LoginGuestStep from './LoginGuestStep'
import CreateAccountStepOne from './CreateAccountStepOne'
import CreateAccountStepTwo from './CreateAccountStepTwo'
import HelpStep from './HelpStep'

// recaptcha v2
import ReCAPTCHA from 'react-google-recaptcha'
import { useLoading } from '@shared/hooks/useLoading'
import { useCart } from '@shared/hooks/useCart'
import theme from '@Styles/theme'
import { useSessionData } from '@shared/hooks/useSessionData'
import { useWebView } from '@shared/hooks/useWebView'

const Login = () => {
  const {
    loginCurrentStep,
    loginStepPage,
    onSetCurrentLoginStepNumberAndPage,
    onSetIsOpenLogin,
    setEmailOrCpfValueContext,
    isFromLoginAccount,
    onSetIsFromLoginAccount
  } = useLogin()
  const recaptchaRef = useRef(null)
  const recaptchaRefV3 = useRef(null)
  const { isSuitMobile } = useWebView()
  const { goPayments } = useCart()
  const { onSetIsLoading } = useLoading()
  const { sessionData } = useSessionData()

  const CanGoPayment = () => {
    if (sessionData.selectedTickets.length > 0) {
      onSetIsLoading(true)
      goPayments()
    }
  }
  const handlePreviousStep = () => {
    if (loginCurrentStep.step === 2 && loginCurrentStep.stepPage === 2) {
      onSetCurrentLoginStepNumberAndPage(1, 1)
    } else if (
      loginCurrentStep.step === 3 &&
      loginCurrentStep.stepPage === 2 &&
      !isFromLoginAccount
    ) {
      onSetCurrentLoginStepNumberAndPage(2, 2)
    } else if (loginCurrentStep.step === 3 && loginCurrentStep.stepPage === 3) {
      onSetCurrentLoginStepNumberAndPage(2, 2)
    } else if (loginCurrentStep.step === 2 && loginCurrentStep.stepPage === 1) {
      onSetCurrentLoginStepNumberAndPage(1, 1)
    } else if (loginCurrentStep.step === 4 && loginCurrentStep.stepPage === 3) {
      onSetCurrentLoginStepNumberAndPage(3, 3)
    } else if (loginCurrentStep.step === 3 && loginCurrentStep.stepPage === 1) {
      onSetCurrentLoginStepNumberAndPage(2, 1)
    } else if (
      loginCurrentStep.step === 3 &&
      loginCurrentStep.stepPage === 2 &&
      isFromLoginAccount
    ) {
      onSetIsFromLoginAccount(false)
      onSetCurrentLoginStepNumberAndPage(2, 1)
    }
  }

  const onReCAPTCHAChange = (captchaCode) => {
    if (!captchaCode) {
      return
    }
    recaptchaRef.current.reset()
  }

  const onReCAPTCHAChangeV3 = (captchaCode) => {
    if (!captchaCode) {
      return
    }
    recaptchaRefV3.current.reset()
  }

  const stepsComponents = [
    {
      step: 1,
      stepPage: 1,
      component: isSuitMobile() ? (
        <LoginGuestStep recaptchaRef={recaptchaRef} />
      ) : (
        <VerifyAccountStep recaptchaRef={recaptchaRef} />
      )
    },
    {
      step: 2,
      stepPage: 1,
      component: (
        <LoginAccountStep
          recaptchaRefV3={recaptchaRefV3}
          canGoPayment={CanGoPayment}
        />
      )
    },
    {
      step: 2,
      stepPage: 2,
      component: <NoHaveAccountStep />
    },
    {
      step: 3,
      stepPage: 1,
      component: <HelpStep recaptchaRef={recaptchaRef} />
    },
    {
      step: 3,
      stepPage: 2,
      component: <LoginGuestStep recaptchaRef={recaptchaRef} />
    },
    {
      stepPage: 3,
      step: 3,
      component: <CreateAccountStepOne />
    },
    {
      step: 4,
      stepPage: 3,
      component: (
        <CreateAccountStepTwo
          recaptchaRef={recaptchaRef}
          recaptchaRefV3={recaptchaRefV3}
          canGoPayment={CanGoPayment}
        />
      )
    }
  ]
  const currentStepComponent = stepsComponents.find(
    (stepComponent) =>
      stepComponent.step === loginCurrentStep?.step &&
      stepComponent.stepPage === loginStepPage
  )

  useEffect(() => {
    onSetCurrentLoginStepNumberAndPage(1, 1)
    setEmailOrCpfValueContext('')
  }, [])
  return (
    <S.LoginContainer>
      <S.IconWrp onClick={() => onSetIsOpenLogin({ show: false })}>
        <Icon
          src="logo"
          color="transparent"
          size={32}
          className="guest-header-icon"
        />
      </S.IconWrp>

      <S.LoginTittle>
        {loginCurrentStep?.step >= 2 && (
          <Icon
            src="icons/arrow"
            color={theme.button.primary.bgColor}
            width={25}
            className="guest-icon"
            onClick={handlePreviousStep}
          />
        )}
        <Text
          type="HEADLINE"
          text={
            isSuitMobile() ? 'Faça login como guest ' : loginCurrentStep?.title
          }
          className="step-title"
        />
      </S.LoginTittle>

      {currentStepComponent && currentStepComponent.component}

      <ReCAPTCHA
        ref={recaptchaRefV3}
        size="invisible"
        sitekey={process.env.RECAPTCHA_V3_KEY}
        onChange={(t) => onReCAPTCHAChangeV3(t)}
      />
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.RECAPTCHA_KEY_V2}
        onChange={(t) => onReCAPTCHAChange(t)}
      />
    </S.LoginContainer>
  )
}

export default Login
