import { FormProvider, useForm } from 'react-hook-form'
import Input from '@Components/Input'
import Button from '@Components/Button'
import { useLogin } from '@shared/hooks/useLogin'
import Icon from '@Components/Icon'
import * as S from './styled'
import {
  inputsLoginCreateAccountCpfStepOne,
  inputsLoginCreateAccountEmailStepOne
} from '../steps'

import { handleCpfEmail } from '../../../shared/utils/helpers/others'

const CreateAccountStepOne = () => {
  const methods = useForm()
  const {
    loginCurrentStep,
    onSetCurrentLoginStepNumberAndPage,
    emailOrCpfValueContext,
    setDataCreateAccountStepOne,
    dataFromCreateAccountStepOne
  } = useLogin()

  const emailRegex = /\S+@\S+\.\S+/
  const isEmail = emailRegex.test(emailOrCpfValueContext)

  const { handleSubmit } = methods

  const onSubmit = (data) => {
    setDataCreateAccountStepOne(Object.values(data))
    onSetCurrentLoginStepNumberAndPage(4, 3)
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset>
          {isEmail
            ? inputsLoginCreateAccountEmailStepOne.map((inputItem, index) => (
                <S.ContainerInput key={inputItem.id}>
                  <Input
                    initialValue={
                      inputItem.id === 1
                        ? emailOrCpfValueContext
                        : dataFromCreateAccountStepOne[index]
                    }
                    key={inputItem.id}
                    disabled={false}
                    placeholder={inputItem.placeholder}
                    autoComplete="off"
                    handleClick={() => onSetCurrentLoginStepNumberAndPage(1, 1)}
                    rule={inputItem.valid}
                    mask={inputItem.mask}
                    applyMaskOnlyThisConditionIsTrue={
                      inputItem.applyMaskOnlyThisConditionIsTrue
                    }
                    label={
                      inputItem.id === 1
                        ? handleCpfEmail(emailOrCpfValueContext).label
                        : inputItem.label
                    }
                    change={inputItem?.change}
                    inputName={inputItem.inputName}
                    validation={inputItem?.validation}
                    maxLength={256}
                  />
                </S.ContainerInput>
              ))
            : inputsLoginCreateAccountCpfStepOne.map((inputItem, index) => (
                <S.ContainerInput key={inputItem.id}>
                  <Input
                    initialValue={
                      inputItem.id === 1
                        ? emailOrCpfValueContext
                        : dataFromCreateAccountStepOne[index]
                    }
                    key={inputItem.id}
                    disabled={false}
                    placeholder={inputItem.placeholder}
                    handleClick={() => onSetCurrentLoginStepNumberAndPage(1, 1)}
                    rule={inputItem.valid}
                    mask={inputItem.mask}
                    autoComplete="off"
                    applyMaskOnlyThisConditionIsTrue={
                      inputItem.applyMaskOnlyThisConditionIsTrue
                    }
                    label={
                      inputItem.id === 1
                        ? handleCpfEmail(emailOrCpfValueContext).label
                        : inputItem.label
                    }
                    change={inputItem?.change}
                    inputName={inputItem.inputName}
                    validation={inputItem?.validation}
                    maxLength={256}
                  />
                </S.ContainerInput>
              ))}
        </fieldset>
        <S.HelpContainer>
          <span>Ao continuar, aceito os </span>
          <a target="_blank" href={process.env.termsOfUse} rel="noreferrer">
            Termos de Uso
          </a>{' '}
          <span>e</span>
          <a target="_blank" href={process.env.PrivacyPolicy} rel="noreferrer">
            {' '}
            Política de Privacidade.
          </a>
        </S.HelpContainer>
        <S.ContainerButton>
          <Button
            isInitialPositionIcon={
              loginCurrentStep?.button?.isInitialPositionIcon
            }
            height={
              loginCurrentStep?.button?.height
                ? loginCurrentStep?.button?.height
                : '48px'
            }
            className="main-button"
            onClick={() => onSubmit}
            iconLeft={
              loginCurrentStep?.button?.icon ? (
                <Icon
                  color="#460505"
                  src={
                    loginCurrentStep?.button.icon
                      ? loginCurrentStep?.button?.icon
                      : ''
                  }
                  width={24}
                  height={24}
                />
              ) : null
            }
          >
            {loginCurrentStep?.button?.label}
          </Button>
        </S.ContainerButton>
      </form>
    </FormProvider>
  )
}

export default CreateAccountStepOne
