import React, { FC } from 'react'

import * as S from './styled'
import { IProps } from './interface'
import Icon from '@Components/Icon'

const Alert: FC<IProps> = ({
  type = 'info',
  title,
  content,
  isVisible = false,
  onClose,
  onlyShowContentInHeader = true,
  position = 'top'
}: IProps) => {
  return (
    <S.Container type={type} isVisible={isVisible} position={position}>
      {onlyShowContentInHeader ? (
        <>
          <S.Header>
            <Icon
              className="titleIcon"
              src={`icons/${type}`}
              size={24}
              color="#FF3300"
            />
            {title && <S.Title>{title}</S.Title>}
            <Icon
              className="closeIcon"
              src="icons/close"
              onClick={onClose}
              size={24}
            />
          </S.Header>
        </>
      ) : (
        <>
          <S.Header>
            <Icon
              className="titleIcon"
              src={`icons/${type}`}
              size={20}
              color="#FF3300"
            />
            {title && <S.Title>{title}</S.Title>}
            <Icon
              className="closeIcon"
              src="icons/close"
              onClick={onClose}
              size={20}
            />
          </S.Header>
          {content && <S.Body>{content}</S.Body>}
        </>
      )}
    </S.Container>
  )
}

export default Alert
