import { GlobalContext } from '@shared/context/Global'
import { useContextSelector } from 'use-context-selector'
import { clarity } from 'react-microsoft-clarity'
import { useLoading } from '../useLoading'
import { useAdyenCheckoutContext } from '@shared/context/Adyen/AdyenContext'
import { useCart } from '../useCart'
import services from '@Services/index'
import CheckoutUtils from '@shared/utils/cart'

export const useAsyncPayment = () => {
  const { timePaymentAsync } = useAdyenCheckoutContext()
  const { updateCart, goToWaitPayment } = useCart()
  const { onSetIsLoading, onSetIsLoadingButton } = useLoading()
  const { getTotalPriceFromCart } = CheckoutUtils()

  const onSetPaymentAsyncInfo = useContextSelector(
    GlobalContext,
    (state) => state?.onSetPaymentAsyncInfo
  )

  function redirectPaymentAsyncPage(data) {
    onSetIsLoading(true)
    const timeInterval = timePaymentAsync?.filter(
      (item) => item.type === data.type
    )[0]
    const actionData = {
      paymentData: data.paymentData,
      paymentMethodType: data.paymentMethodType,
      token: data.token,
      type: data.type,
      url: data.url,
      qrCodeData: data.qrCodeData,
      orderId: data.orderId,
      cartId: data.cartId,
      timePayment: timeInterval,
      startDate: new Date(),
      expiration: data.expiration
    }

    updateCart(actionData.cartId)

    try {
      clarity.setTag('order', actionData.orderId)
      clarity.setTag('paymentType', data.type)
    } catch (error) {
      console.log(error)
    }

    onSetPaymentAsyncInfo({
      ...actionData,
      price: getTotalPriceFromCart()
    })

    goToWaitPayment(actionData.orderId)
    onSetIsLoadingButton(false)
  }

  async function cancelOrder(orderId: string) {
    onSetIsLoadingButton(false)
    await services.payments.cancelAsyncOrder(orderId, {
      deviceType: '',
      deviceId: '',
      userRoles: [],
      reason: 'Qrcode expirado'
    })
  }

  return {
    redirectPaymentAsyncPage,
    cancelOrder
  }
}
