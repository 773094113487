import styled, { css } from 'styled-components'

export const Container = styled.div<{ isOpen: boolean }>`
  max-height: 400px;
  background-color: ${(props) => props.theme?.global?.background?.color};

  ${(props) =>
    props.isOpen &&
    css`
      z-index: 1;
      border-radius: 5px;
      border: 1px solid ${(props) => props.theme?.header?.user?.borderColor};
    `}
`

export const Content = styled.div<{ isOpen: boolean }>`
  display: none;
  min-width: 200px;
  max-height: 400px;
  height: auto;
  overflow: hidden;
  transition: max-height all 0.4s;
  ${(props) =>
    props.isOpen &&
    css`
      display: block;
      background-color: ${(props) => props.theme?.header?.user?.bgColor};
      border-radius: 0.5rem;
      box-sizing: border-box;
      padding: 1rem;
    `}
`

export const Title = styled.h1`
  color: ${(props) => props.theme?.global?.text?.color};
  font-weight: 400;
  text-align: center;
`

export const SubTitle = styled.h2`
  color: #999;
  font-weight: 400;
  text-align: center;
  margin-top: 8px;
`

export const LoginButton = styled.button`
  background-color: ${(props) => props.theme?.header?.user?.borderColor};
  color: #000;
  border: none;
  padding: 7px;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  width: 104px;
  border-radius: 4px;
  font-weight: 600;
`

export const Body = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px;
`

export const Header = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 16px;
`

export const Footer = styled.div`
  height: 100%;
  font-size: 16px;
`

export const CreateAccoutButton = styled.a`
  color: ${(props) => props.theme?.header?.user?.borderColor};
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-top: 16px;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`
