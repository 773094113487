export const TICKETING_HOST = process.env.baseUrlApiTicketing

export const USERS_SESSIONS = {
  get: (userId: string, sessionId: string | string[], endpoint: string) => {
    return `${TICKETING_HOST}/users/${userId}/${endpoint}/${sessionId}`
  },
  getPolices: () => {
    return `${process.env.baseUrlApiAuth}/users/policies`
  },
  getSavedCard: (userId: string) => {
    return `${process.env.baseUrlApiAuth}/users/${userId}/payments`
  },
  post: (value: {}, endpoint: string) => {
    return {
      url: `${process.env.baseUrlApiAuth}/users/${endpoint}`,
      fields: value
    }
  },
  create: (value: {}) => {
    return {
      url: `${process.env.baseUrlApiAuth}/users`,
      fields: value
    }
  },
  getUserLogged: (userId: string) => {
    return `${process.env.baseUrlApiAuth}/users/${userId}`
  }
}
