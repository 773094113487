export const ORDERS = {
  get: () => `/orders`
}

export const ASYNC_ORDERS = {
  get: () => `/orders/create-async-order`
}

export const ORDERS_COMPLETED = {
  get: (orderId: string) =>
    `users/completed/orders/${orderId}/?renderView=order-complete`
}

export const CANCEL_ASYNC_ORDER = {
  get: (orderId: string) => `/orders/${orderId}/cancelAsyncOrder`
}
