import { useContextSelector } from 'use-context-selector'
import { GlobalContext } from '@shared/context/Global'
import { useSessionId } from './useSessionId'
import { getNextDate } from '@Helpers/date'
import services from '@shared/services'
import { useRouter } from 'next/router'
import { useCookies } from './useCookies'
import CONSTANTS from '@Constants/index'
import { useCartIsReady } from './useCartIsReady'
import { useAuthToken } from './useAuthToken'
import schemas from '@Schemas/index'
import { useAlert } from './useAlert'
import { useModal } from './useModal'
import { useLoading } from './useLoading'
import { useSessionData } from './useSessionData'
import { useCheckout } from './useCheckout'
import { useIsChangeSeatMap } from './useIsChangeSeatMap'
import { ISessionData } from '@shared/interfaces/SessionData'
import { usePage } from './usePage'
import { useSessionTimer } from './useSessionTimer'
import { ISeatInfo } from '@Components/Seat/interface'
import { useSeats } from './useSeats'
import { PAGE_NAME_SEATS } from '@Constants/page'
import CheckoutUtils from '@shared/utils/cart'
import { usePaymentMethods } from './usePaymentMethods'
import { clarity } from 'react-microsoft-clarity'
import { useFidelity } from './useFidelity'
import { deleteThemeSessionStorage } from '@shared/utils/theme'

import { useTickets } from './useTickets'
import { useBomboniere } from './useBomboniere'
import { useDiscounts } from './useDiscounts'
import { useWebView } from './useWebView'
import { useWebViewWithTracking } from './useWebViewWithTracking'
import loadDnaUOL from '@shared/scripts/DnaUOL'
import { useReCaptcha } from '@shared/hooks/useRecaptcha'
import { IError } from '@shared/request/interface'

export function useCart() {
  const { recaptchaRefV2 } = useReCaptcha()
  const cart = useContextSelector(GlobalContext, (state) => state?.cart)
  const { cartIsReadyWebView } = useWebView()
  const { trackCheckoutErrorApp } = useWebViewWithTracking()
  const { sessionTimer, clearTimer } = useSessionTimer()
  const { onSetSeats, seats, onSetShowCaptchaSeatMap } = useSeats()
  const { onSetTickets } = useTickets()
  const { onSetBomboniere } = useBomboniere()
  const { sessionData, onSetSessionData } = useSessionData()
  const { onSetIsLoading, onSetIsLoadingButton } = useLoading()
  const { onSetIsOpenModal } = useModal()
  const { onSetCartIsReady } = useCartIsReady()
  const { onSetIsChangeSeatMap, changeSeatMap } = useIsChangeSeatMap()
  const { getDifferenceSeats, getSelectedSeats, getUrlParams } = CheckoutUtils()
  const { onSetPaymentMethodCurrent } = usePaymentMethods()
  const { onRemoveDiscounts } = useDiscounts()
  const { isMobileApp, getClientOrigin } = useWebView()

  const { removeAllCookies, removeCookie, setCookie, getCookie } = useCookies()

  const cartId =
    typeof window !== 'undefined'
      ? sessionStorage.getItem(CONSTANTS.COOKIE.CART_ID)
      : null

  const seatsSelectionHasChanged =
    getDifferenceSeats(seats?.selected, getSelectedSeats())?.length > 0 ||
    seats?.selected?.length !== sessionData?.selectedSeats?.length

  const onSetCart = useContextSelector(
    GlobalContext,
    (state) => state?.onSetCart
  )
  const { onSetPage } = usePage()
  const { onSetSessionId } = useSessionId()
  const router = useRouter()

  const queryParams = getUrlParams()

  const { sessionId } = useSessionId()
  const { authToken, onSetAuthToken } = useAuthToken()
  const { onSetIsOpenAlert } = useAlert()
  const { onSetGlobalLoading } = useCheckout()
  const { onSetIsFidelity, onSetSameCpfFidelity } = useFidelity()
  const { closeCheckout } = useWebView()

  const restartSession = () => {
    sessionData.selectedSeats = []
    sessionData.selectedBomboniere = []
    sessionData.selectedTickets = []
    onSetIsLoading(true)
    onSetCartIsReady(false)
    onSetGlobalLoading(true)
    getCart()
  }

  const goSeats = async (sessionId: string) => {
    onSetCartIsReady(true)
    onSetPage(CONSTANTS.PAGE.PAGE_NAME_SEATS)
    onSetSessionId(sessionId)

    router.push({
      pathname: `/${CONSTANTS.PAGE.PAGE_NAME_SEATS}`,
      query: { ...queryParams }
    })
  }

  const goSeatsOnNotAvailableSeatError = async () => {
    onSetSeats({ selected: [] })
    onSetIsChangeSeatMap(!changeSeatMap)
    sessionData.selectedSeats = []
    onSetSessionData(sessionData)
    onSetPage(CONSTANTS.PAGE.PAGE_NAME_SEATS)
    router.push({
      pathname: `/${CONSTANTS.PAGE.PAGE_NAME_SEATS}`,
      query: { ...queryParams }
    })
  }

  const goBomboniere = async (sessionId: string) => {
    onSetCartIsReady(true)

    onSetPage(CONSTANTS.PAGE.PAGE_NAME_BOMBONIERE)
    onSetSessionId(sessionId)

    router.push({
      pathname: `/${CONSTANTS.PAGE.PAGE_NAME_BOMBONIERE}`,
      query: { ...queryParams }
    })
  }
  const goTickets = async (
    pageName: string,
    sessionId: string,
    sectionId?: string
  ) => {
    onSetIsLoading(true)

    const goToTicketsPage = () => {
      onSetPage(CONSTANTS.PAGE.PAGE_NAME_TICKETS)
      router.push({
        pathname: `/${CONSTANTS.PAGE.PAGE_NAME_TICKETS}`,
        query: { ...queryParams }
      })
    }
    const setInformationsAndGoToTicketsPage = (data) => {
      const { sessions } = data
      const seats = sessions[0].tickets.map((item: { seat: ISeatInfo }) => ({
        id: item.seat.id,
        label: item.seat.label,
        typeDescription: item.seat.typeDescription,
        iconUrl: item.seat.iconUrl,
        type: item.seat.type,
        specialSeat: item.seat.typeSeparation === 'Especial',
        typeSeparation: item.seat.typeSeparation,
        areaNumber: item.seat.areaNumber,
        rowIndex: item.seat.rowIndex,
        columnIndex: item.seat.columnIndex,
        sectorId: item.seat.sectorId,
        sectorDescription: item.seat.sectorDescription,
        sectorTypeId: item.seat.sectorTypeId,
        sectorTypeDescription: item.seat.sectorTypeDescription,
        sectionId: item.seat.sectionId
      }))
      sessionData.selectedSeats = seats
      sessionData.session.reservationMinutesLeft =
        sessions[0].reservationMinutesLeft
      sessionData.session.seatReservationLimit =
        sessions[0].seatReservationLimit
      onSetSeats({ selected: seats })
      onSetSessionData(sessionData)
      sessionTimer()
      goToTicketsPage()
    }
    if (pageName === PAGE_NAME_SEATS && sessionData?.hasSeatSelection) {
      if (!seats.selected.length) {
        onSetIsLoading(false)
        return onSetIsOpenModal({
          ...schemas.modal.SEATS_SELECT_A_SEAT(),
          onConfirm: () => {
            onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
          }
        })
      } else {
        const selectedSeats = seats.selected
        if (seatsSelectionHasChanged) {
          onSetIsFidelity(false)
          onSetSameCpfFidelity(false)
          const payload = { seats: selectedSeats }

          const [data, error] = await services.seats.create(
            { cartId, sessionId, sectionId },
            payload
          )
          if (data) setInformationsAndGoToTicketsPage(data)

          const handleErrorOnSeatmap = (error: IError) => {
            const modelState = error.modelState || {}
            const { status } = error
            const { cartError, errorSeatSelected, errorId } = modelState

            if (cartError) {
              const cartExpiredMessage = cartError?.[0].includes(
                'O tempo para finalizar a compra se excedeu'
              )
              if (cartExpiredMessage) {
                return onSetIsOpenModal({
                  ...schemas.modal.PURCHASE_TIME_EXPIRED,
                  onConfirm: () => {
                    restartSession()
                    onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
                  },
                  onClose: () => {
                    onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
                  }
                })
              }
            } else if (errorSeatSelected) {
              const errorMessage =
                errorSeatSelected?.[0] ||
                'Outra pessoa selecionou este assento. Escolha outro para continuar.'
              const diference = getDifferenceSeats(
                selectedSeats,
                getSelectedSeats()
              )
              const filtered = selectedSeats.filter((seat) => {
                return diference.some((seatDiference) => {
                  return seatDiference.id !== seat.id
                })
              })
              onSetSeats({ selected: filtered })
              onSetIsChangeSeatMap(!changeSeatMap)
              trackCheckoutErrorApp(
                error.status,
                'Error Seat Selected',
                errorMessage
              )
              return onSetIsOpenModal({
                ...schemas.modal.SEATS_SELECT_A_SEAT(
                  'seats/alerts/alert-seat-ocuppied'
                ),
                description: errorMessage,
                onConfirm: () => {
                  onSetIsChangeSeatMap(!changeSeatMap)
                  onSetSeats({ selected: [] })

                  onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
                }
              })
            } else if (status === 401) {
              return onSetIsOpenModal({
                ...schemas.modal.PURCHASE_TIME_EXPIRED,
                onConfirm: () => {
                  restartSession()
                  onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
                },
                onClose: () => {
                  onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
                }
              })
            } else if (status === 404) {
              return onSetIsOpenModal({
                ...schemas.modal.SEATS_SESSION_DOES_NOT_EXIST,
                onConfirm: () => {
                  onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
                  if (isMobileApp()) closeCheckout()
                  window.location.href = process.env.baseUrlCatalog
                },
                onClose: () => {
                  onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
                }
              })
            } else if (errorId?.includes('INVALID_CAPTCHA')) {
              const executeRecaptcha = async () => {
                if (recaptchaRefV2.current) {
                  try {
                    const captchaCode =
                      await recaptchaRefV2.current.executeAsync()
                    if (captchaCode) {
                      const [data, error] = await services.seats.create(
                        { cartId, sessionId, sectionId },
                        payload,
                        captchaCode
                      )
                      if (data) {
                        setInformationsAndGoToTicketsPage(data)
                      }
                      if (error) {
                        handleErrorOnSeatmap(error)
                      }
                      onSetIsLoading(false)
                    }
                  } catch (error) {
                    console.error('Erro ao executar reCAPTCHA:', error)
                  }
                }
              }
              setTimeout(() => {
                onSetIsLoading(true)
                executeRecaptcha()
              }, 500)
              onSetShowCaptchaSeatMap(true)
            } else {
              const showModalAlertError = () => {
                setTimeout(() => {
                  onSetIsOpenAlert(schemas.alert.CLOSE_ALERT)
                }, 6000)
                return onSetIsOpenAlert({
                  ...schemas.alert.APP_INTERNAL_ERROR,
                  onClose: () => {
                    onSetIsOpenAlert(schemas.alert.CLOSE_ALERT)
                  }
                })
              }
              showModalAlertError()
            }
          }
          if (error) {
            onSetIsLoading(false)
            handleErrorOnSeatmap(error)
          }
        } else {
          goToTicketsPage()
        }
      }
    } else {
      goToTicketsPage()
    }
    onSetIsLoading(false)
  }
  const goPayments = () => {
    onSetPage(CONSTANTS.PAGE.PAGE_NAME_PAYMENTS)

    router.push({
      pathname: `/${CONSTANTS.PAGE.PAGE_NAME_PAYMENTS}`,
      query: { ...queryParams }
    })
  }

  const goConcluded = (id: string) => {
    onSetPage(CONSTANTS.PAGE.PAGE_NAME_CONCLUDED)
    onSetIsLoading(true)
    clearTimer()
    sessionStorage.removeItem(CONSTANTS.PAYMENTSTORAGE.PAYMENT_DATA)
    sessionData.selectedSeats = []
    onSetSessionData(sessionData)
    router.push({
      pathname: `/${CONSTANTS.PAGE.PAGE_NAME_CONCLUDED}`,
      query: { orderId: id, ...queryParams }
    })
  }
  const goToWaitPayment = (id: string) => {
    router.push({
      pathname: `/${CONSTANTS.PAGE.PAGE_NAME_WAIT_PAYMENT}`,
      query: { orderId: id, ...queryParams }
    })
  }
  const loadFingerprintDnaUol = (dna: { uid: string }) => {
    const token =
      getCookie(process.env.COOKIE_TOKEN_NAME_APP) ||
      getCookie(process.env.COOKIE_TOKEN_NAME)

    const createCookieFingerprint = (fingerprint: string) => {
      const expiration = new Date()
      expiration.setFullYear(expiration.getFullYear() + 1)
      setCookie(process.env.COOKIE_FINGERPRINT, fingerprint, expiration)
    }
    try {
      if (
        !token?.fingerprint &&
        dna?.uid &&
        token?.access_token &&
        token?.type !== 'Guest'
      ) {
        window.DnaUOL.dnaUID = dna?.uid
        onSetAuthToken({
          ...token,
          fingerprint: dna.uid
        })
        createCookieFingerprint(dna.uid)
      } else if (dna?.uid) {
        window.DnaUOL.dnaUID = dna?.uid
        createCookieFingerprint(dna?.uid)
      }
    } catch (error) {
      console.error('error on loadFingerprint', error)
    }
  }
  const getUser = async () => {
    const token =
      getCookie(process.env.COOKIE_TOKEN_NAME_APP) ||
      getCookie(process.env.COOKIE_TOKEN_NAME)
    const fingerprint = getCookie(process.env.COOKIE_FINGERPRINT)

    const getCorrectType = () => {
      if (typeof token?.type === 'undefined') {
        return 'bearer'
      } else {
        return token?.type
      }
    }
    if (token?.user_id && token?.access_token) {
      const [data] = await services.users.getUserLogged(token?.user_id)
      onSetAuthToken({
        access_token: token?.access_token,
        user_first_name: token?.user_first_name,
        user_id: token?.user_id,
        email: data?.email,
        id: token?.user_id,
        cpf: data?.account?.cpf,
        EmailOptIn: data?.account?.emailOptIn,
        type: getCorrectType(),
        has_apple_pay: token?.has_apple_pay
          ? token.has_apple_pay === 'true'
          : false,
        has_apple_pay_configured: token?.has_apple_pay_configured
          ? token.has_apple_pay_configured === 'true'
          : false,
        device_id: token?.device_id,
        app_version: token?.app_version,
        fingerprint: fingerprint ?? null
      })
    }
    loadDnaUOL(loadFingerprintDnaUol)
  }
  const clearCart = () => {
    sessionData.selectedSeats = []
    sessionData.selectedTickets = []
    sessionData.selectedBomboniere = []
    sessionData.fidelityFields = []
    sessionData.discounts = []
    onSetSessionData(sessionData)
    onSetSeats({ selected: [] })
    onSetTickets({
      selected: [],
      totalSelected: 0,
      all: [],
      limit: 0
    })
    onSetBomboniere({
      all: [],
      selected: [],
      totalSelected: 0,
      selectedCategory: 0,
      limit: 0
    })
    onRemoveDiscounts('', false, '', true)
  }

  const getCart = async () => {
    sessionStorage.setItem('showModal18', JSON.stringify(true))
    clearCart()
    onSetIsFidelity(false)
    onSetSameCpfFidelity(false)
    onSetIsLoadingButton(false)
    onSetGlobalLoading(true)
    const sessionIdCookie = cart?.sessionId
    const cartId =
      typeof window !== 'undefined'
        ? sessionStorage.getItem(CONSTANTS.COOKIE.CART_ID)
        : null
    await getUser()

    if (cartId) {
      const verifyCartExist = await getCartData()
      if (!verifyCartExist?.isActiveCart) {
        return await createCart()
      } else {
        if (sessionIdCookie !== sessionId) {
          const removedSession = await removeSession()
          if (removedSession) {
            return await addSession(cartId)
          }
        } else {
          await checkDuplicatedOrder(
            cartId,
            false,
            verifyCartExist?.isActiveCart
          )
        }
      }
    } else {
      return await createCart()
    }
  }
  const removeSession = async () => {
    const [data, error] = await services.sessions.removeSession(
      cart?.id,
      sessionId
    )
    if (data) {
      return true
    }
    return error
  }
  const removeCookieGuest = () => {
    if (authToken?.type === 'Guest') {
      removeCookie(process.env.COOKIE_TOKEN_NAME)
      onSetAuthToken(null)
    }
  }
  const updateCart = async (cartId: string) => {
    sessionStorage.getItem(CONSTANTS.COOKIE.CART_ID)
    cart.id = cartId
  }

  const createCart = async () => {
    removeCookieGuest()
    onSetIsLoading(true)
    onSetGlobalLoading(true)
    onSetCartIsReady(false)
    deleteThemeSessionStorage()

    removeCookieGuest()
    const [data, error] = await services.carts.create(
      getClientOrigin(),
      authToken?.device_id,
      authToken?.app_version,
      isMobileApp()
    )
    const cartId = data?.id
    if (error) {
      removeAllCookies()
      return onSetIsOpenAlert({
        ...schemas.alert.APP_INTERNAL_ERROR
      })
    }
    sessionStorage.setItem(CONSTANTS.COOKIE.CART_ID, cartId)

    await checkDuplicatedOrder(cartId, false, true)
  }

  const checkDuplicatedOrder = async (
    cartId?: string,
    isPaymentPage?: boolean,
    cartIsActive?: boolean
  ) => {
    const isSessionIdEqualPrevious = cart?.sessionId === sessionId
    if (
      isSessionIdEqualPrevious &&
      authToken?.id &&
      authToken?.access_token &&
      authToken?.type !== 'Guest'
    ) {
      const [data, ,] = await services.users.checkDuplicateOrder(
        authToken?.id,
        cart?.sessionId
      )
      if (data && data?.hasDuplicated && !isPaymentPage) {
        onSetIsLoading(false)
        onSetGlobalLoading(false)
        onSetCartIsReady(false)

        return onSetIsOpenModal({
          ...schemas.modal.SEATS_TICKET_ALREADY_GUARANTEED(
            data.ticketsLength,
            data.dateFormatted
          ),
          onConfirm: async () => {
            onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
            onSetGlobalLoading(true)
            onSetIsLoading(true)
            await addSession(cartId)
          },
          onClose: () => {
            onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
            if (isMobileApp()) closeCheckout()
            window.location.href = process.env.baseUrlCatalog
          }
        })
      } else if (isPaymentPage && data?.hasDuplicated) {
        return onSetIsOpenModal({
          ...schemas.modal.SEATS_TICKET_ALREADY_GUARANTEED(
            data.ticketsLength,
            data.dateFormatted
          ),
          onConfirm: async () => {
            onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
          },
          onClose: () => {
            onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
            if (isMobileApp()) closeCheckout()
            window.location.href = process.env.baseUrlCatalog
          }
        })
      } else if (!isPaymentPage && !data?.hasDuplicated) {
        if (cartIsActive) {
          return await addSession(cartId)
        } else {
          return await createCart()
        }
      }
    } else {
      addSession(cartId)
    }
  }
  const addSession = async (cartId: string) => {
    removeCookieGuest()
    onSetIsLoading(true)
    const {
      CART_SESSION_ID,
      CART_SECTION_ID,
      CART_ID,
      CURRENT_CITY,
      SITE_CITY
    } = CONSTANTS.COOKIE
    const [data, error] = await services.sessions.addSession(
      cartId,
      sessionId,
      router.query?.partnership ? (router.query?.partnership as string) : null,
      router.query?.queueittoken ? (router.query?.queueittoken as string) : null
    )
    if (error) {
      onSetCartIsReady(false)
      onSetGlobalLoading(false)
      onSetIsLoading(false)
      removeCookie(CART_SESSION_ID)
      removeCookie(CART_SECTION_ID)
      removeCookie(CART_ID)
      removeCookie(CURRENT_CITY)
      removeCookie(SITE_CITY)
      if (error?.status === 400) {
        if (error?.modelState) {
          let schema = null

          if (error?.modelState?.theaterDisabled) {
            schema = schemas.modal.SESSION_ERROR_CUSTOM({
              isOpen: true,
              description: error?.modelState?.theaterDisabled?.[0]
            })
          } else if (error?.modelState?.sessionError) {
            schema = schemas.modal.SESSION_ERROR_CUSTOM({
              isOpen: true,
              description: error?.modelState?.sessionError?.[0]
            })
          } else if (error?.modelState?.cartError) {
            schema = schemas.modal.SEATS_EXPIRED_SESSION_CUSTOM({
              isOpen: true,
              description: error?.modelState?.cartError?.[0]
            })
          } else if (error?.modelState?.queueError) {
            window.location.href = error?.modelState?.queueError
            return
          }

          if (schema) {
            return onSetIsOpenModal({
              ...schema,
              onConfirm: () => {
                if (isMobileApp()) closeCheckout()
                window.location.href = process.env.baseUrlCatalog
                onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
              },
              onClose: () => {
                onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
              }
            })
          }
        }
      }

      if (error.status === 404) {
        onSetIsLoading(false)
        onSetGlobalLoading(false)
        onSetCartIsReady(false)
        return onSetIsOpenModal({
          ...schemas.modal.SEATS_SESSION_DOES_NOT_EXIST,
          onConfirm: () => {
            onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
            if (isMobileApp) closeCheckout()
            window.location.href = process.env.baseUrlCatalog
          },
          onClose: () => {
            onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
          }
        })
      }

      return onSetIsOpenAlert({
        ...schemas.alert.APP_INTERNAL_ERROR,
        onClose: () => {
          onSetIsOpenAlert(schemas.alert.CLOSE_ALERT)
        }
      })
    }

    sessionStorage.setItem(CART_SESSION_ID, sessionId)
    const sectionId = data?.sessions[0]?.sections[0]?.id
    sessionStorage.setItem(CART_SECTION_ID, sectionId)

    const currentCity = data?.sessions[0]?.theatre.citySlug
    if (currentCity !== 'ingresso') {
      setCookie(CURRENT_CITY, currentCity)

      const siteCity = {
        Id: data?.sessions[0]?.theatre.cityId,
        Name: data?.sessions[0]?.theatre.city,
        UF: data?.sessions[0]?.theatre.state,
        UrlKey: data?.sessions[0]?.theatre.citySlug
      }
      setCookie(SITE_CITY, siteCity)
    }
    return getCartData(data)
  }

  const setDataSessionAndRedirect = async (dataFromSession: ISessionData) => {
    if (dataFromSession?.sessions?.[0]) {
      const { id, sections, hasSeatSelection } = dataFromSession.sessions[0]
      const { hasProductCategory } = dataFromSession.sessions[0].theatre
      onSetCart({
        info: dataFromSession,
        id: dataFromSession.id,
        sessionId: id,
        sectionId: sections[0].id,
        hasSeatSelection
      })

      onSetSessionData({
        functionalities: dataFromSession?.functionalities,
        titleMovie: dataFromSession?.sessions[0].event?.name,
        moviePlace: dataFromSession?.sessions[0].theatre.name,
        movieSession: dataFromSession?.sessions[0].location.name,
        movieBanner: dataFromSession?.sessions[0]?.images[1]?.url,
        sessionTime: dataFromSession?.sessions[0].dateFormatted,
        maxTickets: dataFromSession?.sessions?.[0]?.maxTickets,
        sessionId: id,
        hasSeatSelection,
        hasBomboniereActive: hasProductCategory,
        selectedSeats: [],
        selectedTickets: [],
        fidelityFields: [],
        expiresSession: getNextDate(),
        session: dataFromSession?.sessions?.[0],
        urlQueryParams: router?.query,
        typeDescription: dataFromSession?.sessions[0]?.typeDescription,
        subtitles: dataFromSession?.sessions[0]?.subtitles?.name,
        isPoweredBy:
          router?.query?.partnership && router?.query?.partnership !== 'home'
      })
      onSetPaymentMethodCurrent('')
      if (!hasSeatSelection) {
        goTickets(CONSTANTS.PAGE.PAGE_NAME_TICKETS, sessionId, sections[0].id)
        onSetCartIsReady(true)
        onSetGlobalLoading(false)
        sessionTimer()
      } else {
        goSeats(sessionId)
        onSetCartIsReady(true)
        onSetGlobalLoading(false)
        onSetIsChangeSeatMap(!changeSeatMap)
        sessionTimer()
      }
      cartIsReadyWebView()
      try {
        clarity.setTag('cart', dataFromSession?.id)
        clarity.setTag('theatre', dataFromSession?.sessions[0].theatre.name)
        clarity.setTag('session', id)
        clarity.setTag('movie', dataFromSession?.sessions[0].event.name)
      } catch (error) {
        console.log(error)
      }
    }
  }
  const getCartData = async (dataFromSession: ISessionData = {}) => {
    const cartId = sessionStorage.getItem(CONSTANTS.COOKIE.CART_ID)
    onSetGlobalLoading(true)
    if (dataFromSession?.sessions?.[0]) {
      setDataSessionAndRedirect(dataFromSession)
      return { isActiveCart: true }
    } else {
      onSetGlobalLoading(false)

      const [data, error] = await services.carts.get(cartId)
      if (data) {
        return { isActiveCart: true, data }
      } else if (error) {
        return { isActiveCart: false }
      }
    }
  }
  return {
    cart,
    onSetCart,
    goBomboniere,
    getCart,
    createCart,
    getCartData,
    addSession,
    checkDuplicatedOrder,
    goSeats,
    goTickets,
    goPayments,
    goConcluded,
    goToWaitPayment,
    restartSession,
    updateCart,
    goSeatsOnNotAvailableSeatError
  }
}
