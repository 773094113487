import { useWebView } from './useWebView'
import { IAuthToken, TSessionData } from '@shared/context/interface'
import { getFormatedDateUTC } from '@Helpers/others'

export function useWebViewWithTracking() {
  const { isIOS, isAndroid } = useWebView()

  const trackGlobalEventWebView = (eventName: string, properties: object) => {
    if (isIOS()) {
      window.webkit.messageHandlers.callbackHandler.postMessage({
        trackEventAppIngressoCinema: {
          event: eventName,
          ...properties
        }
      })
    }
    if (isAndroid()) {
      const androidParameter = {
        trackEventAppIngressoCinema: eventName,
        ...properties
      }
      try {
        window.TicketingAppAndroid.trackEventWasCalled(
          JSON.stringify(androidParameter)
        )
      } catch (error) {
        console.error(error)
      }
    }
  }
  const trackCheckoutErrorApp = (errorCode, errorTitle, errorMessage) => {
    trackGlobalEventWebView('checkout_with_error', {
      error_code: errorCode,
      error_title: errorTitle,
      error_message: errorMessage
    })
  }
  const trackSeatPickerApp = () => {
    console.debug('Track - Seat Picker')
    trackGlobalEventWebView('screen_chose_seats', {})
  }
  const trackTicketTypeApp = () => {
    console.debug('Track - Ticket Type')
    trackGlobalEventWebView('screen_ticket_type', {})
  }
  const trackPurchaseResumeApp = () => {
    console.debug('Track - Purchase Resume')
    trackGlobalEventWebView('screen_purchase_resume', {})
  }
  const trackOnPaymentApp = () => {
    trackGlobalEventWebView('screen_chose_payment', {})
  }

  const trackBeginCheckoutApp = (sessionData: TSessionData) => {
    const event = sessionData?.session?.event
    const partnership = sessionData?.urlQueryParams?.partnership
      ? sessionData.urlQueryParams?.partnership
      : ''
    const theatre = sessionData?.session?.theatre

    let flagIndex = 0
    let items = sessionData.selectedTickets.map((item) => {
      return {
        item_id: event?.id,
        item_name: event?.name || '',
        price: item.service || 0,
        quantity: item.quantity || 0,
        item_brand: event?.distributor ? event?.distributor : '',
        item_variant: item.name,
        item_category: 'Ticket',
        affiliation: partnership,
        index: flagIndex++
      }
    })
    if (
      sessionData?.selectedBomboniere &&
      sessionData?.selectedBomboniere.length
    ) {
      items = items.concat(
        sessionData?.selectedBomboniere.flatMap((item) => {
          return item.products.map((product) => {
            let itemId = ''
            if (product.concentrator) {
              itemId = `e${theatre?.id}c${product.id}`
            } else {
              itemId = `e${theatre?.id}p${product.id}`
            }
            return {
              item_id: itemId,
              item_name: product.name,
              price: product.service || 0,
              quantity: product.quantity || 0,
              item_brand: null,
              item_variant: null,
              item_category: 'Snack',
              affiliation: partnership,
              index: flagIndex++
            }
          })
        })
      )
    }
    const totalPrice = items.reduce((acc, item) => {
      return acc + Number(item.price * item.quantity)
    }, 0)
    trackGlobalEventWebView('begin_checkout', {
      value: Number(totalPrice.toFixed(2)),
      event: 'begin_checkout',
      currency: 'BRL',
      ...items
    })
  }

  const trackAddToCartApp = (
    sessionData: TSessionData,
    authToken: IAuthToken
  ) => {
    const event = sessionData?.session?.event
    const partnership = sessionData?.urlQueryParams?.partnership
      ? sessionData.urlQueryParams?.partnership
      : ''
    const theatre = sessionData?.session?.theatre
    const session = sessionData?.session
    const sessionType = session?.typeDescription
      .map((item) => {
        return item
      })
      .join(',')
    const params = {
      event: 'add_to_cart',
      page: {
        partnership
      },
      ecommerce: {
        currency: 'BRL',
        items: [
          {
            item_id: event?.id ? event?.id : '',
            item_name: event?.name ? event?.name : '',
            item_brand: event?.distributor ? event?.distributor : '',
            item_category: 'Ticket',
            affiliation: partnership
          }
        ]
      },
      spectacle: {
        id: event?.id,
        name: event?.name,
        contentRating: event?.rating,
        distributor: event?.distributor,
        genres: event?.genre,
        directedBy: event?.directors,
        countries: '',
        runtime: event?.duration,
        openingDate: event?.releaseDate
          ? getFormatedDateUTC(event?.releaseDate)
          : '',
        year: '',
        trailerUrl: event?.trailers[0]?.url?.trim(),
        subtitle: session?.subtitles?.name,
        tag: ''
      },
      place: {
        id: theatre?.id,
        name: theatre?.name,
        exhibitorId: theatre?.corporationId,
        exhibitor: theatre?.corporation,
        neighborhood: theatre?.address.neighborhood,
        room: sessionData?.movieSession
      },
      session: {
        date: session?.dateFormatted?.slice(0, 10),
        time: session?.dateFormatted?.slice(11, 16),
        type: sessionType
      },
      user: {
        name: authToken?.user_first_name || 'visitante',
        id: authToken?.user_id || '',
        email: authToken?.email || '',
        cpf: authToken?.cpf || '',
        type: authToken?.user_first_name ? 'registered' : 'guest',
        language: 'pt-br'
      }
    }
    trackGlobalEventWebView('add_to_cart', params)
  }
  const trackTransactionCheckoutApp = (
    transactionId: string,
    sessionData?: TSessionData,
    payments?: Array<{ giftcardType: string; type: string }>
  ) => {
    let coupon = ''
    const event = sessionData?.session?.event
    const partnership = sessionData?.urlQueryParams?.partnership
      ? sessionData.urlQueryParams?.partnership
      : ''
    const theatre = sessionData?.session?.theatre
    const discountsInPayments = payments?.filter(
      (item) => item.type === 'PaymentGiftCard'
    )

    if (discountsInPayments?.length) {
      coupon = discountsInPayments[0].giftcardType
    }
    // Items com apenas as taxas de serviço (igual o begin_checkout)
    let flagIndex = 0
    let items = sessionData?.selectedTickets?.map((item) => {
      return {
        item_id: event?.id,
        item_name: event?.name || '',
        price: item.service || 0,
        quantity: item.quantity || 0,
        item_brand: event?.distributor ? event?.distributor : '',
        item_variant: item.name,
        item_category: 'Ticket',
        affiliation: partnership,
        index: flagIndex++
      }
    })
    if (
      sessionData?.selectedBomboniere &&
      sessionData?.selectedBomboniere?.length
    ) {
      items = items?.concat(
        sessionData?.selectedBomboniere?.flatMap((item) => {
          return item.products.map((product) => {
            let itemId = ''
            if (product.concentrator) {
              itemId = `e${theatre?.id}c${product.id}`
            } else {
              itemId = `e${theatre?.id}p${product.id}`
            }
            return {
              item_id: itemId,
              item_name: product.name,
              price: product.service || 0,
              quantity: product.quantity || 0,
              item_brand: null,
              item_variant: null,
              item_category: 'Snack',
              affiliation: partnership,
              index: flagIndex++
            }
          })
        })
      )
    }
    // Items com apenas o valor puro dos tickets
    let flagTicketProduct = 0
    const itemsTicket = sessionData?.selectedTickets?.map((item) => {
      let price: number
      if (item.tax) {
        price = item.unitPrice - item.service - item.tax
      } else {
        price = item.unitPrice - item.service
      }
      return {
        item_id: event?.id,
        item_name: event?.name || '',
        price,
        quantity: item.quantity || 0,
        item_brand: event?.distributor ? event?.distributor : '',
        item_variant: item.name,
        item_category: 'Ticket',
        affiliation: partnership,
        index: flagTicketProduct++
      }
    })
    // Items (tickets e produtos) com valor puro
    let itemsTicketProduct = []
    itemsTicketProduct = itemsTicketProduct?.concat(itemsTicket)
    if (
      sessionData?.selectedBomboniere &&
      sessionData?.selectedBomboniere.length
    ) {
      itemsTicketProduct = itemsTicketProduct?.concat(
        sessionData?.selectedBomboniere?.flatMap((item) => {
          return item.products.map((product) => {
            let itemId = ''
            if (product.concentrator) {
              itemId = `e${theatre?.id}c${product.id}`
            } else {
              itemId = `e${theatre?.id}p${product.id}`
            }
            return {
              item_id: itemId,
              item_name: product.name,
              price: product.price || 0,
              quantity: product.quantity || 0,
              item_brand: null,
              item_variant: null,
              item_category: 'Snack',
              affiliation: partnership,
              index: flagTicketProduct++
            }
          })
        })
      )
    }

    const ticketQuantity = itemsTicket?.reduce((acc, item) => {
      return acc + Number(item.quantity)
    }, 0)

    let productQuantity = 0
    if (
      sessionData?.selectedBomboniere &&
      sessionData?.selectedBomboniere.length
    ) {
      productQuantity = sessionData?.selectedBomboniere?.reduce((acc, item) => {
        const qtd = item.products.reduce((acc2, product) => {
          return acc2 + Number(product.quantity)
        }, 0)
        return acc + qtd
      }, 0)
    }

    const params = {
      event: 'purchase',
      order: {
        ticket_quantity: ticketQuantity,
        product_quantity: productQuantity
      },
      ecommerce: {
        transaction_id: transactionId,
        value: Number(
          items
            .reduce((acc, item) => {
              return acc + Number(item.price * item.quantity)
            }, 0)
            .toFixed(2)
        ),
        currency: 'BRL',
        coupon,
        items
      },
      ecommerceTicket: {
        transaction_id: transactionId,
        value: Number(
          itemsTicket
            .reduce((acc, item) => {
              return acc + Number(item.price * item.quantity)
            }, 0)
            .toFixed(2)
        ),
        currency: 'BRL',
        coupon,
        items: itemsTicket
      },
      ecommerceTicketProduct: {
        transaction_id: transactionId,
        value: Number(
          itemsTicketProduct
            .reduce((acc, item) => {
              return acc + Number(item.price * item.quantity)
            }, 0)
            .toFixed(2)
        ),
        currency: 'BRL',
        coupon,
        items: itemsTicketProduct
      }
    }
    trackGlobalEventWebView('purchase', params)
  }
  // Pagina de Assentos
  const trackSeatMapSelectSeatApp = (properties: object) => {
    trackGlobalEventWebView('select_seats', properties)
  }
  const trackSeatMapShowSeatNumberApp = (properties: object) => {
    trackGlobalEventWebView('click_see_seat_number_button', properties)
  }
  const trackSeatMapZoomApp = (properties: object) => {
    trackGlobalEventWebView('click_zoom', properties)
  }
  const trackSeatMapNextBtnApp = (properties: object) => {
    trackGlobalEventWebView('click_next', properties)
  }
  // Página de pagamentos

  const trackPaymentTypeApp = (properties: object) => {
    trackGlobalEventWebView('payment_type', properties)
  }
  const trackPaymentRemoveCardApp = (properties: object) => {
    trackGlobalEventWebView('remove_card', properties)
  }
  const trackPaymentNextBtnApp = (properties: object) => {
    trackGlobalEventWebView('click_next', properties)
  }
  // clube uol
  const trackPaymentKnowMoreClubUolApp = (properties: object) => {
    trackGlobalEventWebView('click_clube_uol_info', properties)
  }
  const trackPaymentCloseUnderstoodButtonClubUolModalApp = (
    properties: object
  ) => {
    trackGlobalEventWebView('click_clube_uol_info_entendi', properties)
  }
  const trackPaymentCloseButtonInfoClubUolModalApp = (properties: object) => {
    trackGlobalEventWebView('click_clube_uol_info_close', properties)
  }
  const trackPaymentModalLinkClickHereClubUolApp = (properties: object) => {
    trackGlobalEventWebView('click_clube_uol_info_lp', properties)
  }
  const trackPaymentClubUolModalInvalidCpfApp = (properties: object) => {
    trackGlobalEventWebView('clube_uol_modal_invalid_cpf', properties)
  }
  const trackPaymentModalLinkClickHereClubUolInvalidCpfApp = (
    properties: object
  ) => {
    trackGlobalEventWebView('click_ clube_uol_modal_invalid_cpf_lp', properties)
  }
  const trackPaymentModalLimitCpfClubUolApp = (properties: object) => {
    trackGlobalEventWebView('clube_uol_modal_cpf_exceeded_limit', properties)
  }
  const trackPaymentRemoveDiscountClubUolApp = (properties: object) => {
    trackGlobalEventWebView('click_remove_clube_uol_discount', properties)
  }
  const trackPaymentDiscountApplySuccessfullApp = (properties: object) => {
    trackGlobalEventWebView(
      'click_clube_uol_apply_discount_successfull',
      properties
    )
  }
  const trackPaymentDiscountApplyErrorApp = (properties: object) => {
    trackGlobalEventWebView('click_clube_uol_apply_discount_error', properties)
  }
  const trackPaymentClubUolTooManyAttemptsApp = (properties: object) => {
    trackGlobalEventWebView('click_clube_uol_too_many_attempts', properties)
  }
  const trackPaymentClubUolInvalidRecaptchaApp = (properties: object) => {
    trackGlobalEventWebView('click_clube_uol_invalid_recaptcha', properties)
  }
  // pix
  const trackModalPixEventsApp = (properties: object) => {
    trackGlobalEventWebView('pix_event', properties)
  }
  return {
    trackCheckoutErrorApp,
    trackSeatPickerApp,
    trackTicketTypeApp,
    trackPurchaseResumeApp,
    trackOnPaymentApp,
    trackSeatMapSelectSeatApp,
    trackSeatMapShowSeatNumberApp,
    trackSeatMapZoomApp,
    trackSeatMapNextBtnApp,
    trackBeginCheckoutApp,
    trackAddToCartApp,
    trackTransactionCheckoutApp,
    trackPaymentTypeApp,
    trackPaymentRemoveCardApp,
    trackPaymentNextBtnApp,
    trackPaymentKnowMoreClubUolApp,
    trackPaymentModalLinkClickHereClubUolApp,
    trackPaymentCloseUnderstoodButtonClubUolModalApp,
    trackPaymentCloseButtonInfoClubUolModalApp,
    trackPaymentDiscountApplySuccessfullApp,
    trackPaymentDiscountApplyErrorApp,
    trackPaymentClubUolModalInvalidCpfApp,
    trackPaymentModalLimitCpfClubUolApp,
    trackPaymentModalLinkClickHereClubUolInvalidCpfApp,
    trackPaymentRemoveDiscountClubUolApp,
    trackPaymentClubUolTooManyAttemptsApp,
    trackPaymentClubUolInvalidRecaptchaApp,
    trackModalPixEventsApp
  }
}
