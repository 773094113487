import styled, { css } from 'styled-components'
import animations from '@Styles/animations'

export const SOverlay = styled.div<{ isOpen: boolean }>`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 33;
  align-items: center;
  justify-content: center;
  animation-duration: 100ms;
  background-color: ${(props) => props.theme?.modal?.bgColor};
  ${(props) =>
    !props.isOpen &&
    css`
      height: 0;
      overflow: hidden;
    `}
  ${(props) => (props.isOpen ? animations.fadeIn : animations.fadeOut)}
`

export const SModal = styled.div<{ isOpen: boolean; customWidth: string }>`
  width: 100%;
  margin: 0 16px;
  line-height: 20px;
  max-height: 90%;
  max-width: ${(props) => (props.customWidth ? props.customWidth : '332px')};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  position: relative;
  background-repeat: no-repeat;
  color: ${(props) => props.theme?.modal?.descriptionColor};
  font-size: 14px;
  animation-duration: 100ms;
  background-color: ${(props) => props.theme?.modal?.bgContentColor};
  ${(props) =>
    props.isOpen ? animations.scaleInCenter : animations.scaleOutCenter}
`

export const SCloseButton = styled.div`
  width: 24px;
  height: 24px;
  z-index: 9;
  position: absolute;
  right: 16px;
  top: 16px;
`

export const SModalHeader = styled.div`
  background-image: url(${(props) => props.theme?.modal?.bgImage});
  background-size: contain;
  position: relative;
  width: 100%;
  border-radius: 4px;
`

export const SIconWrapper = styled.div`
  padding: 16px 0;
  width: 100%;
  display: flex;
  justify-content: center;
`

export const STitle = styled.span<{ size: string }>`
  display: block;
  padding: 16px;
  font-size: ${(props) => (props.size ? props.size : '14px;')};
  font-weight: bold;
  text-align: left;
  color: ${(props) => props.theme?.modal?.titleColor};
  background-color: ${(props) => props.theme?.modal?.bgContentColor};
  letter-spacing: 0.4px;
`

export const SDescription = styled.span`
  font-size: 14px;
  font-weight: normal;
  color: ${(props) => props.theme?.modal?.descriptionColor};
`

export const SModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  position: relative;
  font-size: 14px;
  max-height: 80%;
  overflow-y: auto;
  text-align: left;
  font-weight: 400;
`

export const SModalFooter = styled.div<{
  haveTwoButtons: boolean
  invertButtons: boolean
}>`
  display: flex;
  flex-direction: ${(props) => (props?.invertButtons ? 'row-reverse' : 'row')};
  align-items: center;
  justify-content: ${(props) =>
    props?.haveTwoButtons ? 'space-around' : 'center'};
  width: 100%;
  padding: 16px;

  .close-button {
    padding: 2px 16px;
    font-size: 14px;
    margin: 4px;
    max-width: 120px;
  }

  .close-modal18 {
    width: 240px;
  }
  @media (max-width: 768px) {
    flex-direction: ${(props) => props?.invertButtons && 'column'};
    gap: ${(props) => (props?.invertButtons ? '10px' : '0px')};
  }
`

export const SGrandientWithCircle = styled.div`
  width: 100%;
  height: 40px;
  background-color: transparent;
  background-image: -webkit-radial-gradient(
    50% -1px,
    circle,
    transparent 27px,
    ${(props) => props.theme?.modal?.bgContentColor} 0
  );
`

export const SCheckbox = styled.div`
  margin-top: 15px;
`

export const SCustom = styled.div`
  font-size: 14px;
  white-space: pre-line;
  text-align: left;
  color: ${(props) => props.theme?.modal?.descriptionColor};

  h3 {
    font-size: 14px;
    margin-bottom: 15px;
    line-height: 20px;
  }

  p {
    margin-bottom: 15px;
  }

  strong {
    display: block;
    font-size: 14px;
    line-height: 20px;
  }
`
