import { useContextSelector } from 'use-context-selector'
import { GlobalContext } from '@shared/context/Global'

export function useSessionData() {
  const sessionData = useContextSelector(
    GlobalContext,
    (state) => state?.sessionData
  )
  const onSetSessionData = useContextSelector(
    GlobalContext,
    (state) => state?.onSetSessionData
  )

  const paymentAsyncData = useContextSelector(
    GlobalContext,
    (state) => state?.paymentAsyncData
  )
  const onSetPaymentAsyncInfo = useContextSelector(
    GlobalContext,
    (state) => state?.onSetPaymentAsyncInfo
  )

  return {
    sessionData,
    onSetSessionData,
    paymentAsyncData,
    onSetPaymentAsyncInfo
  }
}
