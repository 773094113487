'use client'
import Head from 'next/head'
import { IProps } from './interface'
import Alert from '@Components/Alert'
import Modal from '@Components/Modal'
import Loading from '@Components/Loading'
import Header from '@Components/Header'
import Footer from '@Components/Footer'
import ProgressTab from '@Components/ProgressTab'
import { useModal } from '@shared/hooks/useModal'
import { useAlert } from '@shared/hooks/useAlert'
import { useLoading } from '@shared/hooks/useLoading'
import { useCartIsReady } from '@shared/hooks/useCartIsReady'
import { useCheckout } from '@shared/hooks/useCheckout'
import { ResumeFooter } from '@Components/ResumeFooter'
import useMediaQuery from '@shared/hooks/useMediaQuery'
import * as S from '@Styles/global'
import { usePage } from '@shared/hooks/usePage'
import { useEffect } from 'react'
import { useRouter } from 'next/router'
import CONSTANTS from '@Constants/index'
import { useCart } from '@shared/hooks/useCart'
import { useSessionId } from '@shared/hooks/useSessionId'
import { useWebView } from '@shared/hooks/useWebView'
import schemas from '@Schemas/index'
import CheckoutUtils from '@shared/utils/cart'

const Layout = ({ children, sessionIdUrl, id }: IProps) => {
  const { modal, onSetIsOpenModal } = useModal()
  const { alert } = useAlert()
  const { loading } = useLoading()
  const { cartIsReady } = useCartIsReady()
  const { globalLoading } = useCheckout()
  const { onSetPage } = usePage()
  const router = useRouter()
  const { cart } = useCart()
  const { getUrlParams } = CheckoutUtils()
  const { onSetGlobalLoading } = useCheckout()
  const { onSetSessionId } = useSessionId()
  const { isMobileApp, closeCheckout } = useWebView()

  const queryParams = getUrlParams()

  useEffect(() => {
    const keywordsToPage = {
      assentos: CONSTANTS.PAGE.PAGE_NAME_SEATS,
      bomboniere: CONSTANTS.PAGE.PAGE_NAME_BOMBONIERE,
      ingressos: CONSTANTS.PAGE.PAGE_NAME_TICKETS,
      pagamentos: CONSTANTS.PAGE.PAGE_NAME_PAYMENTS
    }

    const handleRouteChange = (url) => {
      const keywordFound = Object.keys(keywordsToPage).find((keyword) =>
        url.includes(keyword)
      )

      if (keywordFound) {
        onSetPage(keywordsToPage[keywordFound])
      }
    }

    router.events.on('routeChangeComplete', handleRouteChange)
    const excludedPaths = ['concluido', 'adicionar', 'esperaPagamento']
    const excludedQuery = '?sessionId='

    const conditions = {
      sessionIdDifferent: cart && cart?.sessionId !== sessionIdUrl,
      notExcludedPath: !excludedPaths.some((keyword) =>
        router.pathname.includes(keyword)
      ),
      noSessionIdQuery: !router.pathname.includes(excludedQuery)
    }

    if (
      conditions.sessionIdDifferent &&
      conditions.notExcludedPath &&
      conditions.noSessionIdQuery
    ) {
      verifyAddNewSession()
    } else if (
      typeof sessionIdUrl === 'undefined' &&
      Object.keys(router.query).length === 0 &&
      !router.pathname.includes('adicionar')
    ) {
      return onSetIsOpenModal({
        ...schemas.modal.SEATS_SESSION_DOES_NOT_EXIST,
        onConfirm: () => {
          onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
          if (isMobileApp()) closeCheckout()
          window.location.href = process.env.baseUrlCatalog
        },
        onClose: () => {
          onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
        }
      })
    }
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])
  const verifyAddNewSession = async () => {
    if (cart?.sessionId !== sessionIdUrl && cart && sessionIdUrl) {
      onSetGlobalLoading(true)
      onSetSessionId(sessionIdUrl)
      router.push({
        pathname: window?.location?.origin,
        query: {
          sessionId: sessionIdUrl,
          ...queryParams
        }
      })
    }
  }
  const isMobile = useMediaQuery('(max-width: 991px)')

  return (
    <>
      <Head>
        <title>Checkout</title>
      </Head>

      {alert?.isVisible && <Alert {...alert} />}
      {modal?.isOpen && <Modal {...modal} />}

      {cartIsReady && !globalLoading && (
        <>
          <S.Container id={id}>
            {loading && <Loading imagePath="illustrated/loading" />}
            {sessionIdUrl && !isMobileApp() && <Header />}
            <S.Content>
              {sessionIdUrl && <ProgressTab isMobileApp={isMobileApp()} />}
              <S.Body>
                <>
                  {children}
                  {!isMobile && sessionIdUrl && <ResumeFooter />}
                </>
              </S.Body>
              {sessionIdUrl && <Footer sessionId={sessionIdUrl} />}
            </S.Content>
          </S.Container>
        </>
      )}
      {globalLoading && !cartIsReady && (
        <Loading imagePath="illustrated/loading" />
      )}
    </>
  )
}

export default Layout
