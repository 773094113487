import { useAuthToken } from '@shared/hooks/useAuthToken'
import { IProps } from './interface'

import * as S from './styled'
import { useEffect, useRef } from 'react'
import { useLogin } from '@shared/hooks/useLogin'
import { clarity } from 'react-microsoft-clarity'

export const CollapseUser = ({ isOpen, onClose, clearToken }: IProps) => {
  const { authToken } = useAuthToken()
  const { onSetIsOpenLogin } = useLogin()
  const containerRef = useRef(null)
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target) &&
        isOpen
      ) {
        onClose()
      }
    }

    const handleEscapeKey = (event) => {
      if (event.key === 'Escape' && isOpen) {
        onClose()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('keydown', handleEscapeKey)

    try {
      clarity.setTag('user_email', authToken?.email)
      clarity.setTag('user_id', authToken?.user_id ?? authToken?.id)
    } catch (error) {
      console.log(error)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('keydown', handleEscapeKey)
    }
  }, [isOpen, onClose])

  return (
    <S.Container isOpen={isOpen} ref={containerRef}>
      <S.Content isOpen={isOpen}>
        <S.Header>
          {authToken?.user_first_name ? (
            <S.Title>
              {authToken?.type === 'bearer'
                ? `Olá, ${authToken.user_first_name}!`
                : 'Olá, Visitante!'}
            </S.Title>
          ) : (
            <S.Title>Olá, Visitante!</S.Title>
          )}

          {authToken?.email && <S.SubTitle>{authToken?.email}</S.SubTitle>}
        </S.Header>

        {authToken?.access_token && authToken?.type === 'bearer' ? (
          <S.Footer>
            <S.CreateAccoutButton onClick={clearToken}>
              Sair
            </S.CreateAccoutButton>
          </S.Footer>
        ) : (
          <>
            <S.CreateAccoutButton
              onClick={() => {
                onSetIsOpenLogin({ show: true })
                onClose()
              }}
            >
              Entre ou Cadastre-se
            </S.CreateAccoutButton>
          </>
        )}
      </S.Content>
    </S.Container>
  )
}
