import styled, { css } from 'styled-components'

type TResume = {
  disabled?: boolean
}

export const FooterWrapper = styled.footer`
  ${({ theme }) => css`
    position: fixed;
    bottom: 0;
    max-width: 1440px;
    width: 100%;
    background-color: ${theme.footer.bgColor};
    display: flex;
    flex-direction: column;
    letter-spacing: 0.4px;
    background-image: url(${theme.images.footer});
  `}

  @media (min-width: 992px) {
    border-radius: 4px 4px 0 0;
    background-color: ${({ theme }) => theme.footer.bgColor};
  }

  @media (max-width: 1440px) {
    left: 0;
  }
`

export const FooterTickets = styled.div<{ hasSeatSelection: boolean }>`
  background: ${(props) => props.theme?.footer.ticketList.bgColor};
  width: 100%;
  height: 40px;
  opacity: 0.9;
  position: fixed;
  bottom: 55px;
  align-items: center;
  justify-content: center;
  display: ${(props) => (!props.hasSeatSelection ? `none;` : `flex`)};

  @media (min-width: 992px) {
    display: none;
  }
`

export const WarningSessionTimer = styled.div`
  background: #191919;
  width: 100%;
  height: 89px;
  border: 1px solid ${(props) => props.theme?.global.placemark.color};
  color: ${(props) => props.theme?.global.placemark.color};
  font-size: 14px;
  padding: 8px;
  position: absolute;
  bottom: 60px;
  display: none;
  border-radius: 5px;
  &.show-warning-footer {
    display: block;
  }
`
export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 3px;
`
export const HeaderClose = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
  svg path {
    fill: ${(props) => props.theme?.global.placemark.color};
  }
`
export const TextHeader = styled.p`
  margin-left: 6px;
`
export const TextTimer = styled.span`
  font-weight: bold;
`
export const TextBody = styled.p`
  margin-top: 4px;
`
export const Container = styled.div`
  background: #191919;
`

export const FooterTicketsList = styled.ul<{ numberOfSeats: boolean }>`
  display: flex;
  gap: 4px;

  li {
    svg {
      fill: #ccc;

      @media (min-width: 992px) {
        width: 38px;
        height: 38px;
      }
    }

    span {
      color: #ccc;
      text-transform: capitalize;
      ${(props) => props.numberOfSeats && `margin-right: 4px`};

      &.numberLabel {
        display: ${(props) => (props.numberOfSeats ? `none;` : `flex`)};
      }
    }
  }

  @media (min-width: 992px) {
    gap: 16px;
  }
`

export const FooterResume = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  @media (min-width: 992px) {
    height: 67px;
  }
`

export const FooterResumeInfo = styled.div`
  div {
    width: 100%;
    display: flex;
    text-decoration: none;
    cursor: pointer;
  }
`

export const FooterResumeIconWrp = styled.span<TResume>`
  ${({ theme, disabled }) => css`
    width: 30px;
    display: flex;
    align-items: center;

    svg {
      fill: ${disabled
        ? theme?.button?.disabled?.bgColor
        : theme?.summary?.icons?.secondary};
    }
  `}
`

export const FooterResumeInfonWrp = styled.span<TResume>`
  ${({ theme, disabled }) => css`
    width: 100%;
    display: block;
    margin-left: 15px;

    .cont {
      text-align: center;
      font-size: 14px;
      font-weight: normal;
      margin-right: 5px;
      border-radius: 50%;
      background-color: ${disabled
        ? theme?.button?.disabled?.bgColor
        : theme.footer.quantity.bgColor};
      color: ${theme.footer.quantity.color};
      width: 20px;
      height: 20px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }

    .value {
      color: ${theme.footer.value.color};
      font-size: 14px;
      font-weight: bold;
    }
  `}
`

export const FooterButtonWrp = styled.div`
  display: flex;
  align-items: center;

  .btn-footer {
    min-width: 140px;

    @media (min-width: 992px) {
      white-space: nowrap;
    }
  }

  @media (min-width: 992px) {
    button {
      height: 48px;
    }
  }
`

export const TicketsDesktop = styled.div`
  display: none;

  @media (min-width: 992px) {
    display: block;
  }
`

export const Dialog = styled.dialog<{ showModalChallenge: boolean }>`
  visibility: ${(props) => (props.showModalChallenge ? 'visible' : 'hidden')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme?.modal?.bgColor};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;

  #pix-payment {
    overflow-y: scroll;
    padding: 16px;
    background-color: white;
    ::-webkit-scrollbar {
      width: 12px;
    }
  }

  #fingerprint-3ds {
    background-color: ${(props) =>
      props.showModalChallenge ? 'white' : 'transparent'};

    padding: 16px;
  }

  #challenge-3ds {
    background-color: ${(props) =>
      props.showModalChallenge ? 'white' : 'transparent'};
  }
`
export const ApplePayButton = styled.button`
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: buy;
  height: 48px;

  &.apple-pay-button-white {
    --apple-pay-button-width: 130px;

    -apple-pay-button-style: white;
  }

  &.apple-pay-button-black {
    --apple-pay-button-width: 130px;
    -apple-pay-button-style: black;
  }
  @media (max-width: 992px) {
    height: 40px;
  }
`
