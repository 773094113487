import { FormProvider, useForm } from 'react-hook-form'
import Input from '@Components/Input'
import Button from '@Components/Button'
import { validarCPF } from '@Helpers/CPF'
import { useLogin } from '@shared/hooks/useLogin'
import { UseTracking } from '@shared/hooks/useTracking'
import * as S from './styled'
import Icon from '@Components/Icon'
import services from '@Services/index'
import loadDnaUOL from '@shared/scripts/DnaUOL'
import { useLoading } from '@shared/hooks/useLoading'
import { useCookies } from '@shared/hooks/useCookies'

const VerifyAccountStep = ({ recaptchaRef }) => {
  const methods = useForm()
  const { setCookie, getCookie } = useCookies()
  const currentFingerprint = getCookie(process.env.COOKIE_FINGERPRINT)

  const createCookieFingerprint = (fingerprint: string) => {
    const expiration = new Date()
    expiration.setFullYear(expiration.getFullYear() + 1)
    setCookie(process.env.COOKIE_FINGERPRINT, fingerprint, expiration)
  }
  const {
    loginCurrentStep,
    onSetCurrentLoginStepNumberAndPage,
    setEmailOrCpfValueContext,
    emailOrCpfValueContext,
    setHasAccount
  } = useLogin()
  const { handleSubmit, getValues, setError } = methods
  const inputValue = getValues('emailOrCpf')
  const { trackLoginVerifyAccount, trackNewsLetterIngresso } = UseTracking()
  const { onSetIsLoadingButton, loadingButton } = useLoading()

  const onSubmit = async () => {
    onSetIsLoadingButton(true)
    trackLoginVerifyAccount({
      event: 'gaEvent',
      e_event: 'click_next',
      e_action: emailOrCpfValueContext.indexOf('@') == -1 ? 'cpf' : 'email',
      e_label: 'Verify Account modal',
      e_description: ''
    })
    const emailRegex = /\S+@\S+\.\S+/
    const reCAPTCHAToken = await recaptchaRef?.current?.executeAsync()

    const setUserTrackingOptin = (existAccount: boolean) => {
      setHasAccount(existAccount)
      const user = {
        user: {
          optin: existAccount ? 'Y' : 'N'
        }
      }
      trackNewsLetterIngresso(user)
    }

    if (emailRegex.test(inputValue) && reCAPTCHAToken) {
      const payload = {
        UserName: inputValue.toLowerCase(),
        reCAPTCHAToken,
        clientOrigin: 'Site'
      }
      const [status, error] = await services.users.verifyUser(
        payload,
        'validate-username'
      )

      if (status === 200) {
        setUserTrackingOptin(true)
        setEmailOrCpfValueContext(inputValue?.toLowerCase())
        if (!currentFingerprint) {
          loadDnaUOL((dna) => dna && createCookieFingerprint(dna))
        }
        onSetCurrentLoginStepNumberAndPage(2, 1)
      } else if (error?.status === 404) {
        setUserTrackingOptin(false)
        setEmailOrCpfValueContext(inputValue)
        setUserTrackingOptin(false)

        onSetCurrentLoginStepNumberAndPage(2, 2)
      } else if (error?.modelState?.userName || error?.status === 400) {
        setError('emailOrCpf', {
          message: error?.data.modelState?.userName[0]
        })
      } else {
        setError('emailOrCpf', {
          message: 'Oops, erro interno do servidor.'
        })
      }
    } else if (validarCPF(inputValue)) {
      if (reCAPTCHAToken && inputValue.replace(/[^\d]+/g, '').length === 11) {
        const payload = {
          UserName: inputValue,
          reCAPTCHAToken,
          clientOrigin: 'Site'
        }
        const [status, error] = await services.users.verifyUser(
          payload,
          'validate-username'
        )
        if (status === 200) {
          setUserTrackingOptin(true)
          setEmailOrCpfValueContext(inputValue?.toLowerCase())
          if (!currentFingerprint) {
            loadDnaUOL((dna) => dna && createCookieFingerprint(dna))
          }
          onSetCurrentLoginStepNumberAndPage(2, 1)
        } else if (error?.status === 400 || error?.status === 404) {
          setUserTrackingOptin(false)
          setEmailOrCpfValueContext(inputValue)
          onSetCurrentLoginStepNumberAndPage(2, 2)
        } else if (error?.modelState?.userName) {
          setError('emailOrCpf', {
            message: error?.data.modelState?.userName[0]
          })
        } else {
          setError('emailOrCpf', {
            message: 'Oops, erro interno do servidor.'
          })
        }
      }
    }
    onSetIsLoadingButton(false)
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset>
          {loginCurrentStep?.inputs?.map((inputItem) => (
            <Input
              value={emailOrCpfValueContext || ''}
              autoFocus
              key={inputItem.id}
              disabled={false}
              label={inputItem?.label}
              maxLength={256}
              typeStopInteractionSuccess={inputItem.typeStopInteraction}
              rule={(value) => {
                const emailRegex = /\S+@\S+\.\S+/
                return emailRegex.test(value) || validarCPF(value)
              }}
              externalOnChange={(e) =>
                setEmailOrCpfValueContext(e.toLowerCase())
              }
              inputName="emailOrCpf"
              validation={inputItem?.validation}
              lowerCase={inputItem?.lowerCase}
            />
          ))}
        </fieldset>
        <S.ContainerButton>
          <Button
            isInitialPositionIcon={
              loginCurrentStep?.button?.isInitialPositionIcon
            }
            height={
              loginCurrentStep?.button?.height
                ? loginCurrentStep?.button?.height
                : '48px'
            }
            className="main-button"
            onClick={() => onSubmit}
            loading={loadingButton}
            iconLeft={
              loginCurrentStep?.button?.icon ? (
                <Icon
                  color="#460505"
                  src={
                    loginCurrentStep?.button.icon
                      ? loginCurrentStep?.button?.icon
                      : ''
                  }
                  width={24}
                  height={24}
                />
              ) : null
            }
          >
            {loginCurrentStep?.button?.label}
          </Button>
        </S.ContainerButton>
      </form>
    </FormProvider>
  )
}

export default VerifyAccountStep
