import React from 'react'

import * as S from './styled'
interface IProp {
  variant: string
}
const loadingSpin = (prop: IProp) => {
  return (
    <S.LoadingContainer variant={prop.variant}>
      <S.LoadingSpinItem variant={prop.variant} />
    </S.LoadingContainer>
  )
}

export default loadingSpin
