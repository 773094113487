import React from 'react'

import { IProps } from './interface'
import { SDescriptionRegular } from './styled'

const DescriptionRegular = ({ children, className }: IProps) => {
  return (
    <SDescriptionRegular className={className}>{children}</SDescriptionRegular>
  )
}

export default DescriptionRegular
