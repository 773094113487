import React from 'react'

import Icon from '@Components/Icon'
import Text from '@Components/Text'

import * as S from './styled'

const Benefits = () => {
  return (
    <S.BenefitsWrp>
      <Text
        type="HEADLINE"
        text="Vantagens de ser um cliente Ingresso.com"
        className="benefits-title"
      />

      <S.BenefitsList>
        <S.BenefitsItem>
          <Icon
            src="illustrated/cardAlert2"
            color="#fff"
            size={22}
            className="benefits-icon"
          />
          <Text
            type="HEADLINE"
            text="Salvar cartões"
            className="benefits-text"
          />
        </S.BenefitsItem>
        <S.BenefitsItem>
          <Icon
            src="illustrated/ticketPlus"
            color="#fff"
            size={22}
            className="benefits-icon"
          />
          <Text
            type="HEADLINE"
            text="Cancelar pedidos"
            className="benefits-text"
          />
        </S.BenefitsItem>
        <S.BenefitsItem>
          <Icon
            src="illustrated/star"
            color="#fff"
            size={22}
            className="benefits-icon"
          />
          <Text
            type="HEADLINE"
            text="Favoritar cinemas"
            className="benefits-text"
          />
        </S.BenefitsItem>
        <S.BenefitsItem>
          <Icon
            src="illustrated/phone"
            color="#fff"
            size={16}
            className="benefits-icon"
          />
          <Text
            type="HEADLINE"
            text="Retirar pelo App"
            className="benefits-text"
          />
        </S.BenefitsItem>
      </S.BenefitsList>
    </S.BenefitsWrp>
  )
}

export default Benefits
