import HeadlineBold from '@Components/Text/SubtitleBold'
import ParagraphRegular from '@Components/Text/ParagraphRegular'
import DescriptionRegular from '@Components/Text/DescriptionRegular'
import CaptionRegular from '@Components/Text/CaptionRegular'
import ParagraphBold from '@Components/Text/ParagraphBold'
import SubtitleBold from '@Components/Text/SubtitleBold'
import Icon from '@Components/Icon'

import { IProps } from './interface'

import { TitleWithIconContainer } from '@Components/Text/styled'

const Text = ({
  type = 'REGULAR',
  text,
  icon,
  size = 24,
  className,
  iconColor,
  color
}: IProps) => {
  const objFontComponents = {
    HEADLINE: (
      <HeadlineBold className={className} color={color}>
        {text}
      </HeadlineBold>
    ),
    SUBTITLE: <SubtitleBold className={className}>{text} </SubtitleBold>,
    REGULAR: <ParagraphRegular className={className}>{text} </ParagraphRegular>,
    BOLD: <ParagraphBold className={className}>{text} </ParagraphBold>,
    DESCRIPTION: (
      <DescriptionRegular className={className}>{text} </DescriptionRegular>
    ),
    CAPTION: <CaptionRegular className={className}>{text} </CaptionRegular>
  }

  if (icon) {
    return (
      <TitleWithIconContainer>
        <Icon
          src={`icons/${icon}`}
          className={className}
          width={size}
          color={iconColor}
        />
        {objFontComponents[type]}
      </TitleWithIconContainer>
    )
  }
  return <>{objFontComponents[type]}</>
}

export default Text
