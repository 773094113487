import styled, { css } from 'styled-components'
import { IReactInputVerificationCodeProps } from '.'

type TInputType = Pick<IReactInputVerificationCodeProps, 'length'>

const modifierInput = {
  4: () => css`
    width: 56px;
    height: 72px;
  `,
  6: () => css`
    width: 32px;
    height: 44px;
  `
}

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 1rem;
    align-items: center;
    span {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: ${theme.button.primary.bgColor};
      cursor: pointer;
      margin-top: 24px;
    }
  `}
`
export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 1rem;
    span {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: ${theme.button.primary.bgColor};
      cursor: pointer;
      margin-top: 24px;
    }
  `}
`

export const Wrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
`
export const Input = styled.input<TInputType>`
  ${({ theme, length }) => css`
    ${!!length && modifierInput[length]()}
    border-radius: 4px;
    font-size: 1.2rem;
    text-align: center;
    border: 0;
    color: #555;
    box-shadow: 0 0 0 1px ${theme.modal.bgColor};
    outline: 0;
    transition: box-shadow 0.2s ease-out;
    &:focus {
      box-shadow: 0 0 0 2px ${theme.modal.bgContentColor};
    }
    -webkit-appearance: none;
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  `}
`
