import { GlobalContext } from '@shared/context/Global'
import { useContextSelector } from 'use-context-selector'

export function useSeats() {
  const seats = useContextSelector(GlobalContext, (state) => state?.seats)
  const onSetSeats = useContextSelector(
    GlobalContext,
    (state) => state?.onSetSeats
  )
  const onSetShowCaptchaSeatMap = useContextSelector(
    GlobalContext,
    (state) => state?.onSetShowCaptchaSeatMap
  )
  const showCaptchaSeatMap = useContextSelector(
    GlobalContext,
    (state) => state?.showCaptchaSeatMap
  )
  const onSetAssociatedSeats = useContextSelector(
    GlobalContext,
    (state) => state?.onSetAssociatedSeats
  )
  const associatedSeats = useContextSelector(
    GlobalContext,
    (state) => state?.associatedSeats
  )

  return {
    seats,
    onSetSeats,
    onSetAssociatedSeats,
    associatedSeats,
    onSetShowCaptchaSeatMap,
    showCaptchaSeatMap
  }
}
