import { ticketingAPI } from 'src/shared/request/Api'
import { handleError } from '@shared/request/error'
import endpoints from 'src/shared/endpoints'
import { TProduct } from '@Templates/Bomboniere/interface'
import { getAuthTypeHeader } from '../utils/helpers/headers'

export const READ = async (sessionId: string, promotion: string) => {
  try {
    const { data } = await ticketingAPI.get(
      endpoints.bomboniereEndpoint.SESSIONS_BOMBONIERE.get(sessionId),
      {
        params: {
          promotion: promotion
        }
      }
    )
    return [data, null]
  } catch (error) {
    return [null, handleError(error)]
  }
}
export const postProducts = async (
  sessionId: string,
  cartId: string,
  payload: TProduct[]
) => {
  try {
    const { data } =
      typeof getAuthTypeHeader() !== null
        ? await ticketingAPI.post(
            endpoints.bomboniereEndpoint.SESSIONS_BOMBONIERE.post(
              sessionId,
              cartId
            ),
            payload,
            {
              headers: getAuthTypeHeader()
            }
          )
        : await ticketingAPI.post(
            endpoints.bomboniereEndpoint.SESSIONS_BOMBONIERE.post(
              sessionId,
              cartId
            ),
            payload
          )
    return [data, null]
  } catch (error) {
    return [null, handleError(error)]
  }
}
