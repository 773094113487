import styled, { css } from 'styled-components'
import animations from '@Styles/animations'
import { TResume } from './types'

type TCard = Pick<TResume, 'isOpen' | 'p' | 'isLogin'>

export const Wrapper = styled.div`
  ${() => css`
    width: 100%;
    height: 100%;
    background: #000000b2;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    display: -webkit-flex;
    display: flex;
    align-items: flex-end;
    -webkit-align-items: flex-end;
  `}
`

const cardModifier = {
  S: () => css`
    padding: 10px;
  `,
  M: () => css`
    padding: 16px;
  `,
  L: () => css`
    padding: 24px;
    height: calc(100% - 80px);
    max-height: 100%;
  `
}

export const CardContainer = styled.div<TCard>`
  ${({ theme, isOpen, p, isLogin }) => css`
    background-color: ${isLogin ? theme.login.bgColor : theme.summary.bgColor};
    border-radius: 24px 24px 0 0;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 90%;
    width: 100%;
    overscroll-behavior: contain;
    -webkit-overflow-scrolling: touch;
    animation-duration: 400ms;
    ${isOpen && animations.slideInBottom}
    ${!isOpen && animations.slideOutBottom}
    ${!!p && cardModifier[p]()}

    @media (min-width: 992px) {
      overflow-y: unset;
      overflow-x: unset;
      padding: 0;
      position: absolute;
      height: 100%;
      width: 850px;
      right: 0;
      border-radius: 0;
      ${isOpen && animations.slideInRight}
    }
  `}
`

export const LoginBody = styled.div<{ backgroundUrl: string }>`
  @media (min-width: 992px) {
    background: ${(props) => `url(${props.backgroundUrl}) no-repeat right top`};
    object-fit: contain;
    height: 100%;

    :after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background: linear-gradient(
        360deg,
        #191919 7.29%,
        rgba(25, 25, 25, 0) 36.98%
      );
    }

    :before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background: linear-gradient(
        90deg,
        #191919 30.73%,
        rgba(25, 25, 25, 0) 71.88%
      );
    }
  }
`

export const LoginHeader = styled.div`
  display: none;

  @media (min-width: 992px) {
    display: flex;
    justify-content: space-between;
    color: #fff;
    padding: 16px;
    background-color: #191919;
    position: relative;
    z-index: 1;
  }
`

export const LoginContainer = styled.div`
  display: flex;

  align-items: center;
  /* margin-bottom: 50px; */
  gap: 8px;
`

export const CloseContainer = styled.div`
  svg {
    fill: #0099ff;
    cursor: pointer;

    :hover {
      opacity: 0.8;
    }
  }
`

export const Card = styled.div`
  background-color: ${(props) => props.theme.login.bgColor};
  @media (min-width: 992px) {
    width: 400px;
    border-radius: 16px;
    padding: 0 64px;
    margin: 40px 0 0 40px;
    position: absolute;
    z-index: 1;
    overflow-y: auto;
  }
  @media (min-width: 992px) and (max-width: 1440px) {
    width: 400px;
    overflow: auto;
  }
`
