import React from 'react'

import { IProps } from './interface'
import { SParagraphBold } from './styled'

const ParagraphBold = ({ children, className }: IProps) => {
  return <SParagraphBold className={className}>{children}</SParagraphBold>
}

export default ParagraphBold
