import { FormProvider, useForm } from 'react-hook-form'
import Input from '@Components/Input'
import Button from '@Components/Button'
import { useLogin } from '@shared/hooks/useLogin'
import Icon from '@Components/Icon'
import * as S from './styled'
import { handleCpfEmail } from '@Helpers/others'
import {
  inputsLoginCreateAccountEmailStepTwo,
  inputsLoginCreateAccountCpfStepTwo
} from '../steps'
import { getPolicies } from '@Services/users'
import services from '@Services/index'
import { useModal } from '@shared/hooks/useModal'
import { VerifyCode } from '@Components/VerifyCode'
import { useAuthToken } from '@shared/hooks/useAuthToken'

import { useEffect } from 'react'
import { useLoading } from '@shared/hooks/useLoading'
import schemas from '@Schemas/index'
import CheckoutUtils from '@shared/utils/cart'
import { useCookies } from '@shared/hooks/useCookies'
import { UseTracking } from '@shared/hooks/useTracking'
import { useWebView } from '@shared/hooks/useWebView'

const CreateAccountStepTwo = ({
  recaptchaRef,
  recaptchaRefV3,
  canGoPayment
}) => {
  const methods = useForm()
  const { getPartnerShipName, isPoweredByAndShareData } = CheckoutUtils()
  const { setCookie } = useCookies()
  const {
    loginCurrentStep,
    onSetCurrentLoginStepNumberAndPage,
    emailOrCpfValueContext,
    dataFromCreateAccountStepOne,
    policiesFromContext,
    setPoliciesContext,
    setVerifyCodeValue,
    setOpenVerifyCode,
    openVerifyCode,
    verifyCodeValue,
    errorDescriptionToken,
    setErrorDescriptionToken,
    onSetIsOpenCollapseUser,
    onSetIsOpenLogin,
    openLogin
  } = useLogin()

  const { handleSubmit, watch, getValues, setError, register } = methods
  const { onSetIsOpenModal } = useModal()
  const { onSetAuthToken } = useAuthToken()
  const { onSetIsLoadingButton, loadingButton } = useLoading()
  const { trackNewsLetterIngresso } = UseTracking()
  const { getClientOrigin } = useWebView()
  const passwordInput = watch('password')
  const emailRegex = /\S+@\S+\.\S+/

  const isEmail = emailRegex.test(emailOrCpfValueContext)
  const getPoliciesFromApi = async () => {
    if (!policiesFromContext) {
      try {
        const policiesData = await getPolicies()
        setPoliciesContext(policiesData[1])
      } catch (error) {
        console.log(error)
      }
    }
  }

  const handleContinue = (verifyCode: boolean, openVerify: boolean) => {
    setVerifyCodeValue(verifyCode)
    setOpenVerifyCode(openVerify)
  }
  const handleSendCode = async () => {
    onSetIsLoadingButton(true)
    const reCAPTCHAToken = await recaptchaRefV3.current.executeAsync()

    if (reCAPTCHAToken) {
      const payload = {
        username: isEmail
          ? dataFromCreateAccountStepOne[2]
              ?.replace(/-/g, '')
              .split('.')
              .join('')
          : getValues('emailOrCpf')
              ?.toLowerCase()
              ?.replace(/-/g, '')
              .split('.')
              .join(''),
        grant_type: 'resend_verification_code_email',
        client_origin: getClientOrigin()
      }

      const { errorMessage, status } = await services.token.getToken(payload)

      if (errorMessage === 'Código reenviado.' && status === 400)
        handleContinue(true, true)
      setErrorDescriptionToken('')
    }
    onSetIsLoadingButton(false)
  }
  const handleVerifyCode = async (e) => {
    onSetIsLoadingButton(true)
    const reCAPTCHAToken = await recaptchaRefV3.current.executeAsync()

    if (reCAPTCHAToken) {
      const payload = {
        username: isEmail
          ? dataFromCreateAccountStepOne[2]
              ?.toLowerCase()
              ?.replace(/-/g, '')
              .split('.')
              .join('')
          : getValues('emailOrCpf')
              ?.toLowerCase()
              ?.replace(/-/g, '')
              .split('.')
              .join(''),
        password: getValues('password'),
        reCAPTCHAToken,
        grant_type: 'verification_code',
        client_origin: getClientOrigin(),
        verificationCode: e,
        fingerprint: ''
      }

      const { data, status, errorMessage, errorType } =
        await services.token.getToken(payload)

      if (status === 400 && errorType === 'invalid_grant') {
        setErrorDescriptionToken(errorMessage)
      }
      if (status === 200) {
        if (data) {
          const payload = {
            access_token: data.access_token,
            user_first_name: data.user_first_name,
            user_id: data.user_id,
            cpf: data.user_cpf,
            email: data.user_email,
            id: data.user_id,
            loginType: data.login_type,
            refresh_token: data.refresh_token,
            client_origin: data.client_origin,
            expires_in: data.expires_in,
            issued: data['.issued'],
            type: data.token_type
          }
          const user = {
            user: {
              optin: getValues('emailOptIn') ? 'Y' : '',
              optinPartnership: getValues('newsletterpoweredby') ? 'Y' : ''
            }
          }
          trackNewsLetterIngresso(user)
          onSetIsOpenModal({ onClose: () => setOpenVerifyCode(false) })
          if (isPoweredByAndShareData())
            setCookie(
              process.env.cookieNewsLetterPoweredBy,
              getValues(process.env.cookieNewsLetterPoweredBy)
            )
          onSetAuthToken(payload, true)
          onSetIsOpenCollapseUser(true)
          if (openLogin.onNextPage) canGoPayment()
        }
        onSetIsOpenLogin({ show: false })
      }
    }
    onSetIsLoadingButton(false)
  }

  const onSubmit = async (data) => {
    onSetIsLoadingButton(true)
    await getPoliciesFromApi()
    const reCAPTCHAToken = await recaptchaRef.current.executeAsync()
    if (
      isEmail &&
      getValues('email2').toLowerCase() !==
        getValues('emailOrCpf').toLowerCase()
    ) {
      setError('email2', {
        message: 'Esse campo deve ser igual ao de e-mail.'
      })
    } else {
      if (
        !isEmail &&
        getValues('email2').toLowerCase() !== getValues('email').toLowerCase()
      ) {
        setError('email2', {
          message: 'Esse campo deve ser igual ao de e-mail.'
        })
      } else {
        const payload = {
          account: {
            name: dataFromCreateAccountStepOne[1],
            cpf: isEmail
              ? dataFromCreateAccountStepOne[2]
                  ?.toLowerCase()
                  ?.replace(/-/g, '')
                  .split('.')
                  .join('')
              : data['emailOrCpf']
                  ?.toLowerCase()
                  ?.replace(/-/g, '')
                  .split('.')
                  .join(''),
            emailOptIn: getValues('emailOptIn')
          },
          createdBy: 'Frontend',
          userName: isEmail ? data['emailOrCpf'].toLowerCase() : data['email'],
          emailConfirmation: data['email2'].toLowerCase(),
          password: data['password'],
          policies: policiesFromContext,
          privacyPolicy: policiesFromContext?.find(
            (policy) => policy.policyDescription === 'PrivacyPolicy'
          )?.enabled,
          socialLogins: [],
          reCAPTCHAToken: reCAPTCHAToken,
          client_origin: getClientOrigin(),
          googleId: '',
          googleToken: '',
          picture: ''
        }
        const response = await services.users.createUser(payload)
        if (response.status === 200 || response.status === 201) {
          setOpenVerifyCode(true)
        } else {
          if (isEmail) {
            if (
              response.modelState &&
              response.modelState.userName &&
              response.modelState.userName[0]
            ) {
              setError('emailOrCpf', {
                message: response.modelState.userName[0]
              })
            } else if (
              response.modelState &&
              response.modelState?.['account.cPF']
            ) {
              onSetIsOpenModal({
                ...schemas.modal.VALIDATION_CPF_ACCOUNT_ERROR(
                  response.modelState?.['account.cPF'][0]
                ),
                onClose: () => {
                  onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
                },
                onConfirm: () => {
                  onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
                }
              })
            }
          } else {
            if (response.modelState && response.modelState?.['account.cPF']) {
              setError('emailOrCpf', {
                message: response.modelState?.['account.cPF'][0]
              })
            } else if (
              response.modelState &&
              response.modelState.userName &&
              response.modelState.userName[0]
            ) {
              setError('email', {
                message: response.modelState.userName[0]
              })
            }
          }
        }
      }
    }
    onSetIsLoadingButton(false)
  }
  useEffect(() => {
    onSetIsOpenModal({
      isOpen: openVerifyCode,
      onClose: () => setOpenVerifyCode(false),
      title: !verifyCodeValue ? 'Falta pouco!' : 'Não chegou?',
      description: !verifyCodeValue
        ? 'Enviamos um código de verificação para o seu e-mail. Digite-o abaixo:'
        : 'Pronto! Reenviamos para seu e-mail cadastrado um novo código de confirmação. Verifique novamente a sua caixa de e-mail. Lembre-se também de verificar a sua caixa de SPAM.',
      iconModal: !verifyCodeValue ? 'illustrated/email' : 'illustrated/lock',
      customContent: (
        <S.VerifyCodeContainer>
          <S.ContentVerifyCode>
            {!verifyCodeValue && (
              <>
                <VerifyCode length={6} onCompleted={handleVerifyCode} />
                {errorDescriptionToken && (
                  <S.WarningContainerMain>
                    <Icon
                      color="#FF5A50"
                      src="icons/warning"
                      width={20}
                      height={20}
                    />
                    <S.ErrorMessage>{errorDescriptionToken}</S.ErrorMessage>
                  </S.WarningContainerMain>
                )}
                <S.SendCode onClick={handleSendCode}>
                  Reenviar código
                </S.SendCode>
              </>
            )}
          </S.ContentVerifyCode>
          <S.Go onClick={() => handleContinue(false, true)}>Continuar</S.Go>
        </S.VerifyCodeContainer>
      )
    })
  }, [openVerifyCode, verifyCodeValue, errorDescriptionToken])

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="none">
        <fieldset>
          {isEmail
            ? inputsLoginCreateAccountEmailStepTwo.map((inputItem) => (
                <S.ContainerInput key={inputItem.id}>
                  <Input
                    lowerCase={inputItem?.lowerCase}
                    initialValue={
                      inputItem.id === 1 ? emailOrCpfValueContext : ''
                    }
                    key={inputItem.id}
                    label={
                      inputItem.id === 1
                        ? handleCpfEmail(emailOrCpfValueContext).label
                        : inputItem.label
                    }
                    placeholder={inputItem.placeholder}
                    rule={inputItem.valid}
                    handleClick={() => onSetCurrentLoginStepNumberAndPage(1, 1)}
                    disabled={false}
                    autoComplete={'none'}
                    change={inputItem?.change}
                    applyMaskOnlyThisConditionIsTrue={
                      inputItem.applyMaskOnlyThisConditionIsTrue
                    }
                    inputName={inputItem?.inputName}
                    onPaste={inputItem.onPaste}
                    validation={inputItem?.validation}
                    pass={inputItem.pass}
                    maxLength={256}
                  />
                  <S.passwordValidations>
                    {inputItem?.helpText}
                  </S.passwordValidations>

                  <S.ValidationInput>
                    {inputItem.validationsPassword?.map(
                      (validationsPassword) => {
                        return (
                          <li key={validationsPassword.name}>
                            <Icon
                              src="icons/checked"
                              size={8}
                              color={
                                validationsPassword?.valid(
                                  passwordInput ? passwordInput : ''
                                )
                                  ? '#00cc00'
                                  : '#ccc'
                              }
                            />
                            {validationsPassword.name}
                          </li>
                        )
                      }
                    )}
                  </S.ValidationInput>
                </S.ContainerInput>
              ))
            : inputsLoginCreateAccountCpfStepTwo.map((inputItem) => (
                <S.ContainerInput key={inputItem.id}>
                  <Input
                    initialValue={
                      inputItem.id === 1 ? emailOrCpfValueContext : ''
                    }
                    lowerCase={inputItem?.lowerCase}
                    key={inputItem.id}
                    label={
                      inputItem.id === 1
                        ? handleCpfEmail(emailOrCpfValueContext).label
                        : inputItem.label
                    }
                    type="text"
                    autoComplete={'none'}
                    placeholder={inputItem.placeholder}
                    handleClick={() => onSetCurrentLoginStepNumberAndPage(1, 1)}
                    onPaste={inputItem.onPaste}
                    rule={inputItem.valid}
                    disabled={false}
                    change={inputItem?.change}
                    inputName={inputItem?.inputName}
                    validation={inputItem?.validation}
                    pass={inputItem.pass}
                    maxLength={256}
                  />
                  <S.passwordValidations>
                    {inputItem?.helpText}
                  </S.passwordValidations>

                  <S.ValidationInput>
                    {inputItem?.validationsPassword?.map(
                      (validationsPassword) => {
                        return (
                          <li key={validationsPassword.name}>
                            <Icon
                              src="icons/checked"
                              size={8}
                              color={
                                validationsPassword?.valid(
                                  passwordInput ? passwordInput : ''
                                )
                                  ? '#00cc00'
                                  : '#ccc'
                              }
                            />
                            {validationsPassword.name}
                          </li>
                        )
                      }
                    )}
                  </S.ValidationInput>
                </S.ContainerInput>
              ))}
        </fieldset>
        <S.Newsletter>
          <input
            name="emailOptIn"
            type="checkbox"
            {...register('emailOptIn', {})}
          />
          <span>Quero receber novidades e mensagens da Ingresso.com.</span>
        </S.Newsletter>
        {isPoweredByAndShareData() && (
          <S.NewsLetterPoweredBy>
            <input
              name="newsletterpoweredby"
              type="checkbox"
              {...register('newsletterpoweredby', {})}
            />
            <p>Quero receber novidades e mensagens do {getPartnerShipName()}</p>
          </S.NewsLetterPoweredBy>
        )}

        <S.HelpContainer>
          <span>Ao continuar, aceito os </span>
          <a target="_blank" href={process.env.termsOfUse} rel="noreferrer">
            Termos de Uso
          </a>{' '}
          <span>e</span>
          <a target="_blank" href={process.env.PrivacyPolicy} rel="noreferrer">
            {' '}
            Política de Privacidade.
          </a>
        </S.HelpContainer>
        <S.HelpContainer>
          <span>Deseja excluir sua conta? Siga os </span>
          <a
            target="_blank"
            href={process.env.deleteAccountLink}
            rel="noreferrer"
          >
            passos a seguir
          </a>
        </S.HelpContainer>
        <S.ContainerButton>
          <Button
            isInitialPositionIcon={
              loginCurrentStep?.button?.isInitialPositionIcon
            }
            height={
              loginCurrentStep?.button?.height
                ? loginCurrentStep?.button?.height
                : '48px'
            }
            className="main-button"
            loading={loadingButton}
            onClick={() => onSetCurrentLoginStepNumberAndPage(4, 3)}
            iconLeft={
              loginCurrentStep?.button?.icon ? (
                <Icon
                  color="#460505"
                  src={
                    loginCurrentStep?.button.icon
                      ? loginCurrentStep?.button?.icon
                      : ''
                  }
                  width={24}
                  height={24}
                />
              ) : null
            }
          >
            {loginCurrentStep?.button?.label}
          </Button>
        </S.ContainerButton>
      </form>
    </FormProvider>
  )
}

export default CreateAccountStepTwo
