import React from 'react'

import { SRadio, SLabel, SContainer } from './styled'
import { IProps } from './interface'

const Radio = ({
  id,
  isChecked,
  onClick,
  label,
  disabled,
  boxSize,
  unit,
  name = '',
  colorLabel
}: IProps) => {
  return (
    <>
      <input type="radio" id={id} name={name} hidden={true} />
      <SContainer htmlFor={id} onClick={onClick} disabled={disabled}>
        <SRadio
          disabled={disabled}
          checked={isChecked}
          boxSize={boxSize}
          unit={unit}
        />
        {label && (
          <SLabel disabled={disabled} colorLabel={colorLabel}>
            {label}
          </SLabel>
        )}
      </SContainer>
    </>
  )
}

export const defaultProps = {
  isChecked: false,
  disabled: false,
  boxSize: 24,
  unit: 'px',
  id: 'Radio'
}

Radio.defaultProps = defaultProps

export default Radio
