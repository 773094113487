import styled from 'styled-components'

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 8px;
`

export const Title = styled.div`
  display: flex;
  color: ${({ theme }) => theme?.ticketBox?.nameColor};
  font-weight: bold;
  gap: 4px;
`

export const Details = styled.a`
  display: inline-flex;
  align-items: baseline;
  gap: 4px;
  @media (min-width: 992px) {
    font-size: 12px;
  }
  line-height: 8px;
`

export const Icon = styled.div`
  @media (min-width: 992px) {
    padding-top: 4px;
  }
`

export const SessionTags = styled.p`
  background-color: #3255e2;
  display: inline-block;
  color: #f0f0f0 !important;
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 12px;
  padding: 2px 8px 0px 8px;
  height: 18px;
`

export const MovieSessionContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 4px;
`
export const EventLocation = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 8px 0;
  color: #ffffff;
  color: #8899aa;
  font-size: 12px;
  color: ${({ theme }) => theme?.ticketBox?.nameColor};
`
export const City = styled.p`
  @media (min-width: 992px) {
    font-size: 12px;
  }
`
export const Theater = styled.p`
  @media (min-width: 992px) {
    font-size: 14px;
  }
`
export const SubtitleWrp = styled.div`
  background: ${({ theme }) => theme?.subtitleSeats?.bgColor};
  padding-top: 16px;
  @media (min-width: 992px) {
    padding: 32px 16px 16px;
    background: none;
  }
`
