import { authAPI, ticketingAPI } from 'src/shared/request/Api'
import endpoints from 'src/shared/endpoints'

export const verifyUser = async (value: {}, endpoint: string) => {
  try {
    const { url, fields } = endpoints.usersEndpoint.USERS_SESSIONS.post(
      value,
      endpoint
    )
    const { status } = await authAPI.post(url, fields)
    return [status, null]
  } catch (error) {
    return [null, error?.response]
  }
}
export const registerUserNewsletter = async (value: {}, endpoint: string) => {
  try {
    const { url, fields } = endpoints.usersEndpoint.USERS_SESSIONS.post(
      value,
      endpoint
    )
    const { status } = await authAPI.post(url, fields)
    return [status, null]
  } catch (error) {
    return [null, error?.response]
  }
}
export const checkDuplicateOrder = async (
  userId: string,
  sessionId: string
) => {
  try {
    const url = endpoints.usersEndpoint.USERS_SESSIONS.get(
      userId,
      sessionId,
      'duplicated-orders'
    )
    const { data } = await ticketingAPI.get(url)
    return [data, null]
  } catch (error) {
    return [null, error?.response]
  }
}
export const resetPassword = async (value: {}, endpoint: string) => {
  try {
    const { url, fields } = endpoints.usersEndpoint.USERS_SESSIONS.post(
      value,
      endpoint
    )
    const { status } = await authAPI.post(url, fields)
    return [status, null]
  } catch (error) {
    return [null, error?.response]
  }
}

export const createUser = async (registerObject) => {
  try {
    const { url, fields } =
      endpoints.usersEndpoint.USERS_SESSIONS.create(registerObject)
    const { status } = await authAPI.post(url, fields, {
      headers: {
        'x-confirmation-type': 'Code'
      }
    })
    return { status }
  } catch (error) {
    return {
      status: error?.response?.status,
      modelState: error?.response?.data?.modelState
    }
  }
}

export const getPolicies = async () => {
  try {
    const url = endpoints.usersEndpoint.USERS_SESSIONS.getPolices()
    const { status, data } = await authAPI.get(url)
    return [status, data]
  } catch (error) {
    return [null, error?.response]
  }
}

export const getSavedCard = async (userId: string) => {
  try {
    const url = endpoints.usersEndpoint.USERS_SESSIONS.getSavedCard(userId)
    const { data } = await authAPI.get(url)
    return [data]
  } catch (error) {
    return [null, error?.response]
  }
}

export const getUserLogged = async (userId: string) => {
  try {
    const url = endpoints.usersEndpoint.USERS_SESSIONS.getUserLogged(userId)
    const { data } = await authAPI.get(url)
    return [data]
  } catch (error) {
    return [null, error?.response]
  }
}
