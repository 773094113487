export const TICKETING_HOST = process.env.baseUrlApiTicketing
export const SESSIONS_SECTIONS_SEATS = {
  get: (sessionId: string | string[], sectionId: string) => {
    return `/sessions/${sessionId}/sections/${sectionId}/seats`
  }
}

export const CARTS_SESSIONS_SECTIONS_SEATS = {
  get: (cartId: string, sessionId: string, sectionId: string) => {
    return `${TICKETING_HOST}/carts/${cartId}/sessions/${sessionId}/sections/${sectionId}/seats`
  }
}

export const SESSIONS_INFO = {
  get: (sessionId: string | string[]) => {
    return `${TICKETING_HOST}/sessions/${sessionId}`
  }
}
