import styled, { css } from 'styled-components'

export const Container = styled.div`
  padding: 0px 8px;
  margin: 0 8px 25px;
  background-color: #252525;
  border-radius: 4px;
  :last-child {
    margin: 0;
  }
`
export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 50px;

  .guest-button {
    margin-top: 6px;
    width: 100%;
    cursor: pointer;
  }

  .main-button {
    margin-top: 16px;
    width: 100%;
    cursor: pointer;
  }
`
export const LoginTittle = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
  flex-direction: row;
  text-align: center;
  padding: 20px 0;

  .login-icon {
    transform: rotate(-90deg);
  }

  .step-title {
    width: 100%;
    text-align: center;
  }
`
export const IconWrp = styled.div`
  margin: 0 auto 11.79px;
  border-top: 3px solid #3155e8;
  padding: 0 18px;
  width: 65px;

  .guest-header-icon {
    margin: 24px auto 0;
  }
`

export const Whapper = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 1rem;
    align-items: center;
  `}
`
export const Content = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 1rem;
  `}
`

export const SendCode = styled.span`
  ${({ theme }) => css`
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${theme.button.primary.bgColor};
    cursor: pointer;
    margin-top: 24px;
  `}
`

export const Go = styled.span`
  ${({ theme }) => css`
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${theme.button.primary.bgColor};
    cursor: pointer;
    margin-top: 24px;
    width: 100%;
    text-align: right;
  `}
`
export const WarningContainerMain = styled.div`
  display: flex;
  justify-content: center;
  font-size: 14px;
  align-items: center;
  margin-top: 8px;
`

export const ErrorMessage = styled.span`
  text-align: left;
  margin-left: 8px;
  color: #ff5a50;
`
