export const BOMBONIERE_HOST = process.env.baseUrlApiTicketing

export const SESSIONS_BOMBONIERE = {
  get: (sessionId: string | string[]) => {
    return `${BOMBONIERE_HOST}/sessions/${sessionId}/products/new-checkout`
  },
  post: (sessionId: string | string[], cartId: string) => {
    return `${BOMBONIERE_HOST}/carts/${cartId}/sessions/${sessionId}/products`
  }
}
