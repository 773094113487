import styled from 'styled-components'

export const ContainerButton = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #ccc;
`
export const ContainerInput = styled.div`
  margin-bottom: 8px;
`
export const HelpContainer = styled.p`
  span {
    color: ${(props) => props.theme?.global.four.color};
  }
  width: 100%;
  font-size: 14px;
  text-align: start;
`
