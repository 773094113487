import * as carts from './carts'
import * as sessions from './sessions'
import * as users from './users'
import * as seats from './seats'
import * as tickets from './tickets'
import * as bomboniere from './bomboniere'
import * as giftcard from './giftCard'
import * as payments from './payments'
import * as token from './token'
import * as clubUol from './clubUol'

const services = {
  carts,
  sessions,
  users,
  seats,
  tickets,
  bomboniere,
  giftcard,
  payments,
  token,
  clubUol
}

export default services
