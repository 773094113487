export const APP_INTERNAL_ERROR = {
  isVisible: true,
  type: 'danger',
  title: 'Ops... Ocorreu um erro inesperado!',
  content: 'Tente novamente em alguns instantes.',
  onlyShowContentInHeader: false
}
export const CLOSE_ALERT = {
  isVisible: false,
  type: '',
  title: '',
  content: ''
}

export const SEATS_SEATING_LIMIT = (limit: number) => ({
  isVisible: true,
  type: 'warning',
  title: 'Limite de Assentos',
  content: `Não é possível adicionar mais do que ${limit} assentos por sessão.`,
  onlyShowContentInHeader: false
})
export const DELETE_CLUB_UOL = () => ({
  isVisible: true,
  type: 'success',
  content: `O desconto foi excluído com sucesso!`,
  position: 'bottom'
})
