import { useContextSelector } from 'use-context-selector'
import { GlobalContext } from '@shared/context/Global'

export function useLoading() {
  const loading = useContextSelector(GlobalContext, (state) => state?.isLoading)
  const loadingButton = useContextSelector(
    GlobalContext,
    (state) => state?.isLoadingButton
  )
  const onSetIsLoading = useContextSelector(
    GlobalContext,
    (state) => state?.onSetLoading
  )
  const onSetIsLoadingButton = useContextSelector(
    GlobalContext,
    (state) => state?.onSetLoadingButton
  )
  return {
    loading,
    onSetIsLoading,
    loadingButton,
    onSetIsLoadingButton
  }
}
