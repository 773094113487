import { FormProvider, useForm } from 'react-hook-form'
import Input from '@Components/Input'
import Button from '@Components/Button'
import { cpfMask, validarCPF } from '@Helpers/CPF'
import { useLogin } from '@shared/hooks/useLogin'
import * as S from './styled'
import {
  inputsLoginEmailGuest,
  inputsLoginCpfGuest,
  inputsLoginSuitMobile
} from '../steps'
import Icon from '@Components/Icon'
import services from '@Services/index'
import { useAuthToken } from '@shared/hooks/useAuthToken'
import { useCart } from '@shared/hooks/useCart'
import { useCookies } from '@shared/hooks/useCookies'
import { useLoading } from '@shared/hooks/useLoading'
import { useModal } from '@shared/hooks/useModal'
import schemas from '@Schemas/index'
import { useSessionData } from '@shared/hooks/useSessionData'
import CheckoutUtils from '@shared/utils/cart'
import { UseTracking } from '@shared/hooks/useTracking'
import { useWebView } from '@shared/hooks/useWebView'

const LoginGuestStep = ({ recaptchaRef }) => {
  const methods = useForm()
  const { register } = methods
  const { getPartnerShipName, verifyShowCheckBoxPoweredBy } = CheckoutUtils()
  const { setCookie } = useCookies()
  const { handleSubmit, getValues, setError } = methods
  const { trackNewsLetterIngresso } = UseTracking()
  const { isSuitMobile } = useWebView()
  const {
    loginCurrentStep,
    emailOrCpfValueContext,
    onSetIsOpenCollapseUser,
    onSetIsOpenLogin,
    onSetCurrentLoginStepNumberAndPage,
    openLogin,
    hasAccount
  } = useLogin()

  const { onSetAuthToken } = useAuthToken()
  const { cart, goPayments, restartSession } = useCart()
  const { onSetIsOpenModal } = useModal()
  const { onSetIsLoading } = useLoading()
  const { sessionData } = useSessionData()
  const { onSetIsLoadingButton, loadingButton } = useLoading()
  const emailRegex = /\S+@\S+\.\S+/
  const isEmail = emailRegex.test(emailOrCpfValueContext)

  const getEmailOrCpfValue = () => {
    if (isEmail) {
      return emailOrCpfValueContext
    } else {
      return cpfMask(emailOrCpfValueContext, '')
    }
  }
  const canGoPayment = () => {
    if (sessionData?.selectedTickets.length > 0) {
      onSetIsLoading(true)
      goPayments()
    }
  }
  const onSubmit = async (dataInputs) => {
    onSetIsLoadingButton(true)
    const reCAPTCHAToken = await recaptchaRef.current.executeAsync()

    if (emailRegex.test(dataInputs['emailOrCpf'])) {
      if (
        getValues('email2').toLowerCase() !==
        getValues('emailOrCpf').toLowerCase()
      ) {
        setError('email2', {
          message: 'Esse campo deve ser igual ao de e-mail.'
        })
      } else if (reCAPTCHAToken) {
        const payload = {
          email: dataInputs['emailOrCpf'].toLowerCase(),
          emailConfirmation: dataInputs['email2'].toLowerCase(),
          cPF: dataInputs['cpf'],
          privacyPolicy: true
        }

        const [data, error] = await services.carts.associate(cart?.id, payload)
        if (data) {
          const payload = {
            type: 'Guest',
            id: data?.userInfo.id,
            email: data?.userInfo.email,
            cpf: data?.userInfo.cpf,
            loginType: data?.userInfo.loginType,
            access_token: data?.userInfo.authenticationKey
          }
          if (verifyShowCheckBoxPoweredBy())
            setCookie(
              process.env.cookieNewsLetterPoweredBy,
              getValues('newsletterpoweredby')
            )

          if (hasAccount) {
            const user = {
              user: {
                optin: hasAccount ? 'Y' : '',
                optinPartnership: getValues('newsletterpoweredby') ? 'Y' : ''
              }
            }
            trackNewsLetterIngresso(user)
          } else {
            const user = {
              user: {
                optin: getValues('emailOptIn') ? 'Y' : '',
                optinPartnership: getValues('newsletterpoweredby') ? 'Y' : ''
              }
            }
            trackNewsLetterIngresso(user)
          }

          onSetAuthToken(payload)
          onSetIsOpenLogin({ show: false })
          onSetIsOpenCollapseUser(true)

          if (openLogin.onNextPage) canGoPayment()
        } else {
          if (error?.modelState?.cPF) {
            setError('emailOrCpf', {
              message: error?.modelState?.cPF[0]
            })
          }

          if (error?.modelState?.cartError) {
            onSetIsLoadingButton(false)
            return onSetIsOpenModal({
              ...schemas.modal.SEATS_EXPIRED_SESSION_CUSTOM({
                isOpen: true,
                description: error?.modelState?.cartError[0]
              }),

              onConfirm: () => {
                onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
                onSetIsOpenLogin({ show: false })
                restartSession()
              },
              onClose: () => {
                onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
              }
            })
          }
        }
      }
    } else if (validarCPF(dataInputs['emailOrCpf'])) {
      if (
        getValues('email2').toLowerCase() !== getValues('email').toLowerCase()
      ) {
        setError('email2', {
          message: 'Esse campo deve ser igual ao de e-mail.'
        })
      } else if (reCAPTCHAToken) {
        const payload = {
          email: dataInputs['email'].toLowerCase(),
          emailConfirmation: dataInputs['email2'].toLowerCase(),
          cPF: dataInputs['emailOrCpf'],
          privacyPolicy: true
        }

        const [data, error] = await services.carts.associate(cart?.id, payload)

        if (data) {
          const payload = {
            type: 'Guest',
            id: data?.userInfo.id,
            email: data?.userInfo.email,
            cpf: data?.userInfo.cpf,
            loginType: data?.userInfo.loginType,
            access_token: data?.userInfo.authenticationKey
          }
          if (verifyShowCheckBoxPoweredBy())
            setCookie(
              process.env.cookieNewsLetterPoweredBy,
              getValues('newsletterpoweredby')
            )

          if (hasAccount) {
            const user = {
              user: {
                optin: hasAccount ? 'Y' : '',
                optinPartnership: getValues('newsletterpoweredby') ? 'Y' : ''
              }
            }
            trackNewsLetterIngresso(user)
          } else {
            const user = {
              user: {
                optin: getValues('emailOptIn') ? 'Y' : '',
                optinPartnership: getValues('newsletterpoweredby') ? 'Y' : ''
              }
            }
            trackNewsLetterIngresso(user)
          }

          onSetAuthToken(payload)
          onSetIsOpenLogin({ show: false })
          onSetIsOpenCollapseUser(true)
          if (openLogin.onNextPage) canGoPayment()
        } else {
          if (error?.modelState?.cPF) {
            setError('emailOrCpf', {
              message: error?.modelState?.cPF[0]
            })
          }

          if (error?.modelState?.cartError) {
            onSetIsLoadingButton(false)
            return onSetIsOpenModal({
              ...schemas.modal.SEATS_EXPIRED_SESSION_CUSTOM({
                isOpen: true,
                description: error?.modelState?.cartError[0]
              }),

              onConfirm: () => {
                onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
                onSetIsOpenLogin({ show: false })
                restartSession()
              },
              onClose: () => {
                onSetIsOpenModal(schemas.modal.CLOSE_MODAL)
              }
            })
          }
        }
      }
    }
    onSetIsLoadingButton(false)
  }
  const renderInputs = () => {
    if (isEmail) {
      return inputsLoginEmailGuest.map((inputItem) => (
        <S.ContainerInput key={inputItem.id}>
          <Input
            initialValue={inputItem.id === 1 ? getEmailOrCpfValue() : ''}
            key={inputItem.id}
            label={inputItem.label}
            placeholder={inputItem.placeholder}
            rule={inputItem.valid}
            handleClick={() => onSetCurrentLoginStepNumberAndPage(1, 1)}
            disabled={false}
            change={inputItem?.change}
            applyMaskOnlyThisConditionIsTrue={
              inputItem.applyMaskOnlyThisConditionIsTrue
            }
            mask={inputItem.mask}
            inputName={inputItem?.inputName}
            validation={inputItem?.validation}
            onPaste={inputItem.onPaste}
            lowerCase={inputItem.lowerCase}
            autoComplete="off"
          />
        </S.ContainerInput>
      ))
    } else if (isSuitMobile()) {
      return inputsLoginSuitMobile.map((inputItem) => (
        <S.ContainerInput key={inputItem.id}>
          <Input
            lowerCase={inputItem.lowerCase}
            initialValue={inputItem.id === 1 ? getEmailOrCpfValue() : ''}
            key={inputItem.id}
            label={inputItem.label}
            placeholder={inputItem.placeholder}
            handleClick={() => onSetCurrentLoginStepNumberAndPage(1, 1)}
            rule={inputItem.valid}
            disabled={false}
            change={inputItem?.change}
            autoComplete="off"
            mask={inputItem.mask}
            applyMaskOnlyThisConditionIsTrue={
              inputItem.applyMaskOnlyThisConditionIsTrue
            }
            inputName={inputItem?.inputName}
            validation={inputItem?.validation}
            onPaste={inputItem.onPaste}
          />
        </S.ContainerInput>
      ))
    } else {
      return inputsLoginCpfGuest.map((inputItem) => (
        <S.ContainerInput key={inputItem.id}>
          <Input
            lowerCase={inputItem.lowerCase}
            initialValue={inputItem.id === 1 ? getEmailOrCpfValue() : ''}
            key={inputItem.id}
            label={inputItem.label}
            placeholder={inputItem.placeholder}
            handleClick={() => onSetCurrentLoginStepNumberAndPage(1, 1)}
            rule={inputItem.valid}
            disabled={false}
            change={inputItem?.change}
            autoComplete="off"
            applyMaskOnlyThisConditionIsTrue={
              inputItem.applyMaskOnlyThisConditionIsTrue
            }
            inputName={inputItem?.inputName}
            validation={inputItem?.validation}
            onPaste={inputItem.onPaste}
          />
        </S.ContainerInput>
      ))
    }
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset>{renderInputs()}</fieldset>
        {!hasAccount && (
          <S.Newsletter>
            <input
              name="emailOptIn"
              type="checkbox"
              {...register('emailOptIn', {})}
            />
            <span>Quero receber novidades e mensagens da Ingresso.com.</span>
          </S.Newsletter>
        )}

        {sessionData.isPoweredBy && verifyShowCheckBoxPoweredBy() && (
          <S.NewsLetterPoweredBy>
            <input
              name="newsletterpoweredby"
              type="checkbox"
              {...register('newsletterpoweredby', {})}
            />
            <p>Quero receber novidades e mensagens do {getPartnerShipName()}</p>
          </S.NewsLetterPoweredBy>
        )}
        <S.ContainerButton>
          <Button
            isInitialPositionIcon={
              loginCurrentStep?.button?.isInitialPositionIcon
            }
            height={
              loginCurrentStep?.button?.height
                ? loginCurrentStep?.button?.height
                : '48px'
            }
            className="main-button"
            loading={loadingButton}
            onClick={() => onSubmit}
            iconLeft={
              loginCurrentStep?.button?.icon ? (
                <Icon
                  color="#460505"
                  src={
                    loginCurrentStep?.button.icon
                      ? loginCurrentStep?.button?.icon
                      : ''
                  }
                  width={24}
                  height={24}
                />
              ) : null
            }
          >
            {loginCurrentStep?.button?.label}
          </Button>
        </S.ContainerButton>
      </form>
    </FormProvider>
  )
}

export default LoginGuestStep
