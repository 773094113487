import { GlobalContext } from '@shared/context/Global'
import { useContextSelector } from 'use-context-selector'

export function usePage() {
  const page = useContextSelector(GlobalContext, (state) => state?.page)
  const onSetPage = useContextSelector(
    GlobalContext,
    (state) => state?.onSetPage
  )
  return {
    page,
    onSetPage
  }
}
