import { useState } from 'react'

import { withHooks } from '@shared/hooks/UseWithHooks'

import Radio from './radio'
import { IUseProps } from './interface'

export const useHooks = ({
  isChecked,
  onClick,
  disabled,
  ...rest
}: IUseProps) => {
  const [checked, setCheck] = useState(isChecked)

  const handleClick = () => {
    if (!disabled) {
      setCheck(!checked)
      if (onClick) {
        onClick(checked)
      }
    }
  }

  return {
    ...rest,
    isChecked: isChecked,
    onClick: handleClick
  }
}

export default withHooks(Radio, useHooks)
