import styled from 'styled-components'

export const Container = styled.div`
  padding: 0px 0.5rem 0px 0.5rem;
  margin: 0px 0.5rem 0px 0.5rem;
  background-color: #252525;
`

export const TitleWithIconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  @media (min-width: 992px) {
    gap: 8px;
  }
`

export const OrderContainer = styled.div`
  height: 75px;
  padding: 32px 0px 8px 16px;
`

export const ContentContainer = styled.div`
  padding: 0px 1rem 0px 1rem;
`

export const DescriptContainer = styled.div`
  padding: 0.75rem 0px 1rem 0px;
  border-top: 0.5px solid #666666;
`

export const DescriptionWithValue = styled(ContentContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Title = styled.div`
  padding-left: 0.5rem;
`
