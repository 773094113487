import endpoints from '@shared/endpoints'
import { ticketingAPI } from '@shared/request/Api'
import { handleError } from '@shared/request/error'
import { getAuthTypeHeader } from '../utils/helpers/headers'

export const addSession = async (
  cartId: string,
  sessionId: string,
  partnership?: string,
  queueittoken?: string
) => {
  try {
    const { data } = await ticketingAPI.post(
      `${endpoints.cartEndpoint.CARTS_SESSIONS.get(
        cartId,
        sessionId
      )}?partnership=${partnership ? partnership : 'home'}${
        queueittoken ? `&queueittoken=${queueittoken}` : ''
      } `,
      {
        headers: getAuthTypeHeader()
      }
    )
    return [data, null]
  } catch (error) {
    return [null, handleError(error)]
  }
}
export const removeSession = async (cartId: string, sessionId: string) => {
  try {
    const { data } = await ticketingAPI.delete(
      endpoints.cartEndpoint.CARTS_SESSIONS.delete(cartId, sessionId),
      {
        headers: getAuthTypeHeader()
      }
    )
    return [data, null]
  } catch (error) {
    return [null, handleError(error)]
  }
}
