import { ticketingAPI } from '@shared/request/Api'
import endpoints from '@shared/endpoints'
import { handleError } from '@shared/request/error'
import { getAuthTypeHeader } from '../utils/helpers/headers'
import { IDiscount } from '@shared/context/interface'

export const create = async (
  origin: string,
  deviceId: string,
  app_version: string,
  isMobileApp: boolean
) => {
  try {
    let response = null
    if (isMobileApp) {
      const { data } = await ticketingAPI.post(
        `${endpoints.cartEndpoint.CARTS}/?origin=${origin}&newCheckout=true&deviceId=${deviceId}&app_version=${app_version}`
      )
      response = data
    } else {
      const { data } = await ticketingAPI.post(
        `${endpoints.cartEndpoint.CARTS}/?origin=${origin}&newCheckout=true`
      )
      response = data
    }
    return [response, null]
  } catch (error) {
    return [null, handleError(error)]
  }
}

export const get = async (cartId: string) => {
  try {
    const { data } = await ticketingAPI.get(
      endpoints.cartEndpoint.CARTS_CARTID.get(cartId)
    )
    return [data, null]
  } catch (error) {
    return [null, handleError(error)]
  }
}

export const associate = async (cartId: string, guest) => {
  try {
    const { data } = await ticketingAPI.post(
      endpoints.cartEndpoint.GUEST_ASSOCIATE.get(cartId),
      guest
    )
    return [data, null]
  } catch (error) {
    return [null, handleError(error)]
  }
}
export const deleteSession = async (cartId: string, sessionId: string) => {
  try {
    const { data } = await ticketingAPI.delete(
      endpoints.cartEndpoint.CARTS_SESSIONS.delete(cartId, sessionId),
      {
        headers: getAuthTypeHeader()
      }
    )
    return [data, null]
  } catch (error) {
    return [null, handleError(error)]
  }
}

export const addTickets = async (
  cartId: string,
  sessionId: string,
  sectionId: string,
  body
) => {
  try {
    const { data } =
      typeof getAuthTypeHeader() !== null
        ? await ticketingAPI.post(
            endpoints.cartEndpoint.ADD_TICKETS.get(
              cartId,
              sessionId,
              sectionId
            ),
            body,
            {
              headers: getAuthTypeHeader()
            }
          )
        : await ticketingAPI.post(
            endpoints.cartEndpoint.ADD_TICKETS.get(
              cartId,
              sessionId,
              sectionId
            ),
            body
          )
    return [data, null]
  } catch (error) {
    return [null, handleError(error)]
  }
}
export const promocode = async (
  passwordPromocode: string,
  cartId: string,
  giftCards: IDiscount[]
) => {
  try {
    const { data } = await ticketingAPI.post(
      endpoints.cartEndpoint.CARTS_PROMOCODE.get(passwordPromocode, cartId),
      giftCards,
      {
        headers: getAuthTypeHeader()
      }
    )
    return [data, null]
  } catch (error) {
    return [null, handleError(error)]
  }
}
