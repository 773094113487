import styled from 'styled-components'

export interface IProps {
  color?: string
  height?: string
  fontSize?: string
}

export const ContainerParental = styled.div`
  padding: 8px;
  border-radius: 4px;
  margin-top: 20px;
  display: flex;
  align-items: baseline;
`

export const DescParental = styled.p`
  display: inline-block;
  margin-left: 8px;
  margin-top: 3px;
  width: 87%;
`

export const Span = styled.span`
  font-size: 10px;
  font-weight: bold;
  padding: 1px;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  color: #ffffff;
`

export const Parental = styled.div`
  background: ${(props: IProps) => props.color};
  text-align: center;
  vertical-align: top;
  height: ${(props) => (props.height ? props.height : '14px')};
  width: ${(props) => (props.height ? props.height : '14px')};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;

  span {
    font-size: ${(props) => (props.fontSize ? props.fontSize : '10px')};
  }
`
