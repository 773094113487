import { GlobalContext } from '@shared/context/Global'
import { useContextSelector } from 'use-context-selector'

export function useTickets() {
  const tickets = useContextSelector(GlobalContext, (state) => state?.tickets)

  const onSetTickets = useContextSelector(
    GlobalContext,
    (state) => state?.onSetTickets
  )
  const hasDiferentsTypesTickets = () => {
    if (tickets.all.length > 0) {
      return tickets.all.some((ticket) => ticket.type !== 'Default')
    }
  }
  return {
    tickets,
    onSetTickets,
    hasDiferentsTypesTickets
  }
}
