import { Cookies } from 'react-cookie'
const cookies = new Cookies()

export const getAuthTypeHeader = () => {
  const authToken =
    cookies.get(process.env.COOKIE_TOKEN_NAME) ||
    cookies.get(process.env.COOKIE_TOKEN_NAME_APP)
  if (authToken?.type === 'bearer') {
    return { authorization: `Bearer ${authToken.access_token}` }
  } else if (authToken?.type === 'Guest') {
    return { 'Guest-Authorization': `${authToken.access_token}` }
  } else {
    return null
  }
}
export const getAuthToken = () => {
  const authToken =
    cookies.get(process.env.COOKIE_TOKEN_NAME) ||
    cookies.get(process.env.COOKIE_TOKEN_NAME_APP)
  return authToken
}
