import { useSessionData } from '@shared/hooks/useSessionData'
import Icon from '@Components/Icon'
import { TResume } from './types'
import * as S from './styles'

export const Resume = ({
  children,
  isOpen = false,
  handleCloseOut,
  p = 'M',
  isLogin = false
}: TResume) => {
  const { sessionData } = useSessionData()

  return (
    <>
      {isOpen && (
        <S.Wrapper onClick={handleCloseOut} data-testid="resume-element">
          <S.CardContainer
            p={p}
            isOpen={isOpen}
            onClick={(e) => e.stopPropagation()}
            isLogin={isLogin}
          >
            <S.LoginHeader>
              <S.LoginContainer>
                <Icon src="icons/user-label" size={24} />
                <span>Identificação</span>
              </S.LoginContainer>
              <S.CloseContainer onClick={handleCloseOut}>
                <Icon src="icons/close" size={24} />
              </S.CloseContainer>
            </S.LoginHeader>
            <S.LoginBody backgroundUrl={sessionData?.movieBanner}>
              <S.Card>{children}</S.Card>
            </S.LoginBody>
          </S.CardContainer>
        </S.Wrapper>
      )}
    </>
  )
}
