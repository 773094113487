import * as S from './styled'
import { IProps } from './interface'
import LoadingSpin from '@Components/LoadingSpin'

const Button = ({
  children,
  variant = 'default',
  iconLeft,
  isInitialPositionIcon = false,
  noUppercase = false,
  loading,
  disabled,
  ...props
}: IProps) => {
  return (
    <S.Button
      {...props}
      variant={variant}
      disabled={disabled || loading}
      noUppercase={noUppercase}
    >
      {loading && <LoadingSpin variant={variant} />}
      {!loading &&
        (iconLeft ||
          (isInitialPositionIcon && (
            <S.IconLeft isInitialPositionIcon={isInitialPositionIcon}>
              {iconLeft}
            </S.IconLeft>
          )))}
      {children}
    </S.Button>
  )
}

export default Button
