import { FormProvider, useForm } from 'react-hook-form'
import Input from '@Components/Input'
import Button from '@Components/Button'
import { useLogin } from '@shared/hooks/useLogin'
import * as S from './styled'
import Icon from '@Components/Icon'
import { handleCpfEmail } from '@Helpers/others'
import Text from '@Components/Text'
import { useEffect } from 'react'
import { useSessionData } from '@shared/hooks/useSessionData'
import Benefits from '../Benefits'

const NoHaveAccountStep = () => {
  const methods = useForm()
  const { handleSubmit } = methods

  const {
    loginCurrentStep,
    onSetCurrentLoginStepNumberAndPage,
    emailOrCpfValueContext,
    setDataCreateAccountStepOne
  } = useLogin()

  const { sessionData } = useSessionData()
  const isGuestEnable = sessionData?.functionalities?.guestCheckout

  const onSubmit = () => {
    onSetCurrentLoginStepNumberAndPage(3, 3)
  }
  useEffect(() => {
    setDataCreateAccountStepOne(Object.values(''))
  }, [])

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset>
          {loginCurrentStep?.inputs?.map((inputItem) => (
            <Input
              initialValue={emailOrCpfValueContext}
              key={inputItem.id}
              disabled={false}
              {...handleCpfEmail(emailOrCpfValueContext)}
              change={inputItem?.change}
              handleClick={() => onSetCurrentLoginStepNumberAndPage(1, 1)}
              inputName="nohaveaccount"
              validation={inputItem?.validation}
            />
          ))}
        </fieldset>
        <S.ContainerButton>
          <Button
            isInitialPositionIcon={
              loginCurrentStep?.button?.isInitialPositionIcon
            }
            height={
              loginCurrentStep?.button?.height
                ? loginCurrentStep?.button?.height
                : '48px'
            }
            className="main-button"
            onClick={() => onSubmit}
            iconLeft={
              loginCurrentStep?.button?.icon ? (
                <Icon
                  color="#460505"
                  src={
                    loginCurrentStep?.button.icon
                      ? loginCurrentStep?.button?.icon
                      : ''
                  }
                  width={24}
                  height={24}
                />
              ) : null
            }
          >
            {loginCurrentStep?.button?.label}
          </Button>
        </S.ContainerButton>
      </form>
      {isGuestEnable && (
        <S.OtherButtonContent>
          <Text
            type="HEADLINE"
            text={loginCurrentStep?.otherButton.title}
            className="other-title"
          />
        </S.OtherButtonContent>
      )}
      {loginCurrentStep?.otherButton && (
        <>
          {isGuestEnable && (
            <Button
              height={
                loginCurrentStep?.otherButton?.height
                  ? loginCurrentStep?.otherButton?.height
                  : '48px'
              }
              href="#"
              className="guest-button"
              variant={loginCurrentStep?.otherButton.variant}
              onClick={() => onSetCurrentLoginStepNumberAndPage(3, 2)}
            >
              {loginCurrentStep?.otherButton.label}
            </Button>
          )}

          <Benefits />
        </>
      )}
    </FormProvider>
  )
}

export default NoHaveAccountStep
