import styled, { css } from 'styled-components'
import animations from '../../styles/animations'

import { TTypes } from './interface'

export const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`

export const Title = styled.h3`
  font-size: 14px;
  line-height: 20px;
  padding: 0 8px 0;
  width: 100%;
  @media (max-width: 992px) {
    font-size: 12px;
  }
`

export const Body = styled.p`
  font-size: 14px;
  padding: 10px 16px 0 0;
  width: 100%;
  justify-content: start;
`
export const Content = styled.p`
  font-size: 14px;
  display: flex;
  margin-left: 7px;
  @media (max-width: 992px) {
    font-size: 12px;
  }
`

export const Container = styled.div<{
  type: TTypes
  isVisible: boolean
  position: string
}>`
  ${(props) =>
    props.position === 'top'
      ? css`
          position: fixed;
          top: 10px;
          left: 5%;
          max-width: 90%;
          width: 100%;
        `
      : css`
          position: fixed;
          top: 88%;
          left: 21.9%;
          width: 70%;
          max-width: 992px;
        `}

  padding: 13px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  z-index: 2;
  background-color: ${(props) => props.theme?.alert?.[props.type]?.bgColor};
  border-left: ${(props) => props.theme?.alert?.[props.type]?.borderLeft};

  ${(props) => (props.isVisible ? animations.fadeIn : animations.fadeOut)}
  ${(props) =>
    props.isVisible
      ? `animation-duration: 100ms`
      : `animation-duration: 500ms`};

  ${Title} {
    color: ${(props) => props.theme?.alert?.[props.type]?.titleColor};
  }
  ${Body} {
    color: ${(props) => props.theme?.alert?.[props.type]?.contentColor};
  }

  .titleIcon svg {
    fill: ${(props) => props.theme?.alert?.[props.type]?.iconColor};
  }

  .closeIcon svg {
    fill: ${(props) => props.theme?.alert?.[props.type]?.iconCloseColor};
  }
  @media (max-width: 992px) {
    ${(props) =>
      props.position === 'bottom' &&
      css`
        left: 2%;
        top: 88.5%;
        font-size: 12px;
      `}
  }
`
