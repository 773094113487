import { useContextSelector } from 'use-context-selector'
import { GlobalContext } from '@shared/context/Global'

export function useDiscounts() {
  const discounts = useContextSelector(
    GlobalContext,
    (state) => state?.discounts
  )
  const onSetDiscounts = useContextSelector(
    GlobalContext,
    (state) => state?.onSetDiscounts
  )
  const onRemoveDiscounts = useContextSelector(
    GlobalContext,
    (state) => state?.onRemoveDiscounts
  )
  return {
    discounts,
    onSetDiscounts,
    onRemoveDiscounts
  }
}
