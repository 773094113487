/* eslint-disable @typescript-eslint/no-explicit-any */
//todo

import React, { FC, memo, useState } from 'react'
import { IProps } from './interface'
import Icon from '../Icon'
import Button from '../Button'
import Login from '@Components/Login'
import { Resume } from '@Components/Resume'
import { ResumeFooter } from '@Components/ResumeFooter'
import { usePage } from '@shared/hooks/usePage'
import { useCart } from '@shared/hooks/useCart'
import { useSeats } from '@shared/hooks/useSeats'
import { useLoading } from '@shared/hooks/useLoading'
import { useTickets } from '@shared/hooks/useTickets'
import {
  PAGE_NAME_BOMBONIERE,
  PAGE_NAME_PAYMENTS,
  PAGE_NAME_TICKETS,
  PAGE_NAME_WAIT_PAYMENT
} from '@Constants/page'

import * as S from './styled'
import { formatCurrencyValue } from '@Helpers/Currency'
import CheckoutUtils from '@shared/utils/cart'
import { useLogin } from '@shared/hooks/useLogin'
import useMediaQuery from '@shared/hooks/useMediaQuery'
import theme from '@Styles/theme'

import { useSessionTimer } from '@shared/hooks/useSessionTimer'
import { usePayment } from '@shared/hooks/usePayment'
import { usePaymentMethods } from '@shared/hooks/usePaymentMethods'
import FooterAlert from '@Components/FooterAlert'
import { useAlert } from '@shared/hooks/useAlert'
import { useNavigation } from '@shared/hooks/useNavigation'
import { useSessionData } from '@shared/hooks/useSessionData'
import { useApplePay } from '@shared/hooks/payment/useApplePay'

const Footer: FC<IProps> = () => {
  const [isOpenResume, setIsOpenResume] = useState(false)
  const { showModalChallenge } = usePayment()
  const { paymentMethodCurrent, onSetPaymentMethodCurrent } =
    usePaymentMethods()
  const { initPaymentWithApplePay } = useApplePay()
  const { page } = usePage()
  const { cart, goTickets, goSeats, goBomboniere } = useCart()

  const { sessionData } = useSessionData()
  const { seats } = useSeats()
  const { tickets } = useTickets()
  const { onSetIsOpenLogin, openLogin } = useLogin()
  const { loading, loadingButton } = useLoading()
  const { alertFooter } = useAlert()
  const { hideReservationWarning } = useSessionTimer()
  const isMobile = useMediaQuery('(max-width: 991px)')

  const { steps } = useNavigation()

  const {
    getTotalPriceFromCart,
    getPath,
    getCurrentQuantity,
    getShowSeatsOrTickets,
    isVisiblePrevButton
  } = CheckoutUtils()
  const handlePrevPage = () => {
    if (page === PAGE_NAME_TICKETS) {
      return goSeats(cart.sessionId)
    } else if (
      page === PAGE_NAME_PAYMENTS &&
      !sessionData.hasBomboniereActive
    ) {
      return goTickets(page, cart?.sessionId)
    } else if (page === PAGE_NAME_PAYMENTS && sessionData.hasBomboniereActive) {
      return goBomboniere(cart?.sessionId)
    } else if (page === PAGE_NAME_BOMBONIERE) {
      return goTickets(page, cart?.sessionId)
    } else {
      return goTickets(page, cart?.sessionId)
    }
  }

  const numberOfSeats = seats?.selected?.length >= 7
  /** alterado de enable para olhar o shouldShowTab */
  const getAvailablesSteps = Object.values(steps).filter(
    (step) => step.shouldShowTab
  )

  const NextStep =
    getAvailablesSteps.findIndex((step) => steps[page]?.index === step.index) +
    1

  const ticketsSelected = tickets?.selected?.map((ticket) =>
    Array.from({ length: ticket.quantity }, (_, i) => (
      <li key={`${ticket.id}-${i}`}>
        <Icon
          role="img"
          color="#ccc"
          src={ticket.iconPath}
          width={26}
          height={26}
        />
      </li>
    ))
  )

  const seatsSelected = seats?.selected?.map((seat) => (
    <li key={seat.id}>
      <Icon role="img" color="#ccc" src="icons/ticket" width={26} height={26} />
    </li>
  ))

  const ticketList = (
    <S.FooterTicketsList numberOfSeats={numberOfSeats}>
      {getPath('assentos') && seatsSelected}
      {getPath('ingressos') && ticketsSelected}
    </S.FooterTicketsList>
  )
  const verifyShowButtonApplePay = () => {
    if (getTotalPriceFromCart() > 0 && paymentMethodCurrent === 'applepay') {
      return {
        display: 'inline-block'
      }
    } else {
      onSetPaymentMethodCurrent('')
      return {
        display: 'none'
      }
    }
  }
  return (
    <S.FooterWrapper className="footer-checkout" data-testid="footer-test">
      {alertFooter?.isVisible && <FooterAlert {...alertFooter} />}

      <S.FooterTickets hasSeatSelection={getShowSeatsOrTickets()}>
        {ticketList}
      </S.FooterTickets>
      <S.WarningSessionTimer id="session-warning">
        <S.Container>
          <S.HeaderContainer>
            <Icon
              role="img"
              color={theme.global.placemark.color}
              src={'/icons/clock'}
              width={14}
              height={14}
            />
            <S.TextHeader>Fique atento!</S.TextHeader>
            <S.HeaderClose role="button" onClick={hideReservationWarning}>
              <Icon
                role="img"
                color={theme.global.placemark.color}
                src={'/icons/close'}
                size={20}
              />
            </S.HeaderClose>
          </S.HeaderContainer>
          <S.TextBody>
            Você só tem <S.TextTimer>2 minutos</S.TextTimer> para finalizar sua
            compra. Após esse tempo algumas configurações poderão ser perdidas.
          </S.TextBody>
        </S.Container>
      </S.WarningSessionTimer>

      <S.Dialog open showModalChallenge={showModalChallenge}>
        <div id="challenge-3ds"></div>
        <div id="fingerprint-3ds"></div>
      </S.Dialog>

      <S.FooterResume>
        {isMobile ? (
          <S.FooterResumeInfo>
            <div
              onClick={() =>
                !getAvailablesSteps[NextStep]?.disabledResume &&
                setIsOpenResume(true)
              }
            >
              <S.FooterResumeIconWrp
                data-testid="button-resume"
                disabled={getAvailablesSteps[NextStep]?.disabledResume}
              >
                <Icon
                  role="img"
                  src="icons/arrow"
                  width={24}
                  height={24}
                  className="icon-resume"
                />
              </S.FooterResumeIconWrp>
              <S.FooterResumeInfonWrp
                disabled={getAvailablesSteps[NextStep]?.disabledResume}
              >
                <span className="cont">{getCurrentQuantity()}</span>

                <span className="value">
                  {formatCurrencyValue(getTotalPriceFromCart())}
                </span>
              </S.FooterResumeInfonWrp>
            </div>
          </S.FooterResumeInfo>
        ) : (
          <S.FooterButtonWrp>
            <Button
              onClick={handlePrevPage}
              disabled={isVisiblePrevButton() || loadingButton}
              className="btn-step-back-footer"
              variant="outlined"
            >
              Voltar
            </Button>
          </S.FooterButtonWrp>
        )}
        <S.TicketsDesktop>{ticketList}</S.TicketsDesktop>
        <S.FooterButtonWrp>
          {paymentMethodCurrent === 'applepay' && (
            <S.ApplePayButton
              id="apple-pay-button"
              onClick={initPaymentWithApplePay}
              className={'apple-pay-button-black'}
              style={verifyShowButtonApplePay()}
            ></S.ApplePayButton>
          )}

          {paymentMethodCurrent !== 'applepay' &&
            page !== PAGE_NAME_WAIT_PAYMENT && (
              <Button
                className="btn-footer"
                noUppercase
                onClick={() => {
                  getAvailablesSteps[NextStep]?.onClick()
                }}
                disabled={
                  getAvailablesSteps[NextStep]?.disabled ||
                  loading ||
                  showModalChallenge
                }
                loading={loadingButton}
              >
                {getAvailablesSteps[NextStep]?.label}
              </Button>
            )}
        </S.FooterButtonWrp>
      </S.FooterResume>
      <Resume
        isOpen={isOpenResume}
        handleCloseOut={() => setIsOpenResume(false)}
      >
        <div onClick={() => setIsOpenResume(false)}>
          <ResumeFooter />
        </div>
      </Resume>
      {openLogin.show && (
        <Resume
          isLogin={true}
          p="L"
          isOpen={openLogin.show}
          handleCloseOut={() => onSetIsOpenLogin({ show: false })}
        >
          <Login />
        </Resume>
      )}
    </S.FooterWrapper>
  )
}

export default memo(Footer)
