import endpoints from '@shared/endpoints'
import { ticketingAPI } from '@shared/request/Api'
import { handleError } from '@shared/request/error'
import { getAuthTypeHeader } from '../utils/helpers/headers'
import axios from 'axios'

export const verify = async (discount: {
  cartId: string
  validation: string
}) => {
  try {
    const res = await axios.get('https://api.ipify.org/?format=json')
    const { data, status } = await ticketingAPI.post(
      endpoints.clubUol.clubUolEndPoint.post(),
      discount,
      {
        headers: {
          ...getAuthTypeHeader(),
          'True-Client-IP': res.data.ip
        }
      }
    )
    const object = { ...data, status }
    return [object]
  } catch (error) {
    return [error.response]
  }
}
export const deleteDiscount = async (cartId: string) => {
  try {
    const response = await ticketingAPI.post(
      endpoints.clubUol.clubUolEndPoint.delete(cartId),
      {
        headers: getAuthTypeHeader()
      }
    )
    return { data: response.data, status: response.status }
  } catch (error) {
    return { ...handleError(error) }
  }
}
