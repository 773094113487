import endpoints from '@shared/endpoints'
import { ticketingAPI } from '@shared/request/Api'
import { handleError } from '@shared/request/error'
import { getAuthTypeHeader } from '../utils/helpers/headers'

export const get = async (giftCardId: string) => {
  try {
    const { data } = await ticketingAPI.get(
      endpoints.giftEndpoints.GIFTCARD_SECTIONS.get(giftCardId),
      {
        headers: getAuthTypeHeader()
      }
    )
    return { data, status }
  } catch (error) {
    return { ...handleError(error) }
  }
}
