import styled, { css } from 'styled-components'

import { IStyleContainer, IStyleRadio, IStyleLabel } from './interface'

/* SContainer
  ========================================================================== */
const container = {
  disabled: css`
    pointer-events: none;
    opacity: 0.8;
  `
}
export const SContainer = styled.label<IStyleContainer>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  ${(props) => props.disabled && container.disabled};
`

/* SLabel
  ========================================================================== */
const label = {
  disabled: css`
    color: #666;
  `
}
export const SLabel = styled.span<IStyleLabel>`
  font-size: 14px;
  color: ${(props) =>
    props?.colorLabel
      ? props.colorLabel
      : props.theme?.global?.secondary?.color};
  padding: 10px;

  ${(props) => props.disabled && label.disabled};
`

/* SRadio
  ========================================================================== */
const Radio = {
  checked: css`
    &::after {
      content: ' ';
      background-color: #fff;
      width: 8px;
      height: 8px;
      top: 50%;
      left: 50%;
      position: absolute;
      border-radius: 50%;
      margin: -4px 0 0 -4px;
    }
    background-color: #ff7300;
    border: solid 1px transparent;
  `,
  unchecked: css`
    background-color: #fff;
    border: solid 1px #999;
  `,
  disabled: css`
    background-color: #666;
    border: solid 1px transparent;
  `
}
export const SRadio = styled.div<IStyleRadio>`
  align-items: center;
  display: flex;
  justify-content: center;
  width: ${(props) => `${props.boxSize}${props.unit}`};
  height: ${(props) => `${props.boxSize}${props.unit}`};
  min-width: ${(props) => `${props.boxSize}${props.unit}`};
  min-height: ${(props) => `${props.boxSize}${props.unit}`};
  border-radius: 50%;
  position: relative;
  transition: 0.2s;
  ${(props) => props.disabled && Radio.disabled};

  ${(props) => (props.checked ? Radio.checked : Radio.unchecked)};
`
