import Image from 'next/image'
import { IPropsModal } from './interface'
import { ReactNode } from 'react'
export const SEATS_SELECT_A_SEAT = (iconUrl = '') => {
  return {
    isOpen: true,
    title: 'Atenção',
    description: 'Você deve selecionar um assento para Continuar sua compra',
    iconModal: iconUrl ? iconUrl : 'illustrated/warning',
    confirmButton: 'Ok'
  }
}
export const CLOSE_MODAL = {
  isOpen: false
}
export const SEATS_SESSION_DOES_NOT_EXIST = {
  isOpen: true,
  title: 'Essa sessão não existe mais!',
  description: `Volte para o catálogo e tente selecionar uma outra sessão. Bom espetáculo!`,
  iconModal: 'illustrated/time',
  confirmButton: 'Continuar'
}

export const RemoveCard = {
  isOpen: true,
  iconModal: 'logo',
  title: 'Atenção',
  description: `Deseja mesmo excluir este cartão?`,
  confirmButton: 'Sim',
  closeButton: 'Não'
}

export const SEATS_TICKET_ALREADY_GUARANTEED = (
  ticketsLength,
  dateEvent: string
) => {
  const date = dateEvent.split(' ')
  return {
    isOpen: true,
    title: 'Ingresso já garantido!',
    iconModal: 'logo',
    confirmButton: 'Sim',
    closeButton: 'Não',
    customContent: (
      <div>
        <p>
          Você já comprou{' '}
          <span>
            <strong>{ticketsLength}</strong>
          </span>{' '}
          {ticketsLength > 1 ? 'ingressos' : 'ingresso'}{' '}
          <span> para a sessão das </span>
          <strong>{date[1]}</strong> do dia <strong>{date[0]}</strong>. Deseja
          comprar novamente?
        </p>
      </div>
    )
  }
}

export const SEATS_EXPIRED_SESSION = {
  isOpen: true,
  title: 'Eita!',
  iconModal: 'illustrated/time',
  confirmButton: 'Continuar'
}
export const SESSION_ERROR_CUSTOM = (options) => {
  return {
    isOpen: options.isOpen,
    title: 'Eita!',
    iconModal: 'illustrated/warning',
    confirmButton: 'Continuar',
    description: options.description
  }
}

export const SEATS_EXPIRED_SESSION_CUSTOM = (options) => {
  return {
    isOpen: options.isOpen,
    title: 'Eita!',
    iconModal: 'illustrated/time',
    confirmButton: 'Continuar',
    description: options.description
  }
}
export const PURCHASE_TIME_EXPIRED = {
  isOpen: true,
  title: 'Eita!',
  iconModal: 'illustrated/time',
  confirmButton: 'Continuar',
  customContent: (
    <div>
      <p>O tempo para finalizar a compra se excedeu. :(</p>
      <p style={{ paddingTop: '8px' }}>Clique em Continuar para reiniciar.</p>
    </div>
  )
}
export const TOKEN_TIME_EXPIRED = {
  isOpen: true,
  title: 'Ops!',
  iconModal: 'illustrated/time',
  confirmButton: 'Continuar',
  customContent: (
    <div>
      <p>
        Não foi possível prosseguir pois sua sessão expirou. Realize o login e
        tente novamente!
      </p>
      <p style={{ paddingTop: '8px' }}>
        Clique em Continuar para logar novamente.
      </p>
    </div>
  )
}
export const TICKET_VALIDATION_ERROR = (mesage: string) => ({
  isOpen: true,
  title: 'Ops!',
  iconModal: 'illustrated/warning',
  confirmButton: 'Continuar',
  customContent: (
    <div>
      <p>{mesage}</p>
    </div>
  )
})

export const ADD_TICKET_CART_ERROR = {
  isOpen: true,
  title: 'Ops!',
  iconModal: 'illustrated/warning',
  confirmButton: 'Continuar',
  customContent: (
    <div>
      <p>Esse carrinho já possui um dono</p>
    </div>
  )
}

export const INVALID_FIDELITY = {
  isOpen: true,
  title: 'Eita!',
  iconModal: 'illustrated/time',
  confirmButton: 'Continuar',
  customContent: (
    <div>
      <p>
        Infelizmente não foi possível validar o seu código. Por favor, tente
        mais tarde.
      </p>
    </div>
  )
}

export const FAILED_TO_LOAD_SESSION = {
  isOpen: true,
  title: 'Eita!',
  iconModal: 'illustrated/warning',
  customContent: (
    <div>
      <p>
        Infelizmente não foi possível carregar os dados desta sessão no momento,
        por favor tente novamente mais tarde
      </p>
    </div>
  )
}

export const IS_SPECIAL_SEAT_BY_TYPE = (type: string) => {
  if (type === 'Companion') {
    return {
      isOpen: true,
      iconModal: 'illustrated/warning',
      title: 'Assento para acompanhante',
      description:
        'Este assento é preferencial para uma pessoa acompanhando um cadeirante.',
      closeButton: 'Cancelar',
      confirmButton: 'Ok',
      message:
        'Você deverá ceder seu assento para um acompanhante, caso seja necessário. Ao aceitar você confirma que está ciente, e de acordo, com estas condições.'
    }
  } else if (type === 'Disability') {
    return {
      isOpen: true,
      iconModal: 'illustrated/warning',
      title: 'Espaço para cadeirante',
      description:
        'Este é um espaço para cadeira de rodas. Não é um assento físico.',
      closeButton: 'Cancelar',
      confirmButton: 'Aceito',
      message:
        'Sua entrada pode ser negada caso você não tenha real necessidade de usufruir deste espaço. Ao aceitar você confirma que está ciente, e de acordo, com estas condições.'
    }
  } else if (type === 'Diferenciado8' || type === 'Puff') {
    return {
      isOpen: true,
      iconModal: 'seats/alerts/alert-puff',
      title: 'Espaço com Puff.',
      description:
        'O assento selecionado acomoda até duas pessoas adquirindo apenas um ingresso.',
      closeButton: 'Cancelar',
      confirmButton: 'Confirmar',
      message: ' Deseja confirmar a seleção?'
    }
  } else if (type === 'Diferenciado5') {
    return {
      isOpen: true,
      iconModal: 'seats/alerts/alert-puff',
      title: 'Espaço com Lounge.',
      description:
        'O assento selecionado acomoda até duas pessoas adquirindo apenas um ingresso.',
      closeButton: 'Cancelar',
      confirmButton: 'Confirmar',
      message: ' Deseja confirmar a seleção?'
    }
  } else if (type === 'Sofa') {
    return {
      isOpen: true,
      iconModal: 'seats/alerts/alert-puff',
      title: 'Espaço com Sofá.',
      description:
        'O assento selecionado acomoda até duas pessoas adquirindo apenas um ingresso.',
      closeButton: 'Cancelar',
      confirmButton: 'Confirmar',
      message: ' Deseja confirmar a seleção?'
    }
  }
}

export const SESSION_REMOVE_SESSION = {
  isOpen: true,
  title: 'Atenção',
  description: 'Deseja mesmo excluir esta sessão do seu carrinho?',
  iconModal: 'illustrated/warning',
  closeButton: 'Cancelar',
  confirmButton: 'Continuar'
}

export const SESSION_REMOVE_SESSION_LOGO = {
  title: 'Atenção',
  description:
    'Deseja mesmo excluir esta sessão do seu carrinho e voltar para home?',
  iconModal: 'illustrated/warning',
  closeButton: 'Cancelar',
  confirmButton: 'Continuar'
}

export const SEATS_BLOCKED_MESSAGE_MODAL_COOkIES = {
  isOpen: true,
  iconModal: 'illustrated/warning',
  confirmButton: 'Ok',
  closeButton: 'Ok'
}

export const SEATS_USE_MESSAGE_MODAL_COOkIES = {
  iconModal: 'illustrated/warning',
  closeButton: 'Cancelar',
  confirmButton: 'Aceitar'
}

export const TICKETS_QTD_SHOULD_EQUAL_SEATS = {
  isOpen: true,
  title: 'Atenção',
  description:
    'Você deve selecionar ingressos igual a quantidade de assentos escolhidos, para Continuar sua compra',
  iconModal: 'illustrated/warning',
  closeButton: 'Ok'
}

export const TICKETS_SELECT_SOME_TICKET = {
  title: 'Ops!',
  description: 'Você deve ao menos selecionar um ingresso para prosseguir.',
  iconModal: 'illustrated/warning',
  isOpen: true,
  confirmButton: 'Continuar'
}

export const TICKETS_DOCUMENTS_EMPTY = {
  title: 'Atenção',
  description:
    'Você deve preencher todos os documentos dos ingressos para prosseguir',
  iconModal: 'illustrated/warning',
  closeButton: 'Ok'
}
export const TICKETS_NOTIFICATION = {
  title: 'Regras de Tipos de Ingressos',
  iconModal: 'illustrated/warning',
  closeButton: 'Entendi'
}

export const TICKETS_RULES = {
  isOpen: true,
  title: 'Regras de Tipos de Ingressos',
  iconModal: 'illustrated/warning',
  description: `
        Srs. Clientes, informamos que só serão aceitos comprovantes de
        MEIA-ENTRADA mediante apresentação física (impressa) do mesmo, ou seja,
        não será aceito nenhum tipo de comprovação por meios virtuais. Forneça a
        identificação para cada um. Válido para crianças menores de 12 anos,
        estudantes, idosos acima de 60 anos, pessoas com deficiência e jovens 15
        a 29 anos de idade de baixa renda. Necessária apresentação da
        identificação na bilheteria e na entrada do evento conforme exigido pela
        Lei Federal n° 12.933/2013 (Lei da Meia-Entrada), regulamentada pelo
        Decreto n° 8.537/2015. Estudantes da educação básica e educação superior
        regularmente matriculados, conforme previsto no Titulo V da Lei no
        9.394/1996, deverão apresentar, no momento do acesso à sala indicada
        para a sessão, a CIE (Carteira de Identificação Estudantil), emitida em
        conformidade com a Lei. Os descontos são pessoais, intransferíveis e não
        cumulativos.
     `,
  closeButton: 'Entendi'
}

export const LOGIN_CODE = {
  isOpen: true,
  title: 'Falta pouco!',
  description:
    'Enviamos um código de verificação para o seu e-mail. Digite-o abaixo:',
  iconModal: 'illustrated/email',
  closeButton: 'Continuar'
}

export const LOGIN_CODE_NOT_SEND = {
  isOpen: true,
  title: 'Não chegou?',
  description:
    'Pronto! Reenviamos para seu e-mail cadastrado um novo código de confirmação. Verifique novamente a sua caixa de e-mail. Lembre-se também de verificar a sua caixa de SPAM.',
  iconModal: 'illustrated/lock',
  closeButton: 'Continuar'
}

export const LOGIN_RESET_PASSWORD = {
  isOpen: true,
  title: 'Pronto!',
  customContent: (
    <div>
      <p>
        Se suas informações estiverem corretas, um link será enviado para o
        e-mail cadastrado para que você defina uma nova senha.
      </p>
      <br />
      <p>
        Em caso de dúvidas, entre em contato por e-mail. com a
        <span style={{ marginLeft: '5px' }}>
          <a href={process.env.ingHelpHost} target="_blank" rel="noreferrer">
            Central de Ajuda.
          </a>
        </span>
      </p>
    </div>
  ),
  iconModal: 'icons/iconMessage',
  closeButton: 'Continuar'
}
export const LOGIN_RESET_PASSWORD_ERROR = {
  isOpen: true,
  title: 'Ops!',
  customContent: (
    <div>
      <p>Infelizmente sua solitação não pode ser concluída.</p>
      <br />
      <p>
        Em caso de dúvidas, entre em contato por e-mail. com a
        <span style={{ marginLeft: '5px' }}>
          <a href={process.env.ingHelpHost} target="_blank" rel="noreferrer">
            Central de Ajuda.
          </a>
        </span>
      </p>
    </div>
  ),
  iconModal: 'illustrated/warning',
  closeButton: 'Continuar'
}
export const DEFAULT_MODAL = (options: IPropsModal) => {
  return {
    ...options
  }
}

export const PAYMENT_ERROR = {
  isOpen: true,
  title: 'Atenção',
  description: 'Erro ao processar o pagamento',
  iconModal: 'illustrated/warning',
  closeButton: 'Ok'
}
export const ADD_TICKETS_ERROR = {
  isOpen: true,
  title: 'Ops!',
  description:
    'Existem erros de validação de ingresso que precisam ser corrigidos.',
  iconModal: 'illustrated/warning',
  confirmButton: 'Continuar'
}
export const QUANTITY_TICKETS_ERROR = {
  isOpen: true,
  title: 'Ops!',
  description:
    'Escolha os Tipos de Ingresso para todos os assentos selecionados no Mapa de Assentos. Caso queria remover assentos, volte ao passo anterior.',
  iconModal: 'illustrated/warning',
  confirmButton: 'Continuar'
}
export const APPLEPAY_ERROR = {
  isOpen: true,
  title: 'Ops!',
  description:
    'Não foi possível completar a compra pelo Apple Pay. Tente novamente ou selecione outra forma de pagamento.',
  iconModal: 'illustrated/warning',
  confirmButton: 'Continuar'
}
export const APPLEPAY_ERROR_CONFIRM_PAYMENT = {
  isOpen: true,
  title: 'Ops!',
  iconModal: 'illustrated/warning',
  confirmButton: 'Continuar'
}

export const AT_LEAST_ONE_TICKET_ERROR = {
  isOpen: true,
  title: 'Ops!',
  description: 'Escolha pelo menos 1 assento na sessão selecionada.',
  iconModal: 'illustrated/warning',
  confirmButton: 'Continuar'
}
export const DELETE_GIFT = {
  isOpen: true,
  title: 'Você realmente deseja excluir esse desconto?',
  customContent: (
    <div>
      <p>Você ainda pode adicioná-lo novamente ou adicionar outros.</p>
    </div>
  ),
  iconModal: 'illustrated/warning',
  confirmButton: 'Sim'
}

export const ERROR_GIFT = {
  isOpen: true,
  title: 'Use um código válido para o cupom.',
  iconModal: 'illustrated/warning',
  closeButton: 'Continuar'
}

export const ERROR_DELETE_SESSION = {
  isOpen: true,
  title: 'Não foi possível excluir essa sessão.',
  description: 'Tente novamente mais tarde!',
  iconModal: 'illustrated/warning',
  closeButton: 'Continuar'
}

export const GIFT_PAYMENT = {
  isOpen: true,
  title: 'Atenção',
  description:
    'Por motivos de segurança, como você não utilizou um cartão nesta compra, não poderá retirar os ingressos pelo Auto-atendimento.',
  iconModal: 'illustrated/warning',
  closeButton: 'Voltar',
  confirmButton: 'Finalizar Compra'
}

export const ERROR_SEND_PAYMENT_CARD = {
  isOpen: true,
  title: 'Ops!',
  description:
    'Verifique se você preencheu corretamente os dados do seu cartão.',
  iconModal: 'illustrated/warning',
  closeButton: 'Continuar'
}

export const ERROR_GET_PAYMENT_METHODS = {
  isOpen: true,
  title: 'Ops!',
  description: 'Infelizmente não conseguimos carregar as formas de pagamento.',
  iconModal: 'illustrated/warning',
  closeButton: 'Continuar'
}

export const ERROR_SEND_PAYMENT_GOOGLE_PAY = {
  isOpen: true,
  title: 'Ops!',
  description: 'Pagamento com Google Pay cancelado',
  iconModal: 'illustrated/warning',
  closeButton: 'Continuar'
}

export const VALIDATION_CPF_ACCOUNT_ERROR = (message: string) => {
  return {
    isOpen: true,
    title: 'Ops!',
    description: message,
    iconModal: 'illustrated/warning',
    confirmButton: 'Ok'
  }
}

export const PAYMENT_CVV = {
  title: 'O que é CVV?',
  isOpen: true,
  iconModal: 'illustrated/warning',
  customContent: (
    <div>
      <small
        style={{
          fontSize: '10px'
        }}
      >
        O CVV é composto por 3 números localizados atrás, e logo após o número
        principal de seu cartão de crédito. Em cartões Amex, o CVV fica na parte
        da frente e pode ter 3 ou 4 números.
      </small>
      <Image
        src="/static/images/CVV.svg"
        alt="cvv"
        height={144}
        width={256}
        layout="fixed"
      />
    </div>
  ),
  customCloseButton: (
    <a
      style={{
        float: 'right'
      }}
    >
      Entendi
    </a>
  )
}

export const VERIFY_CODE = (
  openVerifyCode,
  setOpenVerifyCode,
  verifyCodeValue,
  customContent: ReactNode
) => {
  return {
    isOpen: openVerifyCode,
    onClose: () => setOpenVerifyCode(false),
    title: !verifyCodeValue ? 'Falta pouco!' : 'Não chegou?',
    description: !verifyCodeValue
      ? 'Enviamos um código de verificação para o seu e-mail. Digite-o abaixo:'
      : 'Pronto! Reenviamos para seu e-mail cadastrado um novo código de confirmação. Verifique novamente a sua caixa de e-mail. Lembre-se também de verificar a sua caixa de SPAM.',
    iconModal: !verifyCodeValue ? 'illustrated/email' : 'illustrated/lock',
    customContent
  }
}

export const PAYMENT_DUPLICATED_CODE = {
  isOpen: true,
  title: 'Ops!',
  customContent: (
    <div
      style={{ display: 'flex', alignItems: 'center', justifyItems: 'center' }}
    >
      <p>Código já adicionado.</p>
      <br />
      <br />
    </div>
  ),
  iconModal: 'illustrated/warning',
  closeButton: 'Continuar'
}
export const CLUB_UOL_ERROR = {
  isOpen: true,
  title: 'Ops!',
  description: 'Não foi possível aplicar o cupom.',
  iconModal: 'illustrated/warning',
  closeButton: 'Entendi'
}
export const CLUB_UOL_ERROR_TOOMANY = {
  isOpen: true,
  title: 'Ops!',
  description:
    'Você atingiu o limite de tentativas no Clube UOL. Confira se seu CPF faz parte do programa.',
  iconModal: 'illustrated/warning',
  closeButton: 'Entendi'
}
export const CLUB_UOL_ERROR_RECAPTCHA = {
  isOpen: true,
  title: 'Ops!',
  description: 'Recaptcha inválido, tente novamente.',
  iconModal: 'illustrated/warning',
  closeButton: 'Entendi'
}
export const KNOW_MORE_CLUBUOL = (
  callbackTracking,
  callbackTrackingWebView,
  objectSend
) => {
  return {
    isOpen: true,
    customContent: (
      <div
        style={{
          display: 'flex',
          alignItems: 'start',
          justifyItems: 'start',
          flexDirection: 'column'
        }}
      >
        <p>
          Assinante <strong>UOL</strong> tem isenção de taxa de serviço em até{' '}
          <strong>2</strong> ingressos por mês.
        </p>
        <br />
        <p>
          Na tela de pagamento, digite seu <strong>CPF</strong> ou{' '}
          <strong>CNPJ</strong> para confirmar o uso do benefício e resgatar sua
          isenção na taxa.
        </p>
        <br />
        <p>
          Se você ainda não é assinante <strong>UOL</strong>, não perca tempo!
          <a
            style={{ marginLeft: '4px' }}
            href="https://assine.uol.com.br/lp/checkout-ingresso-uol"
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              callbackTracking(objectSend)
              callbackTrackingWebView(objectSend)
            }}
          >
            Clique aqui
          </a>{' '}
        </p>
        <br />
      </div>
    ),
    iconModal: '/clubUol2',
    originalSizeIcon: true,
    heightIcon: 84,
    customHeightContainerIcon: '84px',
    widthIcon: 100,
    closeButton: 'Entendi',
    typeCloseButton: 'default'
  }
}
export const NO_HAVE_CLUBUOL = (
  callbackTracking,
  callbackTrackingWebView,
  objectSend
) => {
  return {
    isOpen: true,
    title: 'Ops!',
    customContent: (
      <div
        style={{
          display: 'flex',
          alignItems: 'start',
          justifyItems: 'start',
          flexDirection: 'column'
        }}
      >
        <p>
          O CPF informado não possui o <strong>Clube UOL</strong>
        </p>
        <br />
        <p>
          Para ter acesso ao benefício, seja um Assinante <strong>UOL.</strong>
          <a
            style={{ marginLeft: '2px' }}
            href="https://assine.uol.com.br/lp/checkout-ingresso-uol"
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              callbackTracking(objectSend)
              callbackTrackingWebView(objectSend)
            }}
          >
            Clique aqui
          </a>{' '}
          para assinar.
        </p>
      </div>
    ),
    iconModal: 'illustrated/warning',
    closeButton: 'Entendi'
  }
}
export const LIMIT_CLUBUOL = {
  isOpen: true,
  title: 'Ops!',
  description:
    'O CPF informado ultrapassou o limite de 2 ingressos/mês do Clube UOL.',
  iconModal: 'illustrated/warning',
  closeButton: 'Continuar'
}

export const PIX_ERROR_EXPIRED = {
  isOpen: true,
  hasCloseButton: false,
  title: 'Seu tempo expirou!',
  description:
    'Seu tempo para realizar o pagamento com Pix expirou, para pagar selecione uma forma de pagamento.',
  iconModal: 'illustrated/pix-logo',
  closeButton: 'Entendi'
}

export const PIX = (payPix, changePayment) => {
  return {
    isOpen: true,
    title: 'Alterar forma de pagamento?',
    hasCloseButton: false,
    customContent: (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyItems: 'center',
          flexDirection: 'column'
        }}
      >
        <p
          style={{
            marginBottom: '10px'
          }}
        >
          Não altere a forma de pagamento caso já tenha efetuado o PIX. Seu
          pedido não será concluído.
          <br />
        </p>
        <p>
          Utilize o botão <strong>{'Já paguei com PIX '}</strong> para aguardar
          a confirmação do seu pedido.
        </p>
        <a
          style={{
            borderRadius: '8px',
            padding: '8px 32px',
            whiteSpace: 'nowrap',
            textAlign: 'center',
            flexDirection: 'row',
            display: 'flex',
            color: 'white',
            alignItems: 'center',
            fontWeight: '700',
            justifyContent: 'center',
            width: '163px',
            height: '32px',
            margin: '16px 0',
            fontSize: '12px',
            background: `var(
                --degrade,
                linear-gradient(270deg, #6c04ba 5.81%, #3255e2 92.72%)
              )`
          }}
          onClick={() => payPix()}
          onMouseOver={(event) =>
            (event.currentTarget.style.cursor = 'pointer')
          }
        >
          Já paguei com pix
        </a>
        <a
          style={{
            border: '1px solid #98AAEC',
            borderRadius: '8px',
            padding: '7px'
          }}
          onMouseOver={(event) =>
            (event.currentTarget.style.cursor = 'pointer')
          }
          onClick={() => changePayment()}
        >
          Alterar forma de pagamento
        </a>
      </div>
    ),
    iconModal: 'illustrated/warning'
  }
}
export const SESSION_INFO = (
  movietitle: string,
  duration: string,
  ratingDescriptor: string,
  genre: string,
  rating: ReactNode,
  parental: ReactNode,
  ratingMessage: ReactNode
) => ({
  isOpen: true,

  customContent: (
    <div style={{ padding: '8px' }}>
      <div
        style={{
          paddingTop: '16px',
          paddingBottom: '16px'
        }}
      >
        <p
          style={{
            fontSize: '16px'
          }}
        >
          {movietitle}
        </p>
        <div
          style={{
            display: 'flex',
            fontSize: '12px',
            gap: '16px',
            color: '#8899aa'
          }}
        >
          <p>Duração: {duration} </p>
          <p>{genre}</p>
        </div>
        <div>{rating}</div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          fontSize: '12px'
        }}
      >
        {parental} {ratingMessage}
        <p style={{ fontSize: '10px', color: '#8899aa' }}>{ratingDescriptor}</p>
      </div>
    </div>
  )
})

export const MODAL_18 = () => ({
  isOpen: true,
  iconModal: 'illustrated/warning18',
  closeButton: 'Pesquisar outros filmes',
  hasCloseButton: false,
  customWidth: '640px',
  typeCloseButton: 'outlined',
  typeConfirmButton: 'default',
  confirmButton: 'Estou ciente, quero continuar',
  closeButtonClass: 'close-modal18',
  invertButtons: true,
  customContent: (
    <div
      style={{
        width: '95%',
        paddingLeft: '5%'
      }}
    >
      <h1
        style={{
          textAlign: 'center',
          fontSize: '16px',
          letterSpacing: '0.3163461685180664px',
          paddingBottom: '8px'
        }}
      >
        Classificação indicativa 18 anos.
      </h1>
      <h3
        style={{
          color: '#FFD633',
          paddingBottom: '8px'
        }}
      >
        A entrada só poderá ser realizada mediante apresentação de documento
        oficial com foto.
      </h3>
      <div>
        <p style={{ paddingBottom: '8px' }}>
          Caso tenha idade igual ou superior a 16 anos, este poderá ingressar na
          sessão desde que:
          <li>Esteja portando documento de identificação oficial com foto;</li>
          <li>
            Esteja acompanhado do responsável legal, ou outro acompanhante com
            idade superior a 18 anos autorizado por escrito pelo responsável
            legal (ficará retido no cinema).
          </li>
        </p>
        <p>
          Não será permitida a entrada de menores de 16 anos, mesmo que
          acompanhados por responsáveis legais.
        </p>
      </div>
    </div>
  )
})
