import styled from 'styled-components'

export const ContainerButton = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #ccc;
`
export const OtherButtonContent = styled.div`
  text-align: center;
  position: relative;
  display: inline-block;
  font-weight: bold;
  margin: 10px 0;

  h3 {
    text-align: center;
    color: #999;
  }

  &:after,
  :before {
    content: '';
    position: absolute;
    top: 50%;
    width: 40%;
    height: 0;
    border-bottom: 1px solid #ccc;
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }
`
