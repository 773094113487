import styled, { css } from 'styled-components'

export const Header = styled.div`
  height: 48px;
  background: ${({ theme }) => theme.header.bgColor};

  @media (min-width: 992px) {
    height: 60px;
  }
`

export const HeaderContent = styled.div`
  height: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: content-box;
  padding: 0 10px;

  @media (min-width: 992px) {
    padding: 0 16px;
  }
`

export const LogoContainer = styled.div`
  display: flex;
  cursor: pointer;
  overflow: hidden;
  align-items: center;

  span {
    color: ${({ theme }) => theme.header.text.color};
    margin-right: 10px;
  }

  @media (min-width: 992px) {
    margin: 0;
  }

  :hover {
    opacity: 0.8;
  }
`

export const CollapseContainer = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  padding: 0 10px;
  box-sizing: content-box;

  @media (min-width: 992px) {
    padding: 0 16px;
  }
`

export const BtnContainer = styled.div<{ isOpen: boolean }>`
  ul {
    display: flex;
    align-items: center;
    gap: 16px;

    li {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 14px;
      color: ${({ theme }) => theme.header.text.color};

      svg {
        cursor: pointer;

        :hover {
          opacity: 0.8;
        }
      }
    }

    @media (min-width: 992px) {
      gap: 56px;
    }

    .support {
      display: none;

      @media (min-width: 992px) {
        display: flex;
      }
    }

    .close {
      @media (min-width: 992px) {
        display: none;
      }
    }

    ${(props) =>
      props.isOpen &&
      css`
        .login {
          ::before {
            content: '';
            position: absolute;
            top: 24px;
            border: 12px solid transparent;
            border-bottom-color: ${(props) =>
              props.theme?.header?.user?.borderColor};

            @media (min-width: 992px) {
              top: 36px;
            }
          }

          ::after {
            content: '';
            position: absolute;
            top: 25px;
            border: 13px solid transparent;
            border-bottom-color: ${(props) =>
              props.theme?.header?.user?.bgColor};
            z-index: 2;

            @media (min-width: 992px) {
              top: 37px;
            }
          }
        }
      `}
  }
`
