import styled, { css } from 'styled-components'
import { IStyledComponents, IStyledCountTabs } from './interface'

export const ProgressTabContainer = styled.div<{ isMobileApp: boolean }>`
  max-width: 540px;
  display: flex;
  flex-direction: column;
  margin: 16px auto;
  ${({ isMobileApp }) => isMobileApp && 'margin-top: 32px;'}
  align-items: center;

  @media (min-width: 992px) {
    margin: 24px auto 40px;
  }
`

export const ListTab = styled.div`
  display: flex;
  justify-content: space-around;
  width: 90%;
  align-items: center;

  button {
    &:last-of-type {
      margin-right: 0;
    }
  }
`

export const TitleTab = styled.div`
  @media (min-width: 992px) {
    display: none;
  }
`

export const TitleArrow = styled.button`
  ${({ theme }) => css`
    appearance: none;
    background-color: transparent;
    border: 0;
    position: absolute;
    left: 10px;

    &:disabled {
      display: none;
    }

    svg {
      transform: rotate(-90deg);
      fill: ${theme.tabs.availableColor};
    }
  `}
`

export const ItemLine = styled.div<IStyledCountTabs>`
  ${({ theme, isActive }) => css`
    width: 100%;
    height: 1px;
    background-color: ${isActive
      ? theme.tabs.availableColor
      : theme.tabs.disabledColor};
  `}
`

export const Item = styled.button<IStyledComponents>`
  ${({
    theme,
    disabled,
    bgDefault,
    colorDefault,
    bgActive,
    colorActive
  }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    border-radius: 50%;
    border: solid 1px
      ${disabled ? theme.tabs.disabledColor : theme.tabs.availableColor};
    background: ${theme.tabs.bgDefault || bgDefault};
    text-align: center;
    color: ${colorDefault};
    cursor: pointer;

    svg {
      width: 16px;
      height: 16px;

      path {
        fill: ${disabled
          ? theme.tabs.disabledColor
          : theme.tabs.availableColor};
      }
    }

    &.active {
      max-width: 150px;
      border: solid 1px ${theme.tabs.bgActive || bgActive};
      background: ${theme.tabs.bgActive || bgActive};
      color: ${theme.tabs.activeColor || colorActive};
      padding: 8px;

      svg {
        width: 24px;
        height: 24px;

        path {
          fill: ${theme.tabs.activeColor || colorActive};
        }
      }

      &::before {
        border-top: 23px solid ${theme.tabs.bgActive || bgActive};
        border-bottom: 22px solid ${theme.tabs.bgActive || bgActive};
      }

      &::after {
        border-left: 5px solid ${theme.tabs.bgActive || bgActive};
      }
    }
  `}
`

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.tabs.textColor};

  @media (min-width: 992px) {
    display: none;
  }
`
export const Close = styled.div`
  appearance: none;
  background-color: transparent;
  border: 0px;
  position: absolute;
  right: 10px;
`

export const IconContainer = styled.div<{
  currentPage: string
  isActive: boolean
}>`
  @media (min-width: 992px) {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    ${(props) =>
      props.isActive &&
      props.currentPage &&
      css`
        :after {
          content: '${props.currentPage}';
          position: absolute;
          min-width: 140px;
          margin-top: 70px;
          font-size: 12px;
          text-align: center;
          color: ${({ theme }) => theme.tabs.textColor};
        }
      `}
  }
`
